import axios from 'axios';
import _ from "lodash";
import {API_READERS_V1} from "../../constants/API";
import {LOAD_BOOKCLUB_PUBLIC_FAILURE, LOAD_BOOKCLUB_PUBLIC_REQUEST, LOAD_BOOKCLUB_PUBLIC_SUCCESS} from "./actionTypes";

export const loadBookClubList = () => {

    return dispatch => {

        dispatch(loadBookClubListRequest());

        axios
            .get(API_READERS_V1 + '/bookclub/list')
            .then(response => {
                const bookClubListAll = response.data;
                const bookClubListRecruiting = _.filter(bookClubListAll, {status: 'recruiting'});
                const bookClubListIng = _.filter(bookClubListAll, {status: 'ing'});
                const bookClubListEnded = _.filter(bookClubListAll, {status: 'ended'});

                dispatch(loadBookClubListSuccess(
                    bookClubListAll,
                    bookClubListRecruiting,
                    bookClubListIng,
                    bookClubListEnded))
                console.log('[COMPLETE: REDUX] LoadBookList');
            })
            .catch(e => {
                console.log('[FAILURE: REDUX] LoadBookList', e);
                dispatch(loadBookClubListFailure())
            })
    }
};



export const loadBookClubListRequest = () => {
    return {
        type: LOAD_BOOKCLUB_PUBLIC_REQUEST
    }
}

export const loadBookClubListSuccess = (bookClubListAll,
                                        bookClubListRecruiting,
                                        bookClubListIng,
                                        bookClubListEnded) => {
    return {
        type: LOAD_BOOKCLUB_PUBLIC_SUCCESS,
        bookClubListAll,
        bookClubListRecruiting,
        bookClubListIng,
        bookClubListEnded,
    }
};

export const loadBookClubListFailure = () => {
    return {
        type: LOAD_BOOKCLUB_PUBLIC_FAILURE
    }
}


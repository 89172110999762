import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {Segment, Container, Header, Button, Form, Item, Grid, Input, Image, Select, Table,} from 'semantic-ui-react'
import {API_READERS_V1} from "../../constants/API";
import {removeHtmlTag} from "../../middleware/TextHandler";
import moment from 'moment';
import {bookClubImageUploadToFirebase} from "../../middleware/firebaseImageHandler";

class AdminRecommendDetailContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recommend: {},
            isLoaded: false,
            failed: false,

            title: '',
            subTitle: '',
            content: '',
            mainImage: '',
            mainImageFirebase: {},

            bookList: [],
            category: '',
            subCategory: '',
            selectedDay: new Date(),
            tags: [],
            isSecret: false,


            searchValue: '',
            searchOption: 'd_titl',
            searchedOutcome: [],

            uploadingImage: false,
        }
    }

    componentDidMount() {
        this._loadRecommend()
    }

    handleChange = (e, {value, name}) => {
        this.setState({
            [name]: value
        });
    };
    handleSelect = (e, data) => {
        console.log(data)
        this.setState({
            [data.name]: data.value
        });
    };

    _loadRecommend = async () => {

        const {recommendId} = this.props.match.params;
        const token = await localStorage.getItem('token');
        axios
            .get(API_READERS_V1 + `/recommend/admin/${recommendId}`, {
                headers: {jwt: token}
            })
            .then(response => {
                const recommend = response.data;
                const {
                    title,
                    subTitle,
                    content,
                    mainImage,
                    mainImageFirebase,
                    bookList,
                    category,
                    subCategory,
                    selectedDay,
                    tags,
                    isSecret,

                } = recommend

                this.setState({
                    recommend,
                    failed: false,
                    isLoaded: true,

                    title,
                    subTitle,
                    content,
                    mainImage,
                    mainImageFirebase,

                    bookList,
                    category,
                    subCategory,
                    selectedDay: moment(selectedDay).format('YYYY-MM-DD'),
                    tags,
                    isSecret,
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    failed: true,
                })
            })
    }

    searchBook = () => {
        const {searchOption, searchValue} = this.state;
        console.log({
            searchOption,
            searchValue,
        })

        axios
            .get(API_READERS_V1 + '/bookshelf/search/book', {
                params: {
                    searchValue,
                    searchOption
                }
            })
            .then(response => {
                const searchedOutcome = response.data
                console.log({searchedOutcome})
                this.setState({
                    searchedOutcome
                })
            })
            .catch(error => {

            })
    }
    _deleteBook = i => {
        const bookList = this.state.bookList;
        bookList.splice(i, 1);
        this.setState({
            bookList
        })
    }
    moveItemInArrayFromIndexToIndex = (array, fromIndex, toIndex) => {
        const newArray = [...array];

        if (fromIndex === toIndex) return newArray;

        const target = newArray[fromIndex];
        const inc = toIndex < fromIndex ? -1 : 1;

        for (let i = fromIndex; i !== toIndex; i += inc) {
            newArray[i] = newArray[i + inc];
        }

        newArray[toIndex] = target;

        return newArray;
    };
    modifyList = async () => {
        const a = window.confirm('만드시겠습니까?')

        if (!a) {
            return;
        }

        const token = localStorage.getItem('token');
        const {
            title,
            subTitle,
            content,
            mainImage,
            bookList,
            category,
            subCategory,
            selectedDay,
            tags,

            isSecret,
        } = this.state;

        const data = {
            title,
            subTitle,
            content,
            mainImage,
            bookList,
            category,
            subCategory,
            selectedDay,
            tags,

            isSecret
        }

        axios
            .put(API_READERS_V1 + `/recommend/admin/${this.state.recommend._id}`, data, {headers: {jwt: token}})
            .then(response => {
                console.log(response.data);
                this.props.history.push({pathname: '/admin/recommend'})
            })
            .catch(error => {
                console.log(error);
            })
    }

    fileChange = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.handleImageUpload(file)
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {
            this.setState({imagePreviewUrl: ''});
        }
    };

    handleImageUpload = async (file) => {

        this.setState({
            uploadingImage: true,
        })
        const {
            mainImage,
            mainImageFirebase
        } = await bookClubImageUploadToFirebase(file);

        this.setState({
            mainImage,
            mainImageFirebase,
            uploadingImage: false,
        })
    }


    render() {
        return (
            <div>
                <Container as={Segment} vertical>
                    <Button onClick={() => {
                        this.modifyList()
                    }}>
                        수정하기
                    </Button>
                    <Select value={this.state.isSecret}
                            name={'isSecret'}
                            onChange={this.handleSelect}
                            options={[
                                {
                                    key: true,
                                    text: '비공개',
                                    value: true
                                },
                                {
                                    key: false,
                                    text: '공개',
                                    value: false
                                }
                            ]}/>
                </Container>
                <Container as={Segment} vertical>
                    <Image src={this.state.mainImage} size={'medium'}/>
                    <Button
                        basic
                        color="blue"
                        size="small"
                        onClick={() => {
                            this.fileInput.click();
                        }}
                    >
                        사진 수정하기
                    </Button>
                    <input style={{display: 'none'}} type="file" onChange={this.fileChange}
                           ref={fileInput => (this.fileInput = fileInput)} accept=".jpg, .jpeg, .png, .heic"/>

                    <Form>
                        <Select value={this.state.category}
                                name={'category'}
                                onChange={this.handleSelect}
                                options={[
                                    {
                                        key: 'PERSON',
                                        text: '인물',
                                        value: 'PERSON'
                                    },
                                    {
                                        key: 'ORGANIZATION',
                                        text: '학교/회사/기관',
                                        value: 'ORGANIZATION'
                                    },
                                    {
                                        key: 'PERIOD',
                                        text: '기간',
                                        value: 'PERIOD'
                                    }
                                ]}/>
                        {
                            this.state.category === 'PERIOD' ? (
                                <Select value={this.state.subCategory}
                                        name={'subCategory'}
                                        onChange={this.handleSelect}
                                        options={[
                                            {
                                                key: 'WEEKLY',
                                                text: '주',
                                                value: 'WEEKLY'
                                            },
                                            {
                                                key: 'MONTHLY',
                                                text: '월',
                                                value: 'MONTHLY'
                                            },
                                            {
                                                key: 'ANNUAL',
                                                text: '년',
                                                value: 'ANNUAL'
                                            }
                                        ]}/>
                            ) : null
                        }
                        <Form.Input label={'Date'} name={'selectedDay'} value={this.state.selectedDay}
                                    type={'date'}
                                    onChange={this.handleChange}/>
                        <Form.Input label={'Title'} name={'title'} value={this.state.title}
                                    onChange={this.handleChange}/>
                        <Form.Input label={'subTitle'} name={'subTitle'} value={this.state.subTitle}
                                    onChange={this.handleChange}/>
                        <Form.TextArea rows={20}
                                       label={'content'} name={'content'} value={this.state.content}
                                       onChange={this.handleChange}/>
                    </Form>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    사진
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    제목
                                </Table.HeaderCell>

                                <Table.HeaderCell>
                                    저자
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    출판사
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    출판년월
                                </Table.HeaderCell>

                                <Table.HeaderCell>
                                    설명
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    이유
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    버튼
                                </Table.HeaderCell>

                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.bookList.map((book, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>
                                                <Image src={book.image} size={'tiny'}/>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {book.title}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {book.author}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {book.publisher}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {book.pubdate}
                                            </Table.Cell>

                                            <Table.Cell width={4}>
                                                {book.description}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Form>
                                                    <Form.TextArea value={this.state.bookList[i].recommend}
                                                                   onChange={(e, {name, value}) => {
                                                                       const bookList = this.state.bookList;
                                                                       bookList[i].recommend = value
                                                                   }}/>
                                                </Form>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button onClick={() => {
                                                    this._deleteBook(i)
                                                }}>
                                                    삭제하기
                                                </Button>
                                                {
                                                    i === 0 ? (
                                                        null
                                                    ) : (
                                                        <Button onClick={() => {
                                                            const bookList = this.moveItemInArrayFromIndexToIndex(this.state.bookList, i, i - 1)
                                                            this.setState({
                                                                bookList
                                                            })
                                                        }}>
                                                            올리기
                                                        </Button>
                                                    )
                                                }
                                                {
                                                    i === this.state.bookList.length - 1 ? (
                                                        null
                                                    ) : (
                                                        <Button onClick={() => {
                                                            const bookList = this.moveItemInArrayFromIndexToIndex(this.state.bookList, i, i + 1)
                                                            this.setState({
                                                                bookList
                                                            })
                                                        }}>
                                                            내리기
                                                        </Button>
                                                    )
                                                }

                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>
                </Container>
                <Container as={Segment} vertical>
                    <Header>
                        추가할 책 검색
                    </Header>
                    <Input type='text' placeholder='Search...'
                           action
                           name={'searchValue'}
                           onChange={this.handleChange}
                           value={this.state.searchValue}>
                        <input/>
                        <Select compact
                                name={'searchOption'}
                                onChange={this.handleSelect}
                                options={[
                                    {
                                        key: 'd_titl',
                                        text: '제목',
                                        value: 'd_titl'
                                    }, {
                                        key: 'd_auth',
                                        text: '저자',
                                        value: 'd_auth'
                                    }]}
                                defaultValue='d_titl'/>
                        <Button onClick={this.searchBook}>Search</Button>
                    </Input>
                    {
                        this.state.searchedOutcome.length > 0 ? (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            이미지
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            제목
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            저자
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            출판사
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            출판일
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            기타
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>

                                    {
                                        this.state.searchedOutcome.map((book, i) => {
                                            return (
                                                <Table.Row key={i}>
                                                    <Table.Cell>
                                                        <Item.Image src={book.image} size={'tiny'}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {book.title}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {book.author}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {book.publisher}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {book.pubdate}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Button onClick={() => {
                                                            const bookList = this.state.bookList;
                                                            let newBook = book;
                                                            newBook.title = removeHtmlTag(newBook.title);
                                                            newBook.author = removeHtmlTag(newBook.author);
                                                            newBook.description = removeHtmlTag(newBook.description);
                                                            bookList.push(newBook)
                                                            this.setState({
                                                                bookList
                                                            })
                                                        }}>
                                                            추가
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                    }
                                </Table.Body>
                            </Table>
                        ) : null
                    }
                </Container>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminRecommendDetailContainer);

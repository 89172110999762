const removeHtmlTag = (text) => {
    return text.replace(/\n/gi, '')
        .replace(/\t/g, '')
        .replace(/&nbsp;/gi, '')
        .replace(/&amp;/gi, '&')
        .replace(/&quot;/gi, '')
        .replace(/&#x0D;/gi, '')
        .replace(/&lsquo;/gi, '')
        .replace(/&rsquo;/gi, '')
        .replace(/&lt;/gi, '')
        .replace(/&gt;/gi, '')
        .replace(/&#10;/gi, '')
        .replace(/<(?:.|\n)*?>/gm, '')
};

export const removeHtmlTagWithLineChange = (text) => {
    return text.replace(/<p>/gi, '\n')
        .replace(/<br>/gm, '\n')
        .replace(/\t/g, '')
        .replace(/&nbsp;/gi, '')
        .replace(/&amp;/gi, '&')
        .replace(/&quot;/gi, '')
        .replace(/&lsquo;/gi, '')
        .replace(/&rsquo;/gi, '')
        .replace(/&#x0D;/gi, '')
        .replace(/&lt;/gi, '')
        .replace(/&gt;/gi, '')
        .replace(/&#10;/gi, '')
        .replace(/<(?:.|\n)*?>/gm, '')
};


const removeHtmlTagSubString = (text, number) => {
    return removeHtmlTag(text).substr(0,number);
};

export {
    removeHtmlTag,
    removeHtmlTagSubString
}
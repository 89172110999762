import React, {Component} from 'react';
import {connect} from 'react-redux';
import Axios from 'axios';
import moment from 'moment';
import {API} from '../../constants/API';
import {Loader, Item, Label, Icon} from 'semantic-ui-react';
import {deleteTag} from '../../middleware/stringModify';
import {Link} from 'react-router-dom';

export class MyEssayAll extends Component {
    state = {
        essayData: [],
        isLoaded: false
    };
    componentDidMount = () => {
        this.loadEssayInfo();
    };

    loadEssayInfo = () => {
        const token = localStorage.getItem('token');
        Axios.get(API + '/readers/getinfo/essay', {headers: {jwt: token}})
            .then(result => {
                console.log(result.data);
                const essayData = result.data;
                this.setState({
                    essayData,
                    isLoaded: true
                });
            })
            .catch(error => {
                console.log(error.response.data);
            });
    };

    render() {
        const {essayData, isLoaded} = this.state;

        if (!isLoaded) {
            return (
                <div>
                    <Loader active/>
                </div>
            );
        }
        return (
            <div>
                <Item.Group unstackable divided style={{maxWidth: 800, margin: 'auto'}}>
                    {essayData.map(essay => {
                        return (
                            <Item key={essay._id}>
                                <Item.Image
                                    src={essay.bookclubId ? essay.bookInBookClubBookshelf[0].image : essay.bookInPrivateBookshelf[0].image}
                                    size={'tiny'}/>
                                <Item.Content>
                                    <Item.Header>{essay.title}</Item.Header>
                                    <Item.Extra>
                                        {essay.public === 'public' ? (
                                            <Label color="blue" size="mini" content="전체 공개"/>
                                        ) : essay.public === 'inClub' ? (
                                            <Label color="red" size="mini" content="클럽 내 공개"/>
                                        ) : essay.public === 'follower' ? (
                                            <Label color="green" size="mini" content="팔로워에게 공개"/>
                                        ) : (
                                            <Label size="mini" content="공개 미설정"/>
                                        )}
                                        {essay.bookclubId ? <Label size="mini" content="북클럽 에세이" color="olive"/> :
                                            <Label size="mini" content="개인 에세이" color="orange"/>}
                                    </Item.Extra>
                                    <Item.Meta>{moment(essay.created).format('YYYY년 MM월 DD일')}</Item.Meta>
                                    <Item.Description
                                        style={{color: 'grey'}}>{deleteTag(essay.content).substring(0, 150)} ...</Item.Description>
                                    <Item.Meta>
                                        <Icon name="comment outline"/> {essay.comments.length}
                                    </Item.Meta>
                                    <Item.Meta>
                                        written by <Link to={{
                                        pathname: '/readers/profile/' + essay.owner_id,
                                        state: {from: this.props.location}
                                    }}> {essay.owner_name}</Link>
                                    </Item.Meta>
                                </Item.Content>
                            </Item>
                        );
                    })}
                </Item.Group>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyEssayAll);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withLineEnter} from "../../middleware/stringModify";
import {Container, Segment, Header} from "semantic-ui-react";

class OfflineMeetingModuleComponent extends Component {
    render() {
        const {bookClub} = this.props;
        const {offlineMeeting, hasOfflineMeeting} = bookClub;

        if (!hasOfflineMeeting) {
            return null;
        }
        return (
            <div style={{paddingBottom: 60, paddingTop: 60, }}>
                <Segment as={Container} vertical text basic textAlign={'center'}>
                    <Header style={{}} size={'large'}>
                        오프라인 모임
                        <Header.Subheader style={{marginTop: 5}}>
                            다같이 만나는 자리
                        </Header.Subheader>
                    </Header>
                </Segment>

                <Segment as={Container} text>
                    <p style={{lineHeight: '2rem'}}>
                        {withLineEnter(offlineMeeting)}
                    </p>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OfflineMeetingModuleComponent);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withLineEnter} from "../../middleware/stringModify";
import {Container, Segment, Header, Image, Message, Item} from "semantic-ui-react";

class HostIntroComponent extends Component {
    render() {
        const {bookClub} = this.props;
        if (!bookClub.hasHost) {
            return null
        }
        return (
            <div style={{paddingBottom: 60, paddingTop: 60, backgroundColor:'lightgrey'}}>
                <Segment as={Container} vertical text basic textAlign={'center'}>
                    <Header style={{}} size={'large'}>
                        클럽장 소개
                        <Header.Subheader style={{marginTop: 5}}>
                            북클럽을 운영하는 클럽장입니다.
                        </Header.Subheader>
                    </Header>
                </Segment>
                <Segment as={Container} text style={{backgroundColor:'white'}}>
                    <Image src={bookClub.hostInfo.profileImage} circular size={'tiny'} centered/>
                    <Header textAlign='center'>
                        {bookClub.hostInfo.local.name}

                    </Header>
                    {
                        bookClub.hostIntroModule && bookClub.hostIntroModule.length > 0 && (
                            <Item.Group unstackable>
                                {
                                    bookClub.hostIntroModule.map((module, i) => {
                                        return (
                                            <Item key={i} style={{}}>
                                                <Item.Content>
                                                    {
                                                        (module.header && module.header !== '') && (
                                                            <Header style={{
                                                                // fontSize: '1.1rem'
                                                            }}>
                                                                {module.header}
                                                                {
                                                                    (module.subHeader && module.subHeader !== '') && (
                                                                        <Header.Subheader
                                                                            style={{
                                                                                // fontSize: '1rem'
                                                                            }}>
                                                                            {module.subHeader}
                                                                        </Header.Subheader>
                                                                    )
                                                                }
                                                            </Header>
                                                        )
                                                    }
                                                    {
                                                        (module.content && module.content !== '') && (
                                                            <Item.Description
                                                                style={{
                                                                    // fontSize: '1rem'
                                                                }}>
                                                                {withLineEnter(module.content)}
                                                            </Item.Description>
                                                        )
                                                    }
                                                </Item.Content>
                                            </Item>
                                        )
                                    })
                                }
                            </Item.Group>
                        )
                    }
                    <Message>
                        <Message.Header style={{fontSize: '1rem'}}>
                            클럽장은 어떤 역할을 하나요?
                        </Message.Header>
                        <Message.Content style={{fontSize: '1rem   '}}>
                            클럽장은 북클럽에서 읽을 책을 선별하고, 공통 에세이 주제가 있는 경우 주제를 제시합니다. <br/>
                            오프라인 모임이 있는 경우, 클럽장 주도하에 모임을 진행하게 됩니다.
                        </Message.Content>
                    </Message>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HostIntroComponent);

import * as types from '../actions/actionTypes';
import update from 'react-addons-update';

const initialState = {
    status: "INIT",
    fetching: false,
    failed: false,
    isLoaded: false,

    myBookClubList: [],
    myBookClubListRecruiting: [{}, {}],
    myBookClubListIng: [{}, {}],
    myBookClubListEnded: [{}, {}],

    myBookClubListBeforeApproved: [{}, {}],

};

const myBookClubs = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_MY_BOOKCLUB_REQUEST:
            return update(state, {
                status: {$set: 'REQUEST'},
                fetching: {$set: true},
                failed: {$set: false},
            });
        case types.LOAD_MY_BOOKCLUB_SUCCESS:
            return update(state, {
                status: {$set: 'SUCCESS'},
                fetching: {$set: false},
                failed: {$set: false},
                isLoaded: {$set: true},

                myBookClubList: {$set: action.bookClubList},
                myBookClubListRecruiting: {$set: action.bookClubListRecruiting},
                myBookClubListIng: {$set: action.bookClubListIng},
                myBookClubListEnded: {$set: action.bookClubListEnded},
            })
        case types.LOAD_MY_BOOKCLUB_FAILURE:
            return update(state, {
                status: {$set: 'FAILURE'},
                fetching: {$set: false},
                failed: {$set: true},
                isLoaded: {$set: false},
            })

        case types.LOAD_MY_BOOKCLUB_BEFORE_APPROVED_SUCCESS:
            return update(state,{
                myBookClubListBeforeApproved : {$set: action.bookClubListBeforeApproved}
            })

        case types.INITIALIZE_MY_BOOKCLUB :
            return initialState;
        default:
            return state;
    }
};

export default myBookClubs;

import moment from 'moment';
import {firebase, firebaseApp} from "../config/firebase";

export const bookClubImageUploadToFirebase = async (imageFile) => {
    if (imageFile === '' || !imageFile) {
        return;
    }

    const fileName = moment().format('YYYYMMDDHHmmss');
    const bucket = `bookclubimage/${moment().format('YYYYMM')}`;
    const imageRef = `/${bucket}/${fileName}.jpg`;
    const imageRef200 = `/${bucket}/thumb_200_${fileName}.jpg`;
    const imageRef400 = `/${bucket}/thumb_400_${fileName}.jpg`;
    const imageRef800 = `/${bucket}/thumb_800_${fileName}.jpg`;

    const storageRef = firebaseApp.storage().ref().child(imageRef);
    const uploadTask = await storageRef.put(imageFile)
    const mainImage = await storageRef.getDownloadURL();

    return {
        mainImage,
        mainImageFirebase: {
            bucket,
            fileName,
            imageRef,
            imageRef200,
            imageRef400,
            imageRef800,
        }
    }

}
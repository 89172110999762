import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Container, Grid, Divider, Header, Image, List, Segment} from 'semantic-ui-react';
import ReactGA from 'react-ga';

class AboutReadersContainer extends Component {

  componentDidMount() {
    ReactGA.pageview('/about')
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Fragment>
        <div style={{
          minHeight: '50vh',
          backgroundColor: 'black',
          paddingTop: 30,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <p style={{fontSize: '1rem', color: 'white'}}>
            리 더 스
          </p>
          <div style={{fontSize: '3rem', color: 'white'}} className={'hanSerifJapanese'}>
                        <span className={'animated fadeIn slow'}>
                            會 .{' '}
                        </span>
            <span className={'animated fadeIn delay-1s slow'}>
                            械 .{' '}
                        </span>
            <span className={'animated fadeIn delay-2s slow'}>
                            亭
                        </span>
          </div>
        </div>
        <Container as={Segment} vertical basic text style={styles.module} textAlign={'center'}>
          <div>
            <Header>
              리더스가 무엇인가요?
            </Header>
            <p>
              리더스는 책을 읽는 사람들을 위한 <br/>
              플랫폼을 만드는 프로젝트입니다.<br/><br/>
              북클럽 <b className={'hanSerifJapanese'}>會</b>,
              모바일 앱 <b className={'hanSerifJapanese'}>械</b>,
              책이 있는 공간 <b className={'hanSerifJapanese'}>亭</b> <br/>
              을 만들어 나가고 있습니다.
            </p>
          </div>
        </Container>
        <Container as={Segment} vertical basic style={{...styles.module, fontSize: '1.14285714rem'}}>
          <Grid stackable>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Header size={'large'} style={{textAlign: 'center'}}>
                  會
                  <Header.Subheader>
                    북클럽
                  </Header.Subheader>
                </Header>
                <Image src={'/images/moimColor.png'} style={{marginBottom: 20}}/>
                <p>
                  함께 책을 읽으면, 혼자 읽을 때보다 꾸준히 그리고 오랫동안 읽을 수 있습니다.<br/>
                  2017년 여름부터 시작한 <b>리더스 북클럽</b>은 혼자서는 읽기 어려운 책들을 위주로, 함께 읽어나갔습니다. <br/>
                  <br/>
                </p>
              </Grid.Column>
              <Grid.Column>
                <Header size={'large'} style={{textAlign: 'center'}}>
                  械
                  <Header.Subheader>
                    모바일 앱
                  </Header.Subheader>
                </Header>
                <Image src={'/images/app.jpg'} style={{marginBottom: 20}}/>
                <p>

                  책을 읽는 데 필요한 도구를 제공합니다.
                  <br/>
                  북클럽 참여, 책장 관리, 독서 기록 관리를<br/>
                  체계적으로 하는데 도움을 주는 것을 목적으로 합니다.
                </p>
              </Grid.Column>
              <Grid.Column>
                <Header size={'large'} style={{textAlign: 'center'}}>
                  亭
                  <Header.Subheader>
                    공간
                  </Header.Subheader>
                </Header>
                <Image src={'/images/anjin.jpg'} style={{marginBottom: 20}}/>
                <p>
                  책을 읽는 사람들이, 만날 수 있는 장소를 만듭니다.<br/>
                  도서관과 서재처럼 책이 많이 모여 있는 곳에서 영감을 받을 수 있습니다.<br/>
                  책이 있는 곳에서 나누는 대화, <br/>
                  책과 사람, 사람과 사람의 우연한 만남을
                </p>
              </Grid.Column>

            </Grid.Row>
          </Grid>
        </Container>
        <Container as={Segment} vertical basic text style={styles.module}>
          <Header textAlign={'center'}>
            리더스가 누가 운영하나요?
          </Header>
          <p>
            안녕하세요. 리더스를 운영하고 있는 윤영훈입니다. <br/>
            현재는 <a href='http://whitecube.co.kr' target={'_blank'}>화이트큐브</a>를 공동 창업하고,
            '챌린저스'라는 서비스를 만들어 많은 사람들의 목표달성을 돕는 일을 하고 있습니다.
          </p>
          <p>
            독서의 중요성은 누구나 알고 있지만, 지속적으로 꾸준히 책을 읽는 것은 쉽지 않습니다.<br/>
            책을 꾸준히 체계적으로 읽는 체계를 만드는 것에 대해 고민하였고, <br/>
            오랜 기간동안 계속해서 책을 읽어나갈 수 있는 환경을 구축하는 것이 필요하다고 생각했습니다. <br/>
            <b>재미있게, 지속 가능하게.</b>
          </p>
          <p>
            <a href='http://beingndoing.info' target={'_blank'}>Being&Doing</a> 모임을 운영하면서,
            새로운 사람들을 만나면서, <b>모임 운영의 요령</b>과 <b>모임의 힘</b>에 대해서 알게 되었습니다. <br/>
            좋은 사람들과 좋은 주제에 대해서 이야기 하는 것은, 혼자서는 할 수 없는 생각을 하게 하고,
            다양한 시각으로 세상을 볼 수 있게합니다. <br/>
          </p>
          <p>
            책을 통해, 새로운 지식을 배우고,<br/>
            책을 통해, 새로운 사람을 만날 수 있는 <br/>
            플랫폼과 시스템을 만드는 <b>'리더스'</b> 프로젝트를 진행하고 있습니다.<br/>

            이의 일환으로 책을 중심으로 하는 <b>모임</b>과 <b>도구</b> 그리고 <b>공간</b>을 만들어 나갑니다. <br/>
            '리더스'를 통해 많은 사람들, 그리고 많은 책을 만나는 것을 기대합니다.
          </p>
        </Container>
      </Fragment>
    );
  }
}

const styles = {
  module: {
    marginTop: 50,
    marginBottom: 50,
  }
}

const mapStateToProps = (state) => {
  return {};
}
const mapDispatchToProps = (dispatch) => {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AboutReadersContainer);

import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {API_READERS_V1} from "../../constants/API";
import {
    Loader,
    Container,
    Icon,
    Input,
    Comment,
    Header,
    Segment,
    Form,
    Radio,
    TextArea,
    Button
} from 'semantic-ui-react'
import {withLineEnter} from "../../middleware/stringModify";
import AvatarComponent from '../../components/user/AvatarComponent'
import moment from 'moment'
import {Link, Redirect} from 'react-router-dom'
import {removeHtmlTag, removeHtmlTagWithLineChange} from "../../middleware/TextHandler";
import Colors from "../../config/Colors";

class EssayWriteContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {

            public: 'public',
            title: '',
            contentPlain: ''


        }
    }

    onUnload(event) { // the method that will be used for both add and remove event
        event.returnValue = "Hellooww"
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.onUnload)
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload)
    }


    handleInputChange = (e, {value, name}) => {
        this.setState({[name]: value});
    };
    _uploadEssay = async () => {

        const a = window.confirm('완료하시겠습니까?');

        if (!a) {
            return;
        }

        const {
            isbn,
            title: book_title,
            bookclubId,
            _id: bookId,
        } = this.props.location.state.book;

        if (!isbn) {
            return window.alert('에세이의 책정보가 없습니다. 내용을 복사해 두시고, 에세이 작성을 다시 시작해 주세요');
        }

        const data = {
            owner_id: this.props.userId,
            owner_name: this.props.userInfo.userName,
            isbn,
            book_title: removeHtmlTag(book_title),
            bookclubId,
            bookId,
            title: this.state.title,
            public: this.state.public,
            contentPlain: this.state.contentPlain,
            content: this.state.contentPlain,
        }

        const token = await localStorage.getItem('token');


        axios
            .post(API_READERS_V1 + `/essay/`, data, {
                headers: {jwt: token}
            })
            .then(response => {
                console.log(response.data);
                window.alert('작성이 완료되었습니다.')
                this.props.history.push({pathname: `/mybookclub/${bookclubId}`});
            })
            .catch(error => {
                window.alert('에러가 발생했습니다.')
            })
    };


    render() {

        return (
            <Fragment>
                <Container text
                           as={Segment}
                           vertical>

                    <Input value={this.state.title}
                           name={'title'}
                           placeholder={'제목을 입력하세요'}
                           onChange={this.handleInputChange}
                           style={{fontSize: '1.7rem', fontWeight: 'bold'}}
                           fluid/>
                </Container>
                <Container text as={Segment} vertical>
                    <Form>
                        <TextArea
                            name={'contentPlain'}
                            placeholder={'에세이를 입력하세요, 길이는 무관합니다\n짧더라도, 본인의 생각이나 느낌을 정리하여 작성해 보는 것을 추천합니다.'}
                            onChange={this.handleInputChange}
                            style={{fontSize: '1.2rem', lineHeight: '2rem', height: '50vh'}}
                            value={this.state.contentPlain}
                        />
                    </Form>

                </Container>
                <Container text as={Segment} vertical>
                    <Form.Field>
                        <Radio
                            label='전체공개'
                            name='public'
                            value='public'
                            checked={this.state.public === 'public' || this.state.public === ''}
                            onChange={this.handleInputChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label='팔로워'
                            name='public'
                            value='follower'
                            checked={this.state.public === 'follower'}
                            onChange={this.handleInputChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label='비공개'
                            name='public'
                            value='private'
                            checked={this.state.public === 'private'}
                            onChange={this.handleInputChange}/>
                    </Form.Field>
                    <p style={{fontSize: '1rem', color: 'grey', marginTop: '10px'}}>
                        전체공개와, 팔로워 공개를 선택하시면 북클럽 외의 사람들도 글을 읽을 수 있습니다. <br/>
                        다양한 사람들과 함께 생각을 나눌 수 있도록 공개해주세요. :) <br/>
                        북클럽 에세이의 경우 비공개를 선택하더라도, 멤버들에게는 보여집니다.
                    </p>
                </Container>
                <Container text as={Segment} vertical>
                    <Button onClick={this._uploadEssay} primary basic>
                        작성완료
                    </Button>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {userId, userInfo} = state.auth
    return {userId, userInfo};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EssayWriteContainer);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {removeHtmlTagSubString} from "../../middleware/TextHandler";
import {Segment, Container, Header, Divider, Button, Message,} from 'semantic-ui-react'
import Colors from "../../config/Colors";
import moment from "moment/moment";
import {withLineEnter} from "../../middleware/stringModify";

class AuthenticationWayComponent extends Component {

    render() {

        const {bookClub} = this.props;
        const {authenticationWay} = bookClub;

        if (!bookClub.authenticationWay) {
            return null;
        }

        return (
            <div style={{paddingTop: 60, paddingBottom: 60}}>
                <Segment as={Container} vertical text basic textAlign={'center'}>
                    <Header style={{}} size={'large'}>
                        독서 인증 방법
                        <Header.Subheader style={{marginTop: 5}}>
                            정해진 스케줄에 인증합니다.
                        </Header.Subheader>
                    </Header>
                </Segment>
                <Segment as={Container} text vertical>
                    <p style={{lineHeight: '2rem'}}>
                        {withLineEnter(authenticationWay)}
                    </p>
                    <Message>
                        <Message.Header style={{fontSize: '1rem'}}>
                            인증을 하면!
                        </Message.Header>
                        <Message.Content style={{fontSize: '1rem   '}}>
                            함께하는 멤버들이 책을 읽기 시작할 때,<br/>
                            함께하는 멤버들이 책을 다 읽었을 때,<br/>
                            서로에게 알려주기 때문에, 독서 페이스 메이커가 됩니다. <br/>
                            혼자 읽을 때 보다, 훨씬 더 책에 대해서 자주 생각하게 됩니다.
                        </Message.Content>
                    </Message>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthenticationWayComponent);

import axios from 'axios';
import _ from "lodash";
import {API_READERS_V1} from "../../constants/API";
import {
    INITIALIZE_APPLY_LIST,
    LOAD_APPLY_LIST_FAILURE,
    LOAD_APPLY_LIST_REQUEST,
    LOAD_APPLY_LIST_SUCCESS,
} from "./actionTypes";

export const loadApplyList = (token) => {

    return dispatch => {

        dispatch(loadApplyListRequest());

        axios
            .get(API_READERS_V1 + '/bookclub/apply/list/all', {
                headers: {jwt: token},
            })
            .then(response => {
                const applyListAll = response.data;
                const applyListApproved = _.filter(applyListAll, {status: 'approved'});
                const applyListBeforeApproval = _.filter(applyListAll, {status: 'beforeApproval'});

                dispatch(loadApplyListSuccess(applyListAll, applyListApproved, applyListBeforeApproval))
                console.log('[COMPLETE: REDUX] loadApplyList');
                // console.log('[COMPLETE: REDUX] loadApplyList', applyListAll);
            })
            .catch(e => {
                console.log('[FAILURE: REDUX] loadApplyList', e);
                dispatch(loadApplyListFailure())
            })
    }
};



export const loadApplyListRequest = () => {
    return {
        type: LOAD_APPLY_LIST_REQUEST
    }
}

export const loadApplyListSuccess = (applyListAll, applyListApproved, applyListBeforeApproval) => {
    return {
        type: LOAD_APPLY_LIST_SUCCESS,
        applyListAll, applyListApproved, applyListBeforeApproval
    }
};

export const loadApplyListFailure = () => {
    return {
        type: LOAD_APPLY_LIST_FAILURE
    }
}

export const initializeApplyList = () => {
    return {
        type: INITIALIZE_APPLY_LIST
    }
}


import React, {Component} from 'react';
import {Segment, Container, Form, Message, Grid, Button} from 'semantic-ui-react';
import axios from 'axios';
import {API} from '../../constants/API';
import * as authActions from '../../redux/actions/auth';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import Analytics from "../../middleware/analytics";

class Signup extends Component {
    state = {
        email: '',
        password: '',
        passwordConfirm: '',
        name: '',
        phoneNumber: '',

        message: []
    };


    componentDidMount() {
        Analytics.pageViewAll('/signup');
    }


    handleChange = (e, {value, name}) => {
        this.setState({
            [name]: value
        });
    };

    handleSubmit = e => {
        this.setState({message: ''});

        let message = [];

        console.log(this.state);
        if (!this.ValidateEmail(this.state.email)) {
            message.push('email 형식이 잘못되었습니다');
        }
        if (this.state.password.length < 6) {
            message.push('패스워드는 6자 이상으로 작성해주세요');
        }
        if (this.state.password !== this.state.passwordConfirm) {
            message.push('패스워드가 일치하지 않습니다');
        }
        if (this.state.name.length < 1) {
            message.push('이름을 올바르게 입력해주세요');
        }
        if (this.state.phoneNumber.length === null) {
            message.push('전화번호를 입력하세요');
        }
        if (message.length > 0) {
            console.log(message);
            this.setState({
                message: message
            });
            return;
        }

        const {email, password, passwordConfirm, name, phoneNumber} = this.state;

        const data = {
            name,
            email,
            password,
            phoneNumber
        };

        axios
            .post(API + '/auth/signup', data)
            .then(response => {
                const userInfo = response.data;
                const {email, phoneNumber, profileImage, token, userId, userName} = userInfo;
                this.props.loginSuccess(token, userId, email, userName, phoneNumber, profileImage, userInfo);

                localStorage.setItem('token', userInfo.token);

            })
            .catch(err => {
                console.log(err);
                message = [];
                if (err.response && err.response.data) {

                    message.push(err.response.data.error);
                    this.setState({message});
                }
            });
    };

    ValidateEmail = mail => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    };

    render() {
        const {isLoggedIn} = this.props;
        if (isLoggedIn) {
            const {from} = this.props.location.state || {from: {pathname: '/mypage/bookclub'}};
            return <Redirect to={from}/>;
        }

        const {email, password, passwordConfirm, name, phoneNumber} = this.state;
        return (
            <Grid style={{minHeight: '90vh'}} verticalAlign="middle">
                <Grid.Column>
                    <Segment as={Container} text>
                        <Form>
                            <Form.Input label="이메일" type="email" name="email" value={email}
                                        onChange={this.handleChange}/>
                            <Form.Input label="비밀번호" type="password" name="password" value={password}
                                        placeholder={'6자리 이상'}
                                        onChange={this.handleChange}/>
                            <Form.Input label="비밀번호 확인" type="password" name="passwordConfirm"
                                        value={passwordConfirm}
                                        onChange={this.handleChange}/>
                            <Form.Input label="이름" name="name" value={name}
                                        onChange={this.handleChange}/>

                            <Form.Input label="핸드폰 번호" name="phoneNumber" value={phoneNumber} type="number"
                                        onChange={this.handleChange}/>

                            <Button onClick={this.handleSubmit} color={'black'}>가입하기</Button>
                        </Form>
                        {this.state.message.length > 0 && (
                            <Message warning size="small">
                                <Message.List>
                                    {this.state.message.map((message, i) => {
                                        return <Message.Item key={i}>{message}</Message.Item>;
                                    })}
                                </Message.List>
                            </Message>
                        )}
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    const {isLoggedIn} = state.auth;
    return {isLoggedIn}
};

const mapDispatchToProps = dispatch => {
    return {
        loginSuccess: (token, userId, email, userName, phoneNumber, profileImage, userInfo) => {
            dispatch(authActions.loginSuccess(token, userId, email, userName, phoneNumber, profileImage, userInfo));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Signup);

import React, {Component} from 'react';

import {connect} from 'react-redux';
import Axios from 'axios';
import {API, API_READERS_V1} from '../../constants/API';
import {Loader, Table} from 'semantic-ui-react';

class AdminUser extends Component {
    state = {
        userList: [],
        isLoaded: false,
    };
    componentDidMount = () => {
        const token = localStorage.getItem('token');
        Axios
            .get(API_READERS_V1 + '/user/list/all', {
                headers: {jwt: token}
            })
            .then(response => {
                console.log(response.data);
                const userList = response.data;
                this.setState({userList, isLoaded: true});
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        const {userList, isLoaded} = this.state;
        if (!isLoaded) {
            return <div><Loader active/></div>
        }
        return (
            <div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>번호</Table.HeaderCell>
                            <Table.HeaderCell>_id</Table.HeaderCell>
                            <Table.HeaderCell>이름</Table.HeaderCell>
                            <Table.HeaderCell>nickname</Table.HeaderCell>
                            <Table.HeaderCell>e-mail</Table.HeaderCell>
                            <Table.HeaderCell>전화번호</Table.HeaderCell>
                            <Table.HeaderCell>프로필이미지</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            userList.map((user, i) => {
                                return (
                                    <Table.Row key={i}>
                                        <Table.Cell>{i + 1}</Table.Cell>
                                        <Table.Cell>
                                            <a href={'/admin/user/' + user._id}
                                               target='_blank'>
                                                {user._id}
                                            </a>
                                        </Table.Cell>
                                        <Table.Cell>{user.local.name}</Table.Cell>
                                        <Table.Cell>{user.local.nickname}</Table.Cell>
                                        <Table.Cell>{user.local.email}</Table.Cell>
                                        <Table.Cell>{user.phoneNumber}</Table.Cell>
                                        <Table.Cell>{user.profileImage.slice(0, 20)}</Table.Cell>
                                    </Table.Row>
                                );
                            })
                        }
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminUser);

import React, {Component} from 'react';
import {Card, Image, Icon, Label,} from 'semantic-ui-react'
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import moment from "moment/moment";
import Colors from "../../config/Colors";

class PublicBookClubCardComponent extends Component {


    render() {
        const {bookclub: bookClub} = this.props;

        if (!bookClub._id) {
            return (
                null
            )
        }
        switch (bookClub.status) {
            case 'beforeRecruiting' :
                return (
                    <Card as={Link} to={`/bookclub/${bookClub._id}`} style={{borderRadius: 0}}>
                        <Image src={bookClub.mainImage ? bookClub.mainImage : '/images/mockup/image.png'}
                               style={{borderRadius: 0}}/>
                        <Card.Content>
                            <Card.Header>
                                {bookClub.title} <br/>
                                <span style={{fontWeight: '400', fontSize: '1rem', color: Colors.inActiveGrey}}>
                                    {bookClub.subTitle.length > 25 ? bookClub.subTitle.substring(0, 25) + '...' : bookClub.subTitle}
                                </span> <br/>
                                <span style={{fontWeight: '400', fontSize: '1rem',}}>
                                    모임 기간: {moment(bookClub.startDate).format('YYYY. MM. DD')} - {moment(bookClub.endDate).format('YYYY. MM. DD')}
                                </span>
                            </Card.Header>
                        </Card.Content>
                    </Card>
                );
            case 'recruiting' :
                return (
                    <Card as={Link} to={`/bookclub/${bookClub._id}`} style={{borderRadius: 0}} className={'animated fadeIn'}>
                        <Image src={bookClub.mainImage ? bookClub.mainImage : '/images/mockup/image.png'}
                               style={{borderRadius: 0}}/>
                        <Card.Content>
                            <Card.Header>
                                {bookClub.title} <br/>
                                <span style={{fontWeight: '400', fontSize: '1rem', color: Colors.inActiveGrey}}>
                                    {bookClub.subTitle}
                                </span> <br/>
                                <span style={{fontWeight: '400', fontSize: '1rem',}}>
                                    모임기간: {moment(bookClub.startDate).format('YYYY. MM. DD')} - {moment(bookClub.endDate).format('YYYY. MM. DD')}
                                </span><br/>
                                <span style={{fontWeight: '400', fontSize: '1rem',}}>
                                    모집마감: {moment(bookClub.registerEndDate).format('YYYY. MM. DD')}
                                </span>
                            </Card.Header>
                        </Card.Content>
                    </Card>
                );
            case 'ing' :
                return (
                    <Card as={Link} to={`/bookclub/${bookClub._id}`} style={{borderRadius: 0}} className={'animated fadeIn'}>
                        <Image src={bookClub.mainImage ? bookClub.mainImage : '/images/mockup/image.png'}
                               style={{borderRadius: 0}}/>
                        <Card.Content>
                            <Card.Header>
                                {bookClub.title} <br/>
                                <span style={{fontWeight: '400', fontSize: '1rem', color: Colors.inActiveGrey}}>
                            {bookClub.subTitle.length > 25 ? bookClub.subTitle.substring(0, 25) + '...' : bookClub.subTitle}
                        </span> <br/>
                                <span style={{fontWeight: '400', fontSize: '1rem',}}>
                            {moment(bookClub.startDate).format('YYYY. MM. DD')} - {moment(bookClub.endDate).format('YYYY. MM. DD')}
                        </span>
                            </Card.Header>
                            <Card.Meta>
                                <br/>
                                <Icon name={'user'}/>
                                {bookClub.participants.length} 명 <br/>
                            </Card.Meta>
                        </Card.Content>
                    </Card>
                );
            case 'ended' :
                return (
                    <Card as={Link} to={`/bookclub/${bookClub._id}`} style={{borderRadius: 0}} className={'animated fadeIn'}>
                        <Image src={bookClub.mainImage ? bookClub.mainImage : '/images/mockup/image.png'}
                               style={{borderRadius: 0}}/>
                        <Card.Content>
                            <Card.Header>
                                {bookClub.title} <br/>
                                <span style={{fontWeight: '400', fontSize: '1rem', color: Colors.inActiveGrey}}>
                            {bookClub.subTitle.length > 25 ? bookClub.subTitle.substring(0, 25) + '...' : bookClub.subTitle}
                        </span> <br/>
                                <span style={{fontWeight: '400', fontSize: '1rem',}}>
                            {moment(bookClub.startDate).format('YYYY. MM. DD')} - {moment(bookClub.endDate).format('YYYY. MM. DD')}
                        </span>
                            </Card.Header>
                            <Card.Meta>
                                <br/>
                                <Icon name={'user'}/>
                                {bookClub.participants.length} 명 <br/>
                            </Card.Meta>
                        </Card.Content>
                    </Card>
                );
            default:
                return null
        }

    }
}

const mapStateToProps = (state) => {
    const {
        applyListAll
    } = state.applyList;
    return {
        applyListAll
    };
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PublicBookClubCardComponent);

import * as types from '../actions/actionTypes';
import update from 'react-addons-update';
import _ from "lodash";

const initialState = {
    bookClubListAll: _.times(2, _.constant({title: '', sub_title: ''})),
    bookClubListRecruiting: _.times(2, _.constant({title: '', sub_title: ''})),
    bookClubListIng: _.times(2, _.constant({title: '', sub_title: ''})),
    bookClubListEnded: _.times(2, _.constant({title: '', sub_title: ''})),
    isLoaded: false,
    failed: false,

    status: 'INIT'
};

const bookClubPublic = (state = initialState, action) => {

    switch (action.type) {
        case types.LOAD_BOOKCLUB_PUBLIC_REQUEST:
            return update(state, {
                status: {$set: 'REQUEST'},
                failed: {$set: false},
            });
        case types.LOAD_BOOKCLUB_PUBLIC_SUCCESS:
            return update(state, {
                status: {$set: 'SUCCESS'},
                isLoaded: {$set: true},
                failed: {$set: false},

                bookClubListAll: {$set: action.bookClubListAll},
                bookClubListRecruiting: {$set: action.bookClubListRecruiting},
                bookClubListIng: {$set: action.bookClubListIng},
                bookClubListEnded: {$set: action.bookClubListEnded},
            })
        case types.LOAD_BOOKCLUB_PUBLIC_FAILURE:
            return update(state, {
                status: {$set: "FAILURE"},
                failed: {$set: true}
            })
        default:
            return state

    }


}

export default bookClubPublic


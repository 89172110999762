import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {API_READERS_V1} from "../../constants/API";
import {
    Loader,
    Container,
    Icon,
    Input,
    Comment,
    Header,
    Segment,
    Form,
    Radio,
    TextArea,
    Button
} from 'semantic-ui-react'
import {withLineEnter} from "../../middleware/stringModify";
import AvatarComponent from '../../components/user/AvatarComponent'
import moment from 'moment'
import {Link, Redirect} from 'react-router-dom'
import {removeHtmlTagWithLineChange} from "../../middleware/TextHandler";
import Colors from "../../config/Colors";

class EssayModifyContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            essay: {},
            isLoadedEssay: false,
            failedEssay: false,

            public: 'public',
            title: '',
            contentPlain: ''


        }
    }

    componentDidMount() {
        this._loadEssay();
    }

    handleInputChange = (e, {value, name}) => {
        this.setState({[name]: value});
    };
    _loadEssay = async () => {


        console.log(this.props.match.params.essayId);
        const {essayId} = this.props.match.params;
        const token = await localStorage.getItem('token');

        axios
            .get(API_READERS_V1 + `/essay/${essayId}`, {
                headers: {jwt: token}
            })
            .then(response => {
                const essay = response.data;

                console.log(essay);

                if (essay.isDeleted) {
                    window.alert('에세이 정보를 찾을 수 없습니다.');
                    this.props.history.goBack();
                }
                this.setState({
                    essay,
                    title: essay.title,
                    contentPlain: essay.contentPlain ? essay.contentPlain : removeHtmlTagWithLineChange(essay.content),
                    isLoadedEssay: true,
                    failedEssay: false,
                    public: essay.public
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    failedEssay: true
                })
                window.alert('에세이 정보를 찾을 수 없습니다.');
                this.props.history.goBack();

            })
    };
    _uploadEssay = async () => {

        const a = window.confirm('수정하시겠습니까?');

        if (!a) {
            return;
        }

        const {
            title,
            contentPlain,
        } = this.state;

        const data = {
            title,
            contentPlain,
            public: this.state.public
        };

        const token = await localStorage.getItem('token');

        const {essayId} = this.props.match.params;

        axios
            .put(API_READERS_V1 + `/essay/${essayId}`, data, {
                headers: {jwt: token}
            })
            .then(response => {
                console.log(response.data);
                window.alert('수정이 완료되었습니다.')
                const {from} = this.props.location.state || {from: {pathname: '/mypage/bookclub'}};
                this.props.history.push(from);
            })
            .catch(error => {
                window.alert('에러가 발생했습니다.')
            })
    };

    _deleteEssay = async () => {
        const a = window.confirm('삭제하시겠습니까?\n되돌릴 수 없습니다.');
        if (!a) {
            return;
        }
        const token = await localStorage.getItem('token');
        const {essayId} = this.props.match.params;
        axios
            .delete(API_READERS_V1 + `/essay/${essayId}`, {
                headers: {jwt: token}
            })
            .then(response => {
                window.alert('삭제가 완료되었습니다.')
                const {from} = this.props.location.state || {from: {pathname: '/mypage/bookclub'}};
                this.props.history.push(from);
            })
            .catch(error => {
                window.alert('오류가 발생했습니다.\n재시도 해주세요')
            })

    };

    render() {
        const {
            isLoadedEssay,
            failedEssay,
            essay,
            comments,
        } = this.state;
        if (!isLoadedEssay) {
            return (
                <div>
                    <Loader active/>
                </div>
            )
        }
        return (
            <Fragment>
                <Container text
                           as={Segment}
                           vertical>

                    <Input value={this.state.title}
                           name={'title'}
                           placeholder={'제목을 입력하세요'}
                           onChange={this.handleInputChange}
                           style={{fontSize: '1.7rem', fontWeight: 'bold'}}
                           fluid/>
                </Container>
                <Container text as={Segment} vertical>
                    <Form>
                        <TextArea
                            name={'contentPlain'}
                            onChange={this.handleInputChange}
                            style={{fontSize: '1.2rem', lineHeight: '2rem', height: '50vh'}}
                            value={this.state.contentPlain}
                        />
                    </Form>

                </Container>
                <Container text as={Segment} vertical>
                    <Form.Field>
                        <Radio
                            label='전체공개'
                            name='public'
                            value='public'
                            checked={this.state.public === 'public' || this.state.public === ''}
                            onChange={this.handleInputChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label='팔로워'
                            name='public'
                            value='follower'
                            checked={this.state.public === 'follower'}
                            onChange={this.handleInputChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label='비공개'
                            name='public'
                            value='private'
                            checked={this.state.public === 'private'}
                            onChange={this.handleInputChange}/>
                    </Form.Field>
                    <p style={{fontSize: '1rem', color: 'grey', marginTop: '10px'}}>
                        전체공개와, 팔로워 공개를 선택하시면 북클럽 외의 사람들도 글을 읽을 수 있습니다. <br/>
                        다양한 사람들과 함께 생각을 나눌 수 있도록 공개해주세요. :) <br/>
                        북클럽 에세이의 경우 비공개를 선택하더라도, 멤버들에게는 보여집니다.
                    </p>
                </Container>
                <Container text as={Segment} vertical>
                    <Button onClick={this._uploadEssay} primary basic>
                        수정확인
                    </Button>
                    <Button onClick={this._deleteEssay} negative basic>
                        삭제하기
                    </Button>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {userId, userInfo} = state.auth
    return {userId, userInfo};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EssayModifyContainer);

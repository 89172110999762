import React, {Component, Fragment} from 'react';
import {Sidebar, Menu, Responsive, Container, Icon, Dropdown} from 'semantic-ui-react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {firebase} from "../../config/firebase";

class MenuBar extends Component {
    state = {
        sidebarToggle: false,
        isLoggedIn: false
    };

    componentDidMount() {
        /*firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({
                    isLoggedIn: true
                })
            } else {
                this.setState({
                    isLoggedIn: false
                })
            }
        })*/
    }


    render() {
        const {email} = this.props;
        const {isLoggedIn} = this.props;

        return (
            <Fragment>
                <Responsive maxWidth={764}>
                    <Sidebar as={Menu} vertical visible={this.state.sidebarToggle} animation="overlay" width="wide">
                        <Menu.Item onClick={() => {
                            this.setState({sidebarToggle: false});
                        }}>
                            닫기
                            <Icon name="close"/>
                        </Menu.Item>
                        <Menu.Item/>
                        <Menu.Item as={Link} to="/"
                                   name="Home"
                                   onClick={() => {
                                       this.setState({sidebarToggle: false});
                                   }}>
                            홈
                        </Menu.Item>
                        <Menu.Item as={Link} to="/bookclub"
                                   onClick={() => {
                                       this.setState({sidebarToggle: false});
                                   }}
                                   name="bookclub">
                            모임
                        </Menu.Item>
                        <Menu.Item as={Link} to="/app"
                                   onClick={() => {
                                       this.setState({sidebarToggle: false});
                                   }}
                                   name="app">
                            앱
                        </Menu.Item>
                        <Menu.Item as={Link} to="/space"
                                   onClick={() => {
                                       this.setState({sidebarToggle: false});
                                   }}
                                   name="space">
                            공간
                        </Menu.Item>
                        <Menu.Item>

                        </Menu.Item>
                        {
                            isLoggedIn ? (
                                <Fragment>
                                    <Menu.Item as={Link} to="/mypage/bookclub"
                                               onClick={() => {
                                                   this.setState({sidebarToggle: false});
                                               }}>
                                        나의 북클럽
                                    </Menu.Item>
                                    <Menu.Item as={Link} to="/mypage/myinfo"
                                               onClick={() => {
                                                   this.setState({sidebarToggle: false});
                                               }}>
                                        내 정보
                                    </Menu.Item>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Menu.Item as={NavLink} to={{pathname: '/login'}}
                                               onClick={() => {
                                                   this.setState({sidebarToggle: false});
                                               }}>
                                        로그인/회원가입
                                    </Menu.Item>
                                </Fragment>
                            )
                        }

                    </Sidebar>
                    <Menu fixed="top">
                        <Menu.Item onClick={() => {
                            this.setState({sidebarToggle: true});
                        }}>
                            <Icon name="sidebar"/>
                        </Menu.Item>
                    </Menu>
                </Responsive>

                <Responsive minWidth={765}>
                    <Menu fixed="top" secondary pointing style={{backgroundColor: 'white'}}>
                        <Container>
                            <Menu.Item as={Link} to="/" name="Home">
                                <span style={{fontSize: 20, fontWeight: 'bold'}}>리더스</span>
                            </Menu.Item>
                            <Menu.Item as={Link} to="/bookclub" name="bookclub">
                                <span style={{fontSize: 16}}>모임</span>
                            </Menu.Item>
                            {/*<Menu.Item as={Link} to="/bookshelf" name="bookclub">
                                    <span style={{fontSize: 16}}>서재</span>
                                </Menu.Item>*/}
                            <Menu.Item as={Link} to="/app" name="bookclub">
                                <span style={{fontSize: 16}}>앱</span>
                            </Menu.Item>
                            <Menu.Item as={Link} to="/space" name="space">
                                <span style={{fontSize: 16}}>공간</span>
                            </Menu.Item>
                            <Menu.Menu position="right">
                                {
                                    isLoggedIn ? (
                                        <Fragment>
                                            <Menu.Menu>
                                                <Dropdown text="마이페이지" className={'link item'}>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item as={Link} to="/mypage/bookclub">
                                                            북클럽
                                                        </Dropdown.Item>
                                                        {/*<Dropdown.Item as={Link} to="/mypage/essay">
                                                    에세이
                                                </Dropdown.Item>
                                                <Dropdown.Item as={Link} to="/mypage/bookshelf">
                                                    서재
                                                </Dropdown.Item>*/}
                                                        <Dropdown.Item as={Link} to="/mypage/myinfo">
                                                            내 정보
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Menu.Menu>
                                            {
                                                email === 'yhyun0513@gmail.com' ? (
                                                    <Menu.Menu>
                                                        <Dropdown text="관리자" className={'link item'}>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item as={Link} to="/admin/bookclub"
                                                                               name="ADMIN">
                                                                    북클럽 관리 하기
                                                                </Dropdown.Item>
                                                                <Dropdown.Item as={Link} to="/admin/user"
                                                                               name="ADMIN">
                                                                    유저 관리 하기
                                                                </Dropdown.Item>
                                                                <Dropdown.Item as={Link} to="/admin/recommend"
                                                                               name="ADMIN">
                                                                    추천 책장 관리
                                                                </Dropdown.Item>
                                                                <Dropdown.Item as={Link} to="/admin/essay"
                                                                               name="ADMIN">
                                                                    에세이 관리
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Menu.Menu>
                                                ) : null
                                            }
                                        </Fragment>
                                    ) : (
                                        <Menu.Item as={NavLink} to={{pathname: '/login'}}>
                                            로그인/회원가입
                                        </Menu.Item>
                                    )
                                }

                            </Menu.Menu>

                        </Container>
                    </Menu>
                </Responsive>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {isLoggedIn, email} = state.auth;
    return {isLoggedIn, email};
};

const mapDispatchToProps = {};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MenuBar)
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {API_READERS_V1} from "../../constants/API";
import {Container, Segment, Loader, Item, Header} from 'semantic-ui-react';
import {withLineEnter} from "../../middleware/stringModify";
import ReactGA from 'react-ga';
import Analytics from "../../middleware/analytics";


class RecommendDetailContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recommend: {},
            isLoaded: false,
        }
    }

    componentDidMount() {
        this._loadRecommend();
        const {recommendId} = this.props.match.params;
        Analytics.pageViewAll(`/recommend/${recommendId}`)

        window.scrollTo(0, 0);

    }

    _loadRecommend = () => {
        const {recommendId} = this.props.match.params;
        axios
            .get(API_READERS_V1 + `/recommend/${recommendId}`)
            .then(response => {
                const recommend = response.data;
                console.log(recommend.title)
                this.setState({
                    isLoaded: true,
                    recommend
                })
            })
            .catch(e => {
                console.log(e);
                window.alert('책장을 불러오는데 실패하였습니다.')
                this.props.history.goBack();
            })
    };



    render() {
        if (!this.state.isLoaded) {
            return (
                <Loader active/>
            )
        }
        const {
            recommend
        } = this.state;
        return (
            <div>
                <Container as={Segment} vertical text>
                    <Header>
                        {recommend.title}
                        <Header.Subheader>
                            {recommend.subTitle}
                        </Header.Subheader>
                    </Header>
                </Container>
                <Container as={Segment} vertical text>
                    {withLineEnter(recommend.content)}
                </Container>
                <Container as={Segment} vertical text>
                    <Item.Group>
                        {
                            recommend.bookList.map((book, i) => {
                                return (
                                    <Item key={i}>
                                        <Item.Image src={book.image && book.image.replace('m1', 'm5')} size={'tiny'}/>
                                        <Item.Content>
                                            <Item.Header>
                                                {book.title}
                                            </Item.Header>
                                            <Item.Meta>
                                                {book.author}
                                            </Item.Meta>
                                            <Item.Extra>
                                                {withLineEnter(book.recommend)}
                                            </Item.Extra>
                                        </Item.Content>
                                    </Item>
                                )
                            })
                        }
                    </Item.Group>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RecommendDetailContainer);

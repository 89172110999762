import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {API_READERS_V1} from "../../constants/API";
import {Segment, Table, Loader, Button, Container} from 'semantic-ui-react';
import moment from 'moment';
import numeral from 'numeral';
import {Link} from 'react-router-dom'

class AdminBookClubContainer extends Component {
    state = {
        bookClubList: [],
        isLoaded: false,
    };


    componentDidMount() {
        this._loadBookClubList();


    }

    _loadBookClubList = async () => {
        const token = localStorage.getItem('token');
        axios
            .get(API_READERS_V1 + '/bookclub/admin/v2/list', {
                headers: {jwt: token}
            })
            .then(response => {
                const bookClubList = response.data;
                ;
                console.log(bookClubList);
                this.setState({
                    bookClubList,
                    isLoaded: true,
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    render() {
        const {bookClubList, isLoaded} = this.state;
        if (!isLoaded) {
            return (
                <div style={{minHeight: '60vh'}}>
                    <Loader active>북클럽 정보를 받아오고 있습니다.</Loader>
                </div>
            );
        }
        return (
            <div>
                <Container as={Segment} vertical>
                    <Button as={Link} to={{pathname: `/admin/bookclub/make`}}>
                        북클럽 새로 만들기
                    </Button>
                </Container>
                <Container as={Segment} vertical fluid>
                    <Table size={'small'} celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>no.</Table.HeaderCell>
                                <Table.HeaderCell width={3}>
                                    title <br/>
                                    subTitle</Table.HeaderCell>
                                <Table.HeaderCell>bookList</Table.HeaderCell>
                                <Table.HeaderCell width={1} textAlign={'center'}>
                                    participants<br/>All<br/>
                                    (participants<br/>Confirmed)
                                </Table.HeaderCell>
                                <Table.HeaderCell>registerStartDate</Table.HeaderCell>
                                <Table.HeaderCell>registerEndDate</Table.HeaderCell>
                                <Table.HeaderCell>startDate</Table.HeaderCell>
                                <Table.HeaderCell>endDate</Table.HeaderCell>
                                <Table.HeaderCell>defaultMembershipFee</Table.HeaderCell>
                                <Table.HeaderCell>host_name</Table.HeaderCell>
                                <Table.HeaderCell>status</Table.HeaderCell>
                                <Table.HeaderCell>isPublic</Table.HeaderCell>
                                <Table.HeaderCell>more</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                bookClubList.map((bookClub, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>{i + 1}</Table.Cell>
                                            <Table.Cell>
                                                <b>{bookClub.title}</b> <br/>
                                                {bookClub.subTitle}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {bookClub.bookList && bookClub.bookList.length}
                                            </Table.Cell>
                                            <Table.Cell textAlign={'center'}>
                                                {bookClub.participantsAll.length}<br/>({bookClub.participantsConfirmed.length})
                                            </Table.Cell>
                                            <Table.Cell>
                                                {moment(bookClub.registerStartDate).format('YYYYMMDD HH:mm')}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {moment(bookClub.registerEndDate).format('YYYYMMDD HH:mm')}
                                            </Table.Cell>

                                            <Table.Cell>
                                                {moment(bookClub.startDate).format('YYYYMMDD HH:mm')}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {moment(bookClub.endDate).format('YYYYMMDD HH:mm')}
                                            </Table.Cell>

                                            <Table.Cell>
                                                {numeral(bookClub.defaultMembershipFee).format('0,0')}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {bookClub.hasHost ? (
                                                    <span>
                                                        {bookClub.hostInfo && bookClub.hostInfo.local.name}
                                                    </span>
                                                ) : (
                                                    '클럽장 X'
                                                )}
                                            </Table.Cell>
                                            <Table.Cell>{bookClub.status}</Table.Cell>
                                            <Table.Cell>{bookClub.isPublic ? '공개' : '비공개'}</Table.Cell>
                                            <Table.Cell>
                                                <Button as={Link}
                                                        to={{pathname: `/admin/bookclub/detail/${bookClub._id}`}}
                                                        size={'tiny'}>
                                                    상세보기
                                                </Button>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button as={Link}
                                                        to={{pathname: `/admin/bookclub/manage/${bookClub._id}`}}
                                                        size={'tiny'}>
                                                    에세이 관리
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminBookClubContainer);

import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Container, Divider, Segment, List, Header, Grid, Image} from 'semantic-ui-react'
import Colors from "../../config/Colors";
import ReactGA from 'react-ga';

const data = [
    {
        title: '북클럽 찾기',
        subTitle: '원하는 북클럽을 찾고',
        imageUrl: '/images/app/01.png',
    }, {
        title: '북클럽 소개',
        subTitle: '북클럽이 어떻게 진행되는 지 찾아볼 수 있습니다.',
        imageUrl: '/images/app/02.png',
    }, {
        title: '에세이 관리',
        subTitle: '다른 사람이 제출한 에세이를 보고',
        imageUrl: '/images/app/03.png',
    }, {
        title: '에세이 작성',
        subTitle: '소감문을 작성하고',
        imageUrl: '/images/app/04.png',
    }, {
        title: '책장 관리',
        subTitle: '읽어왔던 책들을 정리하고',
        imageUrl: '/images/app/05.png',
    }, {
        title: '책 카메라',
        subTitle: '인상깊은 구절을 밑줄치고 언제든지 찾아볼 수 있어요',
        imageUrl: '/images/app/06.png',
    },
]

class AppMainContainer extends Component {

    componentDidMount() {
        ReactGA.pageview('/app')
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <Fragment>
                <div style={{minHeight: '50vh', backgroundColor: 'black', paddingTop: 30}}>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn slow'}>
                        <p style={{color: 'white'}}>
                            계
                        </p>
                        <div style={{fontSize: '3rem', color: 'white', fontWeight: '700'}}
                             className={'hanSerifJapanese'}>
                            械
                        </div>
                        <Header inverted>
                            <Header.Subheader>
                                체계적으로 읽는 데 필요한 모바일 앱
                            </Header.Subheader>
                        </Header>
                    </Container>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn delay-1s slow'}>
                        <div style={{
                            backgroundImage: `url(/images/app.jpg)`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: '100%',
                            minHeight: 300,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end'
                        }}>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <span style={{color: 'white'}} className={'hanSerifJapanese'}>
                                리더스 앱
                            </span>
                        </div>
                    </Container>
                </div>
                <Container as={Segment} vertical basic text
                           style={{
                               marginTop: 50,
                               marginBottom: 50,
                           }}
                           textAlign={'center'}>
                    <div>
                        <Header>
                            리더스 械 (계)
                        </Header>
                        <p>
                            책을 읽는 데, 필요한 도구를 만듭니다.
                        </p>
                    </div>
                </Container>

                <Container as={Segment} vertical basic style={{fontSize: '1.14285714rem'}}>
                    <Grid stackable>
                        <Grid.Row columns={3}>
                            <Grid.Column>
                                <Header size={'large'} style={{textAlign: 'center'}}>
                                    모임
                                </Header>
                                <Image src={'/images/app/02m.png'} style={{margin: 'auto', width: '80%'}}/>
                                {/*<Carousel pauseOnHover={false}
                                          transitionMode={'fade'}
                                          withoutControls={true}
                                          wrapAround={true}
                                          autoplay={true}>
                                    <Image src={'/images/app/01m.png'}/>
                                    <Image src={'/images/app/02m.png'}/>
                                </Carousel>*/}
                                <List bulleted>
                                    <List.Item style={{lineHeight: '1.6rem'}}>
                                        온라인으로 모임을 진행 하는데 필요한 기능들이 포함되어 있습니다.
                                    </List.Item>
                                    <List.Item style={{lineHeight: '1.6rem'}}>
                                        에세이 작성
                                    </List.Item>
                                    <List.Item style={{lineHeight: '1.6rem'}}>
                                        에세이 읽기 등
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <Header size={'large'} style={{textAlign: 'center'}}>
                                    책장
                                </Header>
                                <Image src={'/images/app/05m.png'} style={{margin: 'auto', width: '80%'}}/>
                                {/*<Carousel pauseOnHover={false}
                                          transitionMode={'fade'}
                                          withoutControls={true}
                                          wrapAround={true}
                                          autoplay={true}>
                                    <Image src={'/images/app/04m.png'} />
                                    <Image src={'/images/app/05m.png'}/>
                                </Carousel>*/}
                                <List bulleted>
                                    <List.Item style={{lineHeight: '1.6rem'}}>
                                        온라인으로 서재를 관리할 수 있습니다.
                                    </List.Item>
                                    <List.Item style={{lineHeight: '1.6rem'}}>
                                        다른 사람들의 책장을 보고, 내 책장들을 보며, 어떤 책을 읽을 지 생각할 수 있습니다.
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <Header size={'large'} style={{textAlign: 'center'}}>
                                    기록 관리
                                </Header>
                                <Image src={'/images/app/06m.png'} style={{margin: 'auto', width: '80%'}}/>
                                {/*<Carousel pauseOnHover={false}
                                          transitionMode={'fade'}
                                          withoutControls={true}
                                          wrapAround={true}
                                          autoplay={true}>
                                    <Image src={'/images/app/03m.png'}/>
                                    <Image src={'/images/app/06m.png'}/>
                                </Carousel>*/}
                                <List bulleted>
                                    <List.Item style={{lineHeight: '1.6rem'}}>
                                        책을 읽다가 인상깊은 구절이나, 기억하고 싶은 부분을 찍어 둘 수 있습니다.
                                    </List.Item>
                                    <List.Item style={{lineHeight: '1.6rem'}}>
                                        사진에 밑줄을 긋고, 다른 사람에게 공유할 수 있습니다.
                                    </List.Item>
                                    <List.Item style={{lineHeight: '1.6rem'}}>
                                        인공지능 문자 인식을 통해 텍스트화 되어 저장되므로, 필요할 때 문자로 검색해 볼 수 있습니다.
                                    </List.Item>
                                </List>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppMainContainer);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionsMyBookClubs from '../../redux/actions/myBookClubs';
import {Card, Container, Segment, Header, Button,} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import axios from 'axios';
import MyBookClubCardComponent from '../../components/bookclub/MyBookClubCardComponent';
import MyBookClubCardBeforeApprovalComponent from '../../components/bookclub/MyBookClubCardBeforeApprovalComponent';

class MyBookClubContainer extends Component {

    componentDidMount() {
        this._loadMyBookClub()
        window.scrollTo(0, 0)
    }

    _loadMyBookClub = async () => {
        const token = await localStorage.getItem('token');
        this.props.loadMyBookClub(token, false);

    }

    render() {
        const {
            isLoaded,
            myBookClubList,
            myBookClubListRecruiting,
            myBookClubListIng,
            myBookClubListEnded,

            myBookClubListBeforeApproved,


            applyListIsLoaded,
            applyListAll,
            applyListApproved,
            applyListBeforeApproval,
        } = this.props;
        return (
            <div>
                {
                    myBookClubListBeforeApproved.length > 0 && (
                        <Container as={Segment} vertical>
                            <Header size={'large'} style={{}}>
                                승인 대기 중인 북클럽
                            </Header>
                            <Card.Group itemsPerRow={3} stackable>
                                {
                                    myBookClubListBeforeApproved.map((bookClub, i) => {
                                        return (
                                            <MyBookClubCardBeforeApprovalComponent bookclub={bookClub} key={i}/>
                                        )
                                    })
                                }
                            </Card.Group>
                        </Container>
                    )
                }

                {
                    myBookClubListIng.length + myBookClubListRecruiting.length > 0 && (
                        <Container as={Segment} vertical>
                            <Header size={'large'} style={{}}>
                                진행중 북클럽
                            </Header>
                            <Card.Group itemsPerRow={3} stackable>
                                {
                                    myBookClubListIng.concat(myBookClubListRecruiting).map((bookClub, i) => {
                                        return (
                                            <MyBookClubCardComponent bookclub={bookClub} key={i}/>
                                        )
                                    })
                                }
                            </Card.Group>
                        </Container>
                    )
                }
                {
                    myBookClubListEnded.length > 0 && (
                        <Container as={Segment} vertical>
                            <Header size={'large'} style={{}}>
                                종료된 북클럽
                            </Header>
                            <Card.Group itemsPerRow={3} stackable>
                                {
                                    myBookClubListEnded.map((bookClub, i) => {
                                        return (
                                            <MyBookClubCardComponent bookclub={bookClub} key={i}/>
                                        )
                                    })
                                }
                            </Card.Group>
                        </Container>
                    )
                }

                {
                    myBookClubListBeforeApproved.length + myBookClubListIng.length + myBookClubListRecruiting.length + myBookClubListEnded.length < 1 && (
                        <Container as={Segment} vertical>
                            <Header size={'large'} style={{}}>
                                북클럽 둘러보기
                            </Header>
                            <p>
                                아직 신청하신 북클럽이 없습니다
                            </p>
                            <Button as={Link} to={{pathname: '/bookclub'}}>
                                북클럽 찾아보기
                            </Button>
                        </Container>
                    )
                }


            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        isLoaded,
        myBookClubList,
        myBookClubListRecruiting,
        myBookClubListIng,
        myBookClubListEnded,

        myBookClubListBeforeApproved,

    } = state.myBookClubs;

    const {
        applyListAll,
        applyListApproved,
        applyListBeforeApproval,
        isLoaded: applyListLoaded
    } = state.applyList;
    return {
        isLoaded,
        myBookClubList,
        myBookClubListRecruiting,
        myBookClubListIng,
        myBookClubListEnded,

        applyListAll,
        applyListApproved,
        applyListBeforeApproval,
        applyListLoaded,

        myBookClubListBeforeApproved
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        loadMyBookClub: (token, isRefreshing) => dispatch(actionsMyBookClubs.loadMyBookClub(token, isRefreshing))
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MyBookClubContainer);

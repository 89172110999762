import {
    LOAD_MY_BOOKCLUB_REQUEST,
    LOAD_MY_BOOKCLUB_SUCCESS,
    LOAD_MY_BOOKCLUB_FAILURE,
    INITIALIZE_MY_BOOKCLUB,
    LOAD_MY_BOOKCLUB_BEFORE_APPROVED_REQUEST,
    LOAD_MY_BOOKCLUB_BEFORE_APPROVED_SUCCESS,
    LOAD_MY_BOOKCLUB_BEFORE_APPROVED_FAILURE
} from './actionTypes'

import axios from 'axios';
import {API} from "../../constants/API";
import _ from 'lodash'


export const loadMyBookClub = (token, isRefreshing = false) => {
    console.log('REDUX - MY BOOKCLUB 북클럽 로드 시작');

    return dispatch => {
        dispatch(loadMyBookClubRequest());
        dispatch(loadMyBookClubBeforeApproved(token, isRefreshing));
        axios
            .get(API + '/readers/v1/bookclub/my/list', {
                headers: {jwt: token}
            })
            .then(response => {
                console.log('[COMPLETE] REDUX - MY BOOKCLUB 북클럽 로드 완');
                const bookClubList = response.data;
                const bookClubListRecruiting = _.filter(bookClubList, {status: 'recruiting'});
                const bookClubListIng = _.filter(bookClubList, {status: 'ing'});
                const bookClubListEnded = _.filter(bookClubList, {status: 'ended'});
                dispatch(loadMyBookClubSuccess(bookClubList, bookClubListRecruiting, bookClubListIng, bookClubListEnded))

            })
            .catch(e => {
                console.log('[ERROR] REDUX - MY BOOKCLUB 북클럽 로드에서 에러발생');
                dispatch(loadMyBookClubFailure());
            })
    }
};

export const loadMyBookClubBeforeApproved = (token, isRefreshing = false) => {
    console.log('REDUX - MY BOOKCLUB 북클럽 로드 시작');

    return dispatch => {
        dispatch(loadMyBookClubBeforeApprovedRequest());
        axios
            .get(API + '/readers/v1/bookclub/my/list/beforeapproval', {
                headers: {jwt: token}
            })
            .then(response => {
                console.log('[COMPLETE] REDUX - MY BOOKCLUB 북클럽 로드 완');
                const bookClubListBeforeApproved = response.data;
                dispatch(loadMyBookClubMyBookClubBeforeApprovedRequestSuccess(bookClubListBeforeApproved))

            })
            .catch(e => {
                console.log('[ERROR] REDUX - MY BOOKCLUB 북클럽 로드에서 에러발생');
                dispatch(loadMyBookClubBeforeApprovedFailure());
            })
    }
};

export const loadMyBookClubRequest = () => {
    return {
        type: LOAD_MY_BOOKCLUB_REQUEST
    }
}
export const loadMyBookClubSuccess = (bookClubList, bookClubListRecruiting, bookClubListIng, bookClubListEnded) => {
    return {
        type: LOAD_MY_BOOKCLUB_SUCCESS,
        bookClubList,
        bookClubListRecruiting,
        bookClubListIng,
        bookClubListEnded
    }
}
export const loadMyBookClubFailure = () => {
    return {
        type: LOAD_MY_BOOKCLUB_FAILURE
    }
}

export const loadMyBookClubBeforeApprovedRequest = () => {
    return {
        type: LOAD_MY_BOOKCLUB_BEFORE_APPROVED_REQUEST
    }
}

export const loadMyBookClubMyBookClubBeforeApprovedRequestSuccess = (bookClubListBeforeApproved) => {
    return {
        type: LOAD_MY_BOOKCLUB_BEFORE_APPROVED_SUCCESS,
        bookClubListBeforeApproved,
    }
}

export const loadMyBookClubBeforeApprovedFailure = () => {
    return {
        type: LOAD_MY_BOOKCLUB_BEFORE_APPROVED_FAILURE
    }
}

export const initializeMyBookClub = () => {
    return {
        type: INITIALIZE_MY_BOOKCLUB
    }
}
import React, {Component} from 'react';
import {Sidebar,} from 'semantic-ui-react';
import MenuBar from './MenuBar';
import FooterComponent from './FooterComponent';


class Layout extends Component {
  render() {
    return (
      <div>
        <Sidebar.Pusher style={{paddingTop: 45}}>
          <MenuBar/>
          {this.props.children}
          <FooterComponent/>
        </Sidebar.Pusher>
      </div>
    );
  }
}

export default Layout;

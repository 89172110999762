import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Grid, Header, Button, Image, Card, Segment} from 'semantic-ui-react'
import axios from 'axios';
import moment from 'moment';
import {API_READERS_V1} from "../../constants/API";
import {Link} from 'react-router-dom';
import * as actionRecommend from "../../redux/actions/recommend";
import ReactGA from 'react-ga';
import Analytics from "../../middleware/analytics";

class BookshelfMainContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recommends: []
        }
    }

    componentDidMount() {
        this._loadRecommendList()
        Analytics.pageViewAll('/bookshelf')
        window.scrollTo(0, 0)
    }

    _loadRecommendList = async () => {
        this.props.loadRecommendList()
    };

    render() {
        return (
            <div>
                {/* 상단 헤더 */}
                {/*<Container className={'noMargin'} fluid style={{backgroundColor: 'black', padding: 10}}>
                    <Grid as={Container} stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column
                                style={{paddingBottom: 40, paddingTop: 40}}
                                verticalAlign={'middle'}
                                className={'headerGrid'}>
                                <Header style={{color: 'white', fontWeight: '400'}} size={'huge'}>
                                    <Header.Subheader style={{color: 'white', lineHeight: '2rem'}}>
                                        다른 사람들의 책장을 통해 <br/>
                                        책의 흐름을 읽고 어쩌구 저쩌구<br/>
                                        <br/>
                                    </Header.Subheader>
                                    책장
                                </Header>
                                <Button inverted size={'large'} onClick={() => {
                                    this.setState({
                                        showIntro: !this.state.showIntro
                                    })
                                }}>
                                    리더스가 무엇인가요?
                                </Button>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={'/images/white-mockup.png'} size={'large'} centered/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>*/}

                <Container as={Segment} vertical basic>
                    <Header>
                        인물에 관한 책
                    </Header>
                    <Card.Group itemsPerRow={3} stackable>
                        {
                            this.props.recommendsAll.map((recommend, i) => {
                                return (
                                    <Card key={i}
                                          style={{borderRadius: 0}}
                                          as={Link}
                                          to={{pathname: `/recommend/${recommend._id}`}}>
                                        <Image src={recommend.mainImage}/>
                                        <Card.Content>
                                            <Card.Header>
                                                {recommend.title}
                                            </Card.Header>
                                            <Card.Description>
                                                {recommend.subTitle}
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>
                                )
                            })
                        }
                    </Card.Group>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        recommendsAll
    } = state.recommend;

    return {

        recommendsAll
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        loadRecommendList: () => {
            dispatch(actionRecommend.loadRecommendList())
        }
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BookshelfMainContainer);

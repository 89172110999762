// LOGIN

export const AUTH_TOKEN_LOGIN = 'AUTH_TOKEN_LOGIN';
export const AUTH_TOKEN_LOGOUT = 'AUTH_TOKEN_LOGOUT';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';

export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE';


export const LOAD_BOOKCLUB_PUBLIC_REQUEST = 'LOAD_BOOKCLUB_PUBLIC_REQUEST';
export const LOAD_BOOKCLUB_PUBLIC_SUCCESS = 'LOAD_BOOKCLUB_PUBLIC_SUCCESS';
export const LOAD_BOOKCLUB_PUBLIC_FAILURE = 'LOAD_BOOKCLUB_PUBLIC_FAILURE';

export const LOAD_BOOKCLUB_PARTNER_REQUEST = 'LOAD_BOOKCLUB_PARTNER_REQUEST';
export const LOAD_BOOKCLUB_PARTNER_SUCCESS = 'LOAD_BOOKCLUB_PARTNER_SUCCESS';
export const LOAD_BOOKCLUB_PARTNER_FAILURE = 'LOAD_BOOKCLUB_PARTNER_FAILURE';

export const LOAD_RECOMMEND_LIST_REQUEST = 'LOAD_RECOMMEND_LIST_REQUEST';
export const LOAD_RECOMMEND_LIST_SUCCESS = 'LOAD_RECOMMEND_LIST_SUCCESS';
export const LOAD_RECOMMEND_LIST_FAILURE = 'LOAD_RECOMMEND_LIST_FAILURE';



export const LOAD_APPLY_LIST_REQUEST = 'LOAD_APPLY_LIST_REQUEST';
export const LOAD_APPLY_LIST_SUCCESS = 'LOAD_APPLY_LIST_SUCCESS';
export const LOAD_APPLY_LIST_FAILURE = 'LOAD_APPLY_LIST_FAILURE';




// 내 정보 가져오기

export const GET_MY_INFO = 'GET_MY_INFO';
export const GET_MY_INFO_REQUEST = 'GET_MY_INFO_REQUEST';
export const GET_MY_INFO_SUCCESS = 'GET_MY_INFO_SUCCESS';
export const GET_MY_INFO_FAILURE = 'GET_MY_INFO_FAILURE';

export const GET_MY_ESSAY_DETAIL = 'GET_MY_ESSAY_DETAIL';
export const GET_MY_ESSAY_DETAIL_SUCCESS = 'GET_MY_ESSAY_DETAIL_SUCCESS';
export const GET_MY_ESSAY_DETAIL_REQUEST = 'GET_MY_ESSAY_DETAIL_REQUEST';
export const GET_MY_ESSAY_DETAIL_FAILURE = 'GET_MY_ESSAY_DETAIL_FAILURE';


//
export const LOAD_MY_BOOKCLUB_REQUEST = 'LOAD_MY_BOOKCLUB_REQUEST';
export const LOAD_MY_BOOKCLUB_SUCCESS = 'LOAD_MY_BOOKCLUB_SUCCESS';
export const LOAD_MY_BOOKCLUB_FAILURE = 'LOAD_MY_BOOKCLUB_FAILURE';

export const LOAD_MY_BOOKCLUB_BEFORE_APPROVED_REQUEST = 'LOAD_MY_BOOKCLUB_BEFORE_APPROVED_REQUEST';
export const LOAD_MY_BOOKCLUB_BEFORE_APPROVED_SUCCESS = 'LOAD_MY_BOOKCLUB_BEFORE_APPROVED_SUCCESS';
export const LOAD_MY_BOOKCLUB_BEFORE_APPROVED_FAILURE = 'LOAD_MY_BOOKCLUB_BEFORE_APPROVED_FAILURE';

export const INITIALIZE_MY_BOOKCLUB = 'INITIALIZE_MY_BOOKCLUB'
export const INITIALIZE_APPLY_LIST = 'INITIALIZE_APPLY_LIST'
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Segment, Header, Icon} from 'semantic-ui-react'

class ReadersWayComponent extends Component {
    render() {
        return (
            <div style={{paddingTop: 20, paddingBottom: 20, backgroundColor: '#E8E8E8'}}>
                <Container as={Segment} vertical style={{marginBottom: 30}} basic>
                    <Header size={'large'} style={{fontWeight: '900'}}>
                        리더스 북클럽 진행 방법
                    </Header>
                </Container>
                <Container
                    style={{
                        marginBottom: 30,
                        display: 'flex',
                        // justifyContent: 'space-evenly',
                        flexWrap: 'nowrap',
                        overflowX: 'auto',
                        scrollbar: 'none',
                        WebkitScrollbarDisplay: 'none',
                        overflowScrolling: 'touch',
                        WebkitOverflowScrolling: "touch",
                    }}>
                    <div style={styles.readersBookClubWayModule}>
                        <Icon name={'money bill alternate outline'} size={'big'}/>
                        <div style={{textAlign: 'center', marginTop: 10}}>
                            <span style={{fontWeight: 'bold'}}>
                                디파짓
                            </span> <br/>
                            읽는 책만큼 디파짓을 내고 <br/>
                            (확실히 읽게 됩니다)
                        </div>
                    </div>
                    <div style={styles.readersBookClubWayModule}>
                        <Icon name={'camera retro'} size={'big'}/>
                        <div style={{textAlign: 'center', marginTop: 10}}>
                            <span style={{fontWeight: 'bold'}}>
                                독서인증 1
                            </span> <br/>
                            책을 읽기 시작할때,<br/>
                            다 읽었을 때, 인증합니다.
                        </div>
                    </div>
                    <div style={styles.readersBookClubWayModule}>
                        <Icon name={'keyboard outline'} size={'big'}/>
                        <div style={{textAlign: 'center', marginTop: 10}}>
                            <span style={{fontWeight: 'bold'}}>
                                독서인증 2
                            </span><br/>
                            생각 정리를 위한 글쓰기!<br/>
                            에세이를 제출합니다.
                        </div>
                    </div>
                    <div style={styles.readersBookClubWayModule}>
                        <Icon name={'mobile alternate'} size={'big'}/>
                        <div style={{textAlign: 'center', marginTop: 10}}>
                            <span style={{fontWeight: 'bold'}}>
                                전용 앱
                            </span> <br/>
                            밑줄 친 부분과 메모를 공유!<br/>
                            에세이 작성/읽기가 가능합니다
                        </div>
                    </div>
                    <div style={styles.readersBookClubWayModule}>
                        <Icon name={'undo'} size={'big'}/>
                        <div style={{textAlign: 'center', marginTop: 10}}>
                            <span style={{fontWeight: 'bold'}}>
                                환급
                            </span> <br/>
                            정해진 기간 내 인증을 끝내면,<br/>
                            디파짓을 환급합니다. <br/>
                        </div>
                    </div>
                </Container>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReadersWayComponent);


const styles = {
    readersBookClubWayModule: {
        width: 220,
        height: 180,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: 2,
        padding: 10,
        display: 'flex',
        flex: '0 0 auto'
    }
}
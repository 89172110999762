import React, {Component} from 'react';
import {connect} from 'react-redux';
import {removeHtmlTagSubString} from "../../middleware/TextHandler";
import {Segment, Container, Header, Divider, Button, Card,} from 'semantic-ui-react'
import Colors from "../../config/Colors";
import moment from "moment/moment";

class PublicEssayModuleComponent extends Component {
    state = {
        showAllPublicEssay: false
    }

    render() {

        const {bookClub, publicEssays} = this.props;

        if (publicEssays.length < 1) {
            return null;
        }

        return (
            <div style={{paddingTop: 60, paddingBottom: 60}}>
                <Segment as={Container} vertical text basic textAlign={'center'}>
                    <Header style={{}} size={'large'}>
                        공개 에세이
                        <Header.Subheader style={{marginTop: 5}}>
                            총 {bookClub.essays.length}개의 에세이가 작성되었습니다. <br/>
                            그중 {publicEssays.length}개의 에세이가 공개되어 있습니다.
                        </Header.Subheader>
                    </Header>
                </Segment>
                <Segment as={Container} vertical>
                    <Card.Group itemsPerRow={3} stackable>
                        {
                            publicEssays.slice(0, this.state.showAllPublicEssay ? 100 : 6).map((essay, i) => {
                                return (
                                    <Card key={i} style={{borderRadius: 0}}>
                                        <Card.Content>
                                            <div
                                                style={{
                                                    backgroundColor: 'lightgrey',
                                                    backgroundImage: `url(${essay.bookInfo.image.replace('m1', 'm5')})`,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                    width: '40px',
                                                    height: '60px',
                                                    margin: 5,
                                                    float: 'left',
                                                }}>
                                            </div>
                                            <p style={{fontSize: '1rem'}}>
                                                {essay.title}
                                            </p>
                                            <p style={{fontSize: '1rem', color: Colors.inActiveGrey}}>
                                                {removeHtmlTagSubString(essay.contentPlain ? essay.contentPlain : essay.content, 100)} [...]
                                            </p>
                                        </Card.Content>
                                        <Card.Content>
                                            <div style={{display: 'flex'}}>
                                                <div
                                                    style={{
                                                        backgroundColor: 'lightgrey',
                                                        backgroundImage: `url(${essay.userInfo.profileImage})`,
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'cover',
                                                        width: '25px',
                                                        height: '25px',
                                                        borderRadius: '13px',
                                                        marginLeft: 5,
                                                        marginRight: 5,

                                                    }}>
                                                </div>
                                                <div>
                                                    <div style={{fontSize: '0.8rem', lineHeight: '0.8rem'}}>
                                                        {essay.userInfo.local.name} <br/>
                                                        {moment(essay.created).format('YY. MM. DD')}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Content>
                                    </Card>
                                )
                            })
                        }
                    </Card.Group>
                    <Divider hidden/>
                    {
                        publicEssays.length > 6 && (
                            <div style={{textAlign: 'center'}}>
                                <Button onClick={() => {
                                    this.setState({showAllPublicEssay: !this.state.showAllPublicEssay})
                                }}>
                                    {this.state.showAllPublicEssay ? '감추기' : '전체보기'}
                                </Button>
                            </div>
                        )
                    }
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PublicEssayModuleComponent);

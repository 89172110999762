import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

const apiKeyGA = 'UA-132383201-1';
const apiKeyPixel = '312296316076481';

// const isDevMode = true;
const isDevMode = false;

let isInitializedGA = false;
let isInitializedPixel = false;


const initializeGA = () => {
    ReactGA.initialize(apiKeyGA);
    isInitializedGA = true;
};

const initializePixel = () => {
    ReactPixel.init(apiKeyPixel);
    isInitializedPixel = true;
};

const maybeInitializeGA = () => {
    if (!isInitializedGA) {
        initializeGA()
    }
};

const maybeInitializePixel = () => {
    if (!isInitializedPixel) {
        initializePixel()
    }
};

const pageViewGA = (route) => {
    maybeInitializeGA();
    ReactGA.pageview(route);
}

const pageViewPixel = () => {
    maybeInitializePixel()
    ReactPixel.pageView()

}

const pageViewAll = (route) => {
    if (isDevMode) {
        return;
    }
    pageViewGA(route);
    pageViewPixel(route);
};

const trackPixel = (event, data) => {
    if (isDevMode) {
        return;
    }
    maybeInitializePixel();
    ReactPixel.track(event, data);
}

const trackGA = (event) => {
    if (isDevMode) {
        return;
    }

    maybeInitializeGA();
    ReactGA.event({})
}



export default {
    initializeGA,
    initializePixel,
    maybeInitializeGA,
    maybeInitializePixel,
    pageViewGA,
    pageViewPixel,
    pageViewAll,
    trackPixel,
    trackGA,
}




import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Segment, Header,} from "semantic-ui-react";

class ReadingListComponent extends Component {
    render() {
        const {bookClub} = this.props;
        return (
            <div style={{paddingBottom: 60, paddingTop: 60, backgroundColor: 'white'}}>
                <Segment as={Container} vertical text basic textAlign={'center'}>
                    <Header style={{}} size={'large'}>
                        읽는 책
                        <Header.Subheader style={{marginTop: 5}}>
                            {bookClub.numberOfWeeks}주 동안 {bookClub.bookList.length}권의 책을 읽습니다
                        </Header.Subheader>
                    </Header>
                </Segment>
                <Segment as={Container} text vertical>
                    <div
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}>
                        {
                            bookClub.bookList.map((book, i) => {
                                return (
                                    <div key={i}>
                                        <div
                                            style={{
                                                backgroundColor: 'lightgrey',
                                                backgroundImage: `url(${book.image.replace('m1', 'm5')})`,
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                width: '100px',
                                                height: '155px',
                                                margin: 5,
                                            }}>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReadingListComponent);

import React, {Component, Fragment} from 'react';

import {connect} from 'react-redux';
import {Segment, Container, Button, Form, Input, Image, Grid, Header, Card, Divider, Message} from 'semantic-ui-react';
import axios from 'axios';
import {API_READERS_V1} from "../../constants/API";
import moment from "moment/moment";
import numeral from "numeral";
import {readersBankAccount, readersBankAccountHolder, readersBankName} from "../../config/informations";
import Colors from "../../config/Colors";

class ApplyBookClubContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,
            isLoadedBookClub: false,
            bookClub: {},


            participantName: props.userName,
            phone: props.phoneNumber,
            email: props.email,
            commentToHost: ''

        };
    }



    componentDidMount() {
        this.loadBookClubInfo()
        window.scrollTo(0, 0)
    }

    loadBookClubInfo = async () => {
        const {bookClubId} = this.props.match.params

        axios
            .get(API_READERS_V1 + '/bookclub/one', {
                params: {
                    bookClubId
                }
            })
            .then(response => {
                const bookClub = response.data[0];

                if (bookClub.partner) {
                    window.alert('정상적인 접근이 아닙니다')
                    return this.props.history.push({pathname:'/'})
                }

                this.setState({
                    bookClub,
                    isLoadedBookClub: true,
                    failed: false,
                })
            })
            .catch(e => {
                console.log(e);
            })
    };

    handleSubmit = () => {

        if (this.state.userName === '' || this.state.userName === null) {
            return window.alert('이름을 입력해 주세요')
        }
        if (this.state.phone === '' || !this.state.phone) {
            return window.alert('전화 번호를 입력해 주세요')
        }
        this.setState({
            fetching: true
        });
        const confirm = window.confirm('신청하겠습니까?');
        if (!confirm) {
            this.setState({fetching: false});
            return;
        }


        console.log('신청합니다');
        console.log(this.props.match.params.bookClubId);

        const data = {
            bookclubId: this.props.match.params.bookClubId,
            participantName: this.state.userName,
            phone: this.state.phone,
            email: this.state.email,
            commentToHost: this.state.commentToHost,
        };

        axios
            .post(API_READERS_V1 + '/bookclub/apply', data, {
                headers: {
                    jwt: this.props.token
                }
            })
            .then(response => {
                // console.log(response.data);
                window.alert('신청이 완료되었습니다.');
                this.setState({fetching: false});
                this.props.history.push({pathname: `/mypage/bookclub`});
            })
            .catch(e => {
                const message = e.response.data ? e.response.data.message : '에러 발생';
                window.alert(message);
                this.setState({fetching: false});
            })



    };

    render() {
        const {fetching, bookClub, isLoadedBookClub} = this.state;
        if (!isLoadedBookClub) {
            return (
                <div style={{minHeight: '80vh'}}>

                </div>
            )
        }
        const $bookClubInfo = (
            <Segment as={Container} vertical basic>
                <Grid as={Container} stackable>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <Image src={bookClub.mainImage}/>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Header size={'large'}>
                                {bookClub.title}
                                <Header.Subheader>
                                    {bookClub.subTitle}
                                </Header.Subheader>
                            </Header>
                            <div>
                                시작: {moment(bookClub.startDate).format('YYYY. MM. DD')} <br/>
                                종료: {moment(bookClub.endDate).format('YYYY. MM. DD')}
                            </div>
                            <Card fluid style={{borderRadius: 0}}>
                                <Card.Content>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                        회비 : {numeral(bookClub.totalAmountMembershipFee).format('0,0')} 원
                                    </p>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                        - 디파짓
                                        : {numeral(bookClub.depositPerEssay * bookClub.numOfEssays).format('0,0')} 원
                                    </p>
                                    <Divider style={{marginLeft: '50%'}}/>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                        <span style={{fontSize: '0.8rem', color: Colors.inActiveGrey}}>
                                            에세이를 쓰기만 하면 결국 내는 금액은,
                                        </span> <br/>
                                        :{numeral(bookClub.defaultMembershipFee).format('0,0')} 원
                                    </p>
                                    <p>
                                        * 디파짓 {numeral(bookClub.depositPerEssay).format('0,0')} 원
                                        x {bookClub.numOfEssays}회(에세이
                                        작성 수) <br/>
                                        독서 인증을 할 때마다 디파짓을 환급해 드립니다.
                                    </p>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
        return (
            <div style={{minHeight: '60vh'}}>
                {$bookClubInfo}
                <Segment as={Container} vertical basic text>
                    <Message>
                        <Message.Header>
                            확인해주세요
                        </Message.Header>
                        <Message.List style={{fontSize: '1rem'}}>
                            <Message.Item>
                                {numeral(bookClub.totalAmountMembershipFee).format('0,0')} 원을 아래 계좌로 입금해주세요 <br/>
                                {readersBankAccount} {readersBankName} {readersBankAccountHolder}
                            </Message.Item>
                            <Message.Item>
                                이름(입금자명)을 바르게 입력해주세요. 이름, 입금자명이 틀리면 확인이 어렵습니다.
                            </Message.Item>
                            <Message.Item>
                                입금이 확인되면, 24시간이내로 문자+email로 연락을 드립니다. 핸드폰 번호와 e-mail을 꼭 확인해주세요
                            </Message.Item>
                            <Message.Item>
                                북클럽이 시작되기 전에는 100% 환불이 가능합니다.
                            </Message.Item>
                            <Message.Item>
                                디파짓은 독서인증이 끝날때마다 환급해 드립니다.
                            </Message.Item>
                        </Message.List>
                    </Message>
                    <Form>
                        <Form.Input fluid
                                    label={'이름(입금자명)'}
                                    value={this.state.participantName}
                                    onChange={(e, d) => {
                                        this.setState({
                                            participantName: d.value
                                        })
                                    }}/>
                        <Form.Input fluid
                                    label={'전화번호'}
                                    value={this.state.phone}
                                    onChange={(e, d) => {
                                        this.setState({
                                            phone: d.value
                                        })
                                    }}/>
                        <Form.Input fluid
                                    label={'이메일'}
                                    value={this.state.email}
                                    onChange={(e, d) => {
                                        this.setState({
                                            email: d.value
                                        })
                                    }}/>
                        <Form.Field>
                            <Form.TextArea
                                label={'기타'}
                                placeholder={'클럽장에게 하고 싶은 말 / 자기소개'}
                                rows={7}
                                value={this.state.commentToHost}
                                onChange={(e, d) => {
                                    this.setState({
                                        commentToHost: d.value
                                    })
                                }}/>
                        </Form.Field>
                    </Form>
                    <Button
                        style={{marginTop: 20}}
                        fluid
                        color={'black'}
                        onClick={this.handleSubmit}
                        disabled={fetching}
                        loading={fetching}>
                        신청하기
                    </Button>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        userName,
        userId,
        token,
        phoneNumber,
        email,
    } = state.auth;
    return {
        userName,
        userId,
        token,
        phoneNumber,
        email,
    }
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplyBookClubContainer);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {firebase} from "../../config/firebase";
import {Image} from 'semantic-ui-react'

class AvatarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUrl: null,
            fileName: props.fileName,
            bucket: props.bucket,
            uri: props.uri,
        }
    }

    componentDidMount() {
        this._getResizedImage()

    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps !== this.props) {
            this._getResizedImage()
        }
    }

    _getResizedImage = async () => {
        const {
            fileName,
            bucket,
            uri,
        } = this.props;

        // console.log(this.props);

        let imageUrl = '';
        if (bucket && fileName) {
            imageUrl = await firebase.storage().ref().child(`${bucket}/thumb_400_${fileName}.jpg`).getDownloadURL();
        } else if (uri) {
            imageUrl = uri
        } else {
            imageUrl = null
        }
        this.setState({
            imageUrl
        })
    };

    render() {
        const backgroundColor = 'lightgrey';
        const width = this.props.size;
        const height = this.props.size;
        const {uri, fileName, bucket} = this.props;
        const {
            imageUrl
        } = this.state;

        if (!uri && !bucket) {
            return (
                <div
                    style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        width: width,
                        height: height,
                        borderRadius: width / 2,

                    }}>
                </div>
            )
        } else if (uri) {
            return (
                <div style={{
                    backgroundImage: `url(https://react.semantic-ui.com/images/wireframe/square-image.png)`,
                    width: width,
                    height: height,
                    borderRadius: width / 2,
                }}>
                    <div
                        style={{
                            backgroundImage: `url(${imageUrl})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: width,
                            height: height,
                            borderRadius: width / 2,

                        }}>
                    </div>
                </div>
            )
        } else {
            return (
                <div>

                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AvatarComponent);

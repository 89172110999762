import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Button, Segment, Message, Grid} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {firebase} from "../../config/firebase";

class SignInContainer extends Component {
    state = {
        email: '',
        password: '',
        isFetching: false,
        message: ''
    };


    componentDidMount() {
        const user = firebase.auth().currentUser;
        console.log({user})
    }


    handleChange = (e, {value, name}) => {
        this.setState({[name]: value});
    };

    handleSubmit = e => {
        e.preventDefault();
        console.log(this.state);

        this.setState({
            isFetching: true
        });
        this.handleLogin();
    };

    handleLogin = () => {
        const {email, password} = this.state;
        firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => {
                    return firebase
                        .auth()
                        .signInWithEmailAndPassword(email, password)
                        .then(user => {
                            console.log(user);
                            this.setState({
                                isFetching: false
                            })
                        })
                }
            )
            .catch(error => {
                console.log(error);
                this.setState({
                    isFetching: false
                })
            })
    };

    render() {
        const {isFetching} = this.state;
        return (
            <Grid textAlign="center" style={{height: '90vh'}} verticalAlign="middle">
                <Grid.Column style={{maxWidth: 450}}>
                    <Form size="large">
                        <Segment stacked>
                            <Form.Input fluid icon="user" iconPosition="left" placeholder="E-mail address"
                                        value={this.state.email} name={'email'} onChange={this.handleChange}/>
                            <Form.Input fluid icon="lock" iconPosition="left" placeholder="Password" type="password"
                                        value={this.state.password} name="password" onChange={this.handleChange}/>

                            <Button color="blue" basic fluid size="large" onClick={this.handleSubmit}
                                    loading={isFetching}>
                                Login
                            </Button>
                        </Segment>
                    </Form>
                    <Message>
                        <Link to={{pathname: '/signup'}}>Sign Up</Link>
                    </Message>
                    <Message>{this.state.message}</Message>
                </Grid.Column>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignInContainer);

import React, {Component, Fragment} from 'react';

import {connect} from 'react-redux';
import {Segment, Container, Button, Form, Input, Image, Grid, Header, Card, Divider, Message} from 'semantic-ui-react';
import axios from 'axios';
import {API_READERS_V1} from "../../constants/API";
import moment from "moment/moment";
import numeral from "numeral";
import {readersBankAccount, readersBankAccountHolder, readersBankName} from "../../config/informations";
import Colors from "../../config/Colors";

class ApplyBookClubPartnerContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,
            isLoadedBookClub: false,
            bookClub: {},


            participantName: props.userName,
            phone: props.phoneNumber,
            email: props.email,
            commentToHost: '',
            partnerPassword: '',

        };
    }



    componentDidMount() {
        this.loadBookClubInfo()
        window.scrollTo(0, 0)
    }

    loadBookClubInfo = async () => {
        const {bookClubId} = this.props.match.params;

        axios
            .get(API_READERS_V1 + '/bookclub/one', {
                params: {
                    bookClubId
                }
            })
            .then(response => {
                const bookClub = response.data[0];

                if (!bookClub.partner) {
                    window.alert('정상적인 접근이 아닙니다')
                    return this.props.history.push({pathname:'/'})
                }

                this.setState({
                    bookClub,
                    isLoadedBookClub: true,
                    failed: false,
                });
            })
            .catch(e => {
                console.log(e);
            })
    };

    handleSubmit = () => {

        if (this.state.userName === '' || this.state.userName === null) {
            return window.alert('이름을 입력해 주세요')
        }
        if (this.state.phone === '' || !this.state.phone) {
            return window.alert('전화 번호를 입력해 주세요')
        }
        if (this.state.partnerPassword === '' || !this.state.partnerPassword) {
            return window.alert('클럽 초대 비밀번호를 입력해 주세요')
        }
        this.setState({
            fetching: true
        });
        const confirm = window.confirm('신청하겠습니까?');
        if (!confirm) {
            this.setState({fetching: false});
            return;
        }


        console.log('신청합니다');
        console.log(this.props.match.params.bookClubId);

        const data = {
            bookclubId: this.props.match.params.bookClubId,
            participantName: this.state.userName,
            phone: this.state.phone,
            email: this.state.email,
            commentToHost: this.state.commentToHost,
            partnerPassword: this.state.partnerPassword,
        };

        axios
            .post(API_READERS_V1 + '/bookclub/partner/apply', data, {
                headers: {
                    jwt: this.props.token
                }
            })
            .then(response => {
                // console.log(response.data);
                window.alert('신청이 완료되었습니다.');
                this.setState({fetching: false});
                this.props.history.push({pathname: `/mypage/bookclub`});
            })
            .catch(e => {
                const message = e.response.data ? e.response.data.message : '에러 발생';
                window.alert(message);
                this.setState({fetching: false});
            })



    };

    render() {
        const {fetching, bookClub, isLoadedBookClub} = this.state;
        if (!isLoadedBookClub) {
            return (
                <div style={{minHeight: '80vh'}}>

                </div>
            )
        }
        const $bookClubInfo = (
            <Segment as={Container} vertical basic>
                <Grid as={Container} stackable>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <Image src={bookClub.mainImage}/>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Header size={'large'}>
                                {bookClub.title}
                                <Header.Subheader>
                                    {bookClub.subTitle}
                                </Header.Subheader>
                            </Header>
                            <div>
                                시작: {moment(bookClub.startDate).format('YYYY. MM. DD')} <br/>
                                종료: {moment(bookClub.endDate).format('YYYY. MM. DD')}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
        return (
            <div style={{minHeight: '60vh'}}>
                {$bookClubInfo}
                <Segment as={Container} vertical basic text>
                    <Message>
                        <Message.Header>
                            확인해주세요
                        </Message.Header>
                        <Message.List style={{fontSize: '1rem'}}>
                            <Message.Item>
                                북클럽 운영자에게 비밀번호를 확인 받아주세요
                            </Message.Item>
                            <Message.Item>
                                기타 문의 사항은 북클럽 운영자에게 확인해 주세요 :)
                            </Message.Item>
                        </Message.List>
                    </Message>
                    <Form>
                        <Form.Input fluid
                                    label={'이름'}
                                    value={this.state.participantName}
                                    onChange={(e, d) => {
                                        this.setState({
                                            participantName: d.value
                                        })
                                    }}/>
                        <Form.Input fluid
                                    label={'전화번호'}
                                    value={this.state.phone}
                                    onChange={(e, d) => {
                                        this.setState({
                                            phone: d.value
                                        })
                                    }}/>
                        <Form.Input fluid
                                    label={'클럽초대 비밀번호'}
                                    value={this.state.partnerPassword}
                                    onChange={(e, d) => {
                                        this.setState({
                                            partnerPassword: d.value
                                        })
                                    }}/>
                        <Form.Field>
                            <Form.TextArea
                                label={'기타'}
                                placeholder={'클럽장에게 하고 싶은 말 / 자기소개'}
                                rows={7}
                                value={this.state.commentToHost}
                                onChange={(e, d) => {
                                    this.setState({
                                        commentToHost: d.value
                                    })
                                }}/>
                        </Form.Field>
                    </Form>
                    <Button
                        style={{marginTop: 20}}
                        fluid
                        color={'black'}
                        onClick={this.handleSubmit}
                        disabled={fetching}
                        loading={fetching}>
                        신청하기
                    </Button>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        userName,
        userId,
        token,
        phoneNumber,
        email,
    } = state.auth;
    return {
        userName,
        userId,
        token,
        phoneNumber,
        email,
    }
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplyBookClubPartnerContainer);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Segment, Container, Header} from "semantic-ui-react";
import Colors from "../../config/Colors";

class FooterComponent extends Component {
    render() {
        return (
            <div style={{paddingTop: 50, paddingBottom: 30, backgroundColor: Colors.mainColor, marginTop: 100}}>
                <Container as={Segment} basic vertical>
                    <Header inverted style={{fontWeight: '900'}} size={'large'}>
                        리더스
                    </Header>
                    <div style={{color: 'white', fontSize: '14px', lineHeight: '25px'}}>
                        선별된 좋은 책을 읽습니다. <br/>
                        좋은 사람들과 함께 꾸준히 읽고, <br/>
                        같은 책을 읽고, 느낀 서로 다른 생각들을 통해 <br/>
                        혼자 읽었을 때, 가질 수 없는 입체적인 독서를 할 수 있습니다.
                    </div>
                </Container>
                <Container as={Segment} basic vertical>
                    <div style={{color: 'white', fontSize: '14px', lineHeight: '25px'}}>
                        문의하기 <br/>
                        <a target={'_blank'} href="http://pf.kakao.com/_xawQfj/chat" style={{color: '#fdeb4f'}}>
                            카카오플러스 친구 : 리더스 @bd_readers
                        </a> <br/>
                        <a target={'_blank'} href="mailto:readers.pjt@gmail.com" style={{color: 'white'}}>
                            email :readers.pjt@gmail.com
                        </a>
                    </div>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FooterComponent);

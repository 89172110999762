import React, {Component} from 'react';

import {connect} from 'react-redux';
import HomeBookClubCardComponent from '../../components/bookclub/PublicBookClubCardComponent';
import {Card, Segment, Container, Divider, Header, Button} from 'semantic-ui-react';
import * as actionBookClubPublic from "../../redux/actions/bookClubPublic";
import ReactGA from 'react-ga';
import Analytics from "../../middleware/analytics";

class BookClubListEndedContainer extends Component {
    state = {
        ingList: [],
        recruitList: [],
        endedList: []
    };
    componentDidMount = () => {
        this.props.loadBookClubList();
        Analytics.pageViewAll('/bookclub/list/ended');

        window.scrollTo(0, 0);
    };


    render() {
        const {
            bookClubListEnded,
        } = this.props;
        const endedListSegment = () => {
            return bookClubListEnded.map((bookclub, i) => {
                return <HomeBookClubCardComponent bookclub={bookclub} key={i}/>;
            });
        };

        return (
            <div style={{marginTop: 50, marginBottom: 50, minHeight: '80vh'}}>
                <Segment as={Container} vertical basic>
                    <Header size={'large'}>
                        종료된 북클럽
                    </Header>
                    <Divider/>
                    <Card.Group itemsPerRow={3} stackable>
                        {endedListSegment()}
                    </Card.Group>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        bookClubListAll,
        bookClubListRecruiting,
        bookClubListIng,
        bookClubListEnded,
        isLoaded,
        failed
    } = state.bookClubPublic
    return {
        bookClubListAll,
        bookClubListRecruiting,
        bookClubListIng,
        bookClubListEnded,
        isLoaded,
        failed
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadBookClubList: () => {
            dispatch(actionBookClubPublic.loadBookClubList())
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookClubListEndedContainer);

import axios from 'axios';
import _ from "lodash";
import {API_READERS_V1} from "../../constants/API";
import {
    LOAD_BOOKCLUB_PARTNER_FAILURE,
    LOAD_BOOKCLUB_PARTNER_REQUEST,
    LOAD_BOOKCLUB_PARTNER_SUCCESS,
} from "./actionTypes";

export const loadPartnerBookClubList = () => {

    return dispatch => {

        dispatch(loadPartnerBookClubListRequest());

        axios
            .get(API_READERS_V1 + '/bookclub/list/partner')
            .then(response => {
                const bookClubListAll = response.data;
                const bookClubListRecruiting = _.filter(bookClubListAll, {status: 'recruiting'});
                const bookClubListIng = _.filter(bookClubListAll, {status: 'ing'});
                const bookClubListEnded = _.filter(bookClubListAll, {status: 'ended'});

                dispatch(loadPartnerBookClubListSuccess(
                    bookClubListAll,
                    bookClubListRecruiting,
                    bookClubListIng,
                    bookClubListEnded))
                console.log('[COMPLETE: REDUX] PARTNER BOOKCLUB 로드 완료');
            })
            .catch(e => {
                console.log('[FAILURE: REDUX] PARTNER BOOKCLUB 로드 실패', e);
                dispatch(loadPartnerBookClubListFailure())
            })
    }
};



export const loadPartnerBookClubListRequest = () => {
    return {
        type: LOAD_BOOKCLUB_PARTNER_REQUEST
    }
}

export const loadPartnerBookClubListSuccess = (bookClubListAll,
                                        bookClubListRecruiting,
                                        bookClubListIng,
                                        bookClubListEnded) => {
    return {
        type: LOAD_BOOKCLUB_PARTNER_SUCCESS,
        bookClubListAll,
        bookClubListRecruiting,
        bookClubListIng,
        bookClubListEnded,
    }
};

export const loadPartnerBookClubListFailure = () => {
    return {
        type: LOAD_BOOKCLUB_PARTNER_FAILURE
    }
}


import axios from 'axios';
import {
    AUTH_LOGIN_FAILURE,
    AUTH_LOGIN_REQUEST,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT_FAILURE,
    AUTH_LOGOUT_REQUEST,
    AUTH_LOGOUT_SUCCESS,
} from './actionTypes';

import {API} from '../../constants/API';
import {initializeApplyList} from "./applyList";
import {initializeMyBookClub} from "./myBookClubs";

export const userInfoUpdate = (token) => {
    return dispatch => {
        axios
            .get(API + '/auth/my-info', {
                headers: {jwt: token}
            })
            .then(response => {
                const userInfo = response.data;
                console.log('[COMPLETE] REDUX - AUTH - userInfoUpdate');
                const {
                    email, phoneNumber, profileImage, userId, userName
                } = userInfo;
                dispatch(loginSuccess(token, userId, email, userName, phoneNumber, profileImage, userInfo));
            })
            .catch(error => {
                console.log('[ERROR] REDUX - AUTH - userInfoUpdate', error);
            })

    }
};

export function logout() {
    return dispatch => {
        dispatch(logoutRequest());
        localStorage.clear();
        dispatch(initializeApplyList());
        dispatch(initializeMyBookClub());
    };
}

export function logoutRequest() {
    return {type: AUTH_LOGOUT_REQUEST};
}

export function logoutSuccess() {
    return {type: AUTH_LOGOUT_SUCCESS};
}

export function logoutFailure() {
    return {type: AUTH_LOGOUT_FAILURE};
}



export function loginRequest() {
    return {type: AUTH_LOGIN_REQUEST};
}

export function loginFailure() {
    return {type: AUTH_LOGIN_FAILURE};
}

export function loginSuccess(token, userId, email, userName, phoneNumber, profileImage, userInfo) {
    return {
        type: AUTH_LOGIN_SUCCESS,
        token: token,
        userId: userId,
        email: email,
        userName: userName,
        phoneNumber,
        profileImage,
        userInfo
    };
}

import React, { Component } from 'react';
import { connect } from 'react-redux';

class AdminEssayMainContainer extends Component {
    render() {
        return (
            <div>
                헬로
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    };
}
const mapDispatchToProps = (dispatch) => {
    return {

    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminEssayMainContainer);

import React, {Component, Fragment} from 'react';

import {connect} from 'react-redux';
import HomeBookClubCardComponent from '../../components/bookclub/PublicBookClubCardComponent';
import PartnerBookClubCardComponent from '../../components/bookclub/PartnerBookClubCardComponent';
import {Card, Segment, Container, Divider, Header, Button} from 'semantic-ui-react';
import * as actionBookClubPublic from "../../redux/actions/bookClubPublic";
import {Link} from 'react-router-dom'
import Analytics from "../../middleware/analytics";

export class BookClubMainContainer extends Component {
    state = {
        ingList: [],
        recruitList: [],
        endedList: []
    };
    componentDidMount = () => {
        this.props.loadBookClubList();
        Analytics.pageViewAll('/bookclub');

        window.scrollTo(0, 0);
    };


    render() {
        const {
            bookClubListRecruiting,
            bookClubListIng,
            bookClubListEnded,
            bookClubListRecruitingPartner
        } = this.props;
        const recruitListSegment = () => {
            if (bookClubListRecruiting.length === 0) {
                return '현재 모집중인 북클럽이 없습니다.';
            }
            return bookClubListRecruiting.map((bookclub, i) => {
                return <HomeBookClubCardComponent bookclub={bookclub} key={i}/>;
            });
        };
        const recruitListPartnerSegment = () => {
            if (bookClubListRecruitingPartner.length === 0) {
                return null
            }
            return bookClubListRecruitingPartner.map((bookclub, i) => {
                return <PartnerBookClubCardComponent bookclub={bookclub} key={i}/>;
            });
        };
        const ingListSegment = () => {
            if (bookClubListIng.length === 0) {
                return '현재 진행중인 북클럽이 없습니다.';
            }
            return bookClubListIng.slice(0, 6).map((bookclub, i) => {
                return <HomeBookClubCardComponent bookclub={bookclub} key={i}/>;
            });
        };
        const endedListSegment = () => {
            return bookClubListEnded.slice(0, 6).map((bookclub, i) => {
                return <HomeBookClubCardComponent bookclub={bookclub} key={i}/>;
            });
        };

        return (

            <Fragment>
                <div style={{minHeight: '50vh', backgroundColor: 'black', paddingTop: 30, marginBottom: 50}}>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn slow'}>
                        <p style={{color: 'white'}}>
                            회
                        </p>
                        <div style={{fontSize: '3rem', color: 'white', fontWeight: '700'}}
                             className={'hanSerifJapanese'}>
                            會
                        </div>
                        <Header inverted>
                            <Header.Subheader>
                                함께 읽고, 생각을 나누는 책모임
                            </Header.Subheader>
                        </Header>
                    </Container>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn delay-1s slow'}>
                        <div style={{
                            backgroundImage: `url(/images/moimColor.png)`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: '100%',
                            minHeight: 300,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end'
                        }}>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <span style={{color: 'white'}} className={'hanSerifJapanese'}>
                                리더스, 로마인 이야기 모임
                            </span>
                        </div>
                    </Container>
                </div>
                <Segment as={Container} vertical basic style={styles.module}>
                    <Header>
                        모집중 북클럽
                    </Header>
                    <Divider/>
                    <Card.Group itemsPerRow={3}
                                stackable>
                        {recruitListSegment()}
                    </Card.Group>
                </Segment>


                {
                    this.props.bookClubListRecruitingPartner !== 0 ? (
                        <Segment as={Container} vertical basic style={styles.module}>
                            <Header>
                                모집중 제휴 북클럽
                            </Header>
                            <Divider/>
                            <Card.Group itemsPerRow={3}
                                        stackable>
                                {recruitListPartnerSegment()}
                            </Card.Group>
                        </Segment>
                    ) :null
                }



                <Segment as={Container} vertical basic style={styles.module}>
                    <Header>
                        진행중 북클럽
                        <Header.Subheader as={Link} to={{pathname: '/bookclub/list/ing'}}>
                            {bookClubListIng.length}개 전체보기 >
                        </Header.Subheader>
                    </Header>
                    <Divider/>
                    <Card.Group itemsPerRow={3}
                                stackable>
                        {ingListSegment()}
                    </Card.Group>
                </Segment>
                <Segment as={Container} vertical basic style={styles.module}>

                    <Header>
                        종료된 북클럽
                        <Header.Subheader as={Link} to={{pathname: '/bookclub/list/ended'}}>
                            {bookClubListEnded.length}개 전체보기 >
                        </Header.Subheader>
                    </Header>
                    <Divider/>
                    <Card.Group itemsPerRow={3} stackable>
                        {endedListSegment()}
                    </Card.Group>
                </Segment>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        bookClubListAll,
        bookClubListRecruiting,
        bookClubListIng,
        bookClubListEnded,
        isLoaded,
        failed
    } = state.bookClubPublic;

    const {
        bookClubListRecruiting: bookClubListRecruitingPartner
    } = state.bookClubPartner

    return {
        bookClubListAll,
        bookClubListRecruiting,
        bookClubListIng,
        bookClubListEnded,
        isLoaded,
        failed,

        bookClubListRecruitingPartner,


    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadBookClubList: () => {
            dispatch(actionBookClubPublic.loadBookClubList())
        },

    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookClubMainContainer);


const styles = {
    module: {
        marginBottom: 100
    }
}
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import numeral from "numeral";
import {Card, Divider, Button} from 'semantic-ui-react'
import Colors from "../../config/Colors";
import {readersBankAccount} from "../../config/informations";

class PartnerApplyModuleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amIApplyThisBookClub: null,
            applyStatus: ''
        }
    }

    componentDidMount() {

    }

    componentWillMount() {
        this._checkAmIApply();
    }


    componentWillUpdate(nextProps, nextState) {

    }

    componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this._checkAmIApply()
        }
    }

    _checkAmIApply = () => {
        const {applyListAll, bookClub} = this.props;
        // console.log(applyListAll);

        if (!bookClub._id) {
            return;
        }

        const amIApplyThisBookClubIndex = applyListAll.findIndex(apply => apply.bookclubId.toString() === bookClub._id.toString())
        if (amIApplyThisBookClubIndex > -1) {
            this.setState({
                amIApplyThisBookClub: true,
                applyStatus: applyListAll[amIApplyThisBookClubIndex].status
            })
        } else {
            this.setState({
                amIApplyThisBookClub: false,
            })
        }
    };

    render() {
        const {bookClub} = this.props;
        const {amIApplyThisBookClub, applyStatus} = this.state;

        switch (amIApplyThisBookClub) {
            case true :
                return (
                    <Fragment>
                        {
                            applyStatus === 'approved' ? (
                                <Card fluid style={{borderRadius: 0}}>
                                    <Card.Content>
                                        <Button fluid color={'black'} to={{pathname: `/mybookclub/${bookClub._id}`}}
                                                as={Link}>
                                            멤버 페이지로 들어가기
                                        </Button>
                                    </Card.Content>
                                </Card>
                            ) : (
                                <Card fluid style={{borderRadius: 0}}>
                                    <Card.Content>
                                        신청이 완료되었습니다. <br/>
                                    </Card.Content>
                                    <Card.Content>
                                        <Button disabled fluid color={'black'}>
                                            승인 대기중
                                        </Button>
                                    </Card.Content>
                                </Card>
                            )
                        }
                    </Fragment>
                );
            case false :
                switch (bookClub.status) {
                    case 'beforeRecruiting' :
                        return (
                            <Card fluid style={{borderRadius: 0}}>
                                <Card.Content>
                                    <Button fluid color={'black'} disabled>
                                        곧 모집이 시작됩니다.
                                    </Button>
                                </Card.Content>
                            </Card>
                        );
                    case 'recruiting' :
                        return (
                            <Card fluid style={{borderRadius: 0}}>
                                <Card.Content>
                                    <Button fluid color={'black'} to={{pathname: `/partner/bookclub/apply/${bookClub._id}`}}
                                            as={Link}>
                                        북클럽 신청하기
                                    </Button>
                                </Card.Content>
                            </Card>
                        );
                    case 'ing' :
                        return (
                            <Card fluid style={{borderRadius: 0}}>
                                <Card.Content>
                                    <Button fluid color={'grey'}>
                                        진행중입니다.
                                    </Button>
                                </Card.Content>
                            </Card>
                        );
                    case 'ended':
                        return (
                            <Card fluid style={{borderRadius: 0}}>
                                <Card.Content>
                                    <Button fluid color={'grey'}>
                                        종료된 북클럽입니다.
                                    </Button>
                                </Card.Content>
                            </Card>
                        );
                    default:
                        return null
                }
            default :
                return null
        }
    }
}

const mapStateToProps = (state) => {
    const {
        applyListAll
    } = state.applyList;
    return {
        applyListAll
    };
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PartnerApplyModuleComponent);

import firebase from 'firebase';

var config = {
    apiKey: 'AIzaSyBUt6E7vbAaGgHh-et0sxV5vQifGGuwwWk',
    authDomain: 'readers-api.firebaseapp.com',
    databaseURL: 'https://readers-api.firebaseio.com',
    projectId: 'readers-api',
    storageBucket: 'readers-api.appspot.com',
    messagingSenderId: '299147667105'
};

const firebaseApp = firebase.initializeApp(config);



export {firebase, firebaseApp};

import React, {Component} from 'react';
import {BrowserRouter, Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';

import './App.css';
import './Animate.css';
import 'semantic-ui-css/semantic.min.css';

import SignUpContainer from "./containers/9-auth/SignUpContainer";
import SignInContainer from "./containers/9-auth/SignInContainer";

import Layout from './components/shared/Layout';
import Login from './containers/shared/Login';
import Signup from './containers/shared/Signup';
import MyPageContainer from './containers/mypage/MyPageContainer';
import BookClubMainContainer from './containers/bookClubPublic/BookClubMainContainer';
import ApplyBookClubContainer from './containers/bookclubMy/ApplyBookClubContainer';
import AdminUser from './containers/0-admin-user/AdminUser';
import MyBookshelfAll from './containers/mypage/MyBookshelfAll';
import MyEssayAll from './containers/mypage/MyEssayAll';
import MyInfo from './containers/mypage/MyInfo';
import BookshelfMainContainer from "./containers/bookshelfPublic/BookshelfMainContainer";

import * as authActions from './redux/actions/auth';
import HomeContainer from "./containers/home/HomeContainer";
import BookClubDetailContainer from "./containers/bookClubPublic/BookClubDetailContainer";
import AdminUserDetailContainer from "./containers/0-admin-user/AdminUserDetailContainer";

import MyBookClubContainer from "./containers/bookclubMy/MyBookClubContainer";
import MyBookClubDetailContainer from "./containers/bookclubMy/MyBookClubDetailContainer";
import EssayDetailContainer from "./containers/essay/EssayDetailContainer";
import EssayModifyContainer from "./containers/essay/EssayModifyContainer";
import EssayWriteContainer from "./containers/essay/EssayWriteContainer";
import AdminRecommendMainContainer from "./containers/0-admin-recommend/AdminRecommendMainContainer";
import AdminRecommendMakeContainer from "./containers/0-admin-recommend/AdminRecommendMakeContainer";
import AdminRecommendDetailContainer from "./containers/0-admin-recommend/AdminRecommendDetailContainer";
import RecommendDetailContainer from "./containers/bookshelfPublic-recommend/RecommendDetailContainer";
import AdminBookClubContainer from "./containers/0-admin-bookclub/AdminBookClubContainer";
import AdminBookClubMakeContainer from "./containers/0-admin-bookclub/AdminBookClubMakeContainer";
import AdminBookClubDetailContainer from "./containers/0-admin-bookclub/AdminBookClubDetailContainer";
import BookClubListEndedContainer from "./containers/bookClubPublic/BookClubListEndedContainer";
import BookClubListIngContainer from "./containers/bookClubPublic/BookClubListIngContainer";
import BookClubListRecruiting from "./containers/bookClubPublic/BookClubListRecruiting";
import AppMainContainer from "./containers/3-app/AppMainContainer";
import * as actionBookClubPublic from "./redux/actions/bookClubPublic";
import * as actionBookClubPartner from "./redux/actions/bookClubPartner";
import * as actionRecommend from "./redux/actions/recommend";
import * as actionApplyList from "./redux/actions/applyList";
import * as actionsMyBookClubs from "./redux/actions/myBookClubs";
import AboutReadersContainer from "./containers/5-about/AboutReadersContainer";
import SpaceMainContainer from "./containers/4-space/SpaceMainContainer";
import Analytics from './middleware/analytics';
import BookClubPartnerMainContainer from "./containers/bookClub-partner/BookClubPartnerMainContainer";
import BookClubPartnerDetailContainer from "./containers/bookClub-partner/BookClubPartnerDetailContainer";
import ApplyBookClubPartnerContainer from "./containers/bookClub-partner/ApplyBookClubPartnerContainer";
import AdminBookClubManageContainer from "./containers/0-admin-bookclub/AdminBookClubManageContainer";
import AdminEssayMainContainer from "./containers/0-admin-essay/AdminEssayMainContainer";
import AdminEssayDetailContainer from "./containers/0-admin-essay/AdminEssayDetailContainer";



class App extends Component {

    componentWillMount() {

        Analytics.initializePixel();
        Analytics.initializeGA();

        const token = localStorage.getItem('token');
        if (token) {
            console.log(token);
            this.props.userInfoUpdate(token);
            this.props.loadApplyList(token)
        }
        this.props.loadBookClubList();
        this.props.loadPartnerBookClubList();
        this.props.loadRecommendList();
    }


    componentWillUpdate(nextProps, nextState) {
        if (nextProps.isLoggedIn === true && this.props.isLoggedIn === false) {
            this.props.loadApplyList(nextProps.token);
            this.props.loadMyBookClub(nextProps.token, false);
        }
    }



    render() {
        const {isLoggedIn} = this.props;
        return (
            <BrowserRouter>
                <Layout>
                    <Route exact path="/" component={HomeContainer}/>
                    <Route exact
                           path="/signup"
                           component={Signup}/>
                    <Route exact
                           path="/login"
                           component={Login}/>

                    <Route exact
                           path="/bookclub"
                           component={BookClubMainContainer}/>
                    <Route exact
                           path="/bookclub/:bookClubId"
                           component={BookClubDetailContainer}/>
                    <Route exact
                           path="/bookclub/list/ended"
                           component={BookClubListEndedContainer}/>
                    <Route exact
                           path="/bookclub/list/ing"
                           component={BookClubListIngContainer}/>
                    <Route exact
                           path="/bookclub/list/recruiting"
                           component={BookClubListRecruiting}/>

                    <Route exact
                           path="/bookshelf"
                           component={BookshelfMainContainer}/>

                    <Route exact
                           path="/recommend/:recommendId"
                           component={RecommendDetailContainer}/>

                    <Route exact
                           path="/partner"
                           component={BookClubPartnerMainContainer}/>
                    <Route exact
                           path="/partner/bookclub/:bookClubId"
                           component={BookClubPartnerDetailContainer}/>

                    <Route exact
                           path="/app"
                           component={AppMainContainer}/>
                    <Route exact
                           path="/space"
                           component={SpaceMainContainer}/>
                    <Route exact
                           path="/about"
                           component={AboutReadersContainer}/>


                    {/*  ADMIN */}

                    <PrivateRoute path="/admin/bookclub"
                                  exact
                                  component={AdminBookClubContainer} isLoggedIn={isLoggedIn}/>

                    <PrivateRoute path="/admin/bookclub/make"
                                  exact
                                  component={AdminBookClubMakeContainer}
                                  isLoggedIn={isLoggedIn}/>

                    <PrivateRoute exact
                                  path="/admin/bookclub/detail/:bookClubId"
                                  component={AdminBookClubDetailContainer}
                                  isLoggedIn={isLoggedIn}/>



                    {/* 새로 생겨서 작업 해야 하는 부분 */}
                    <PrivateRoute exact
                                  path="/admin/bookclub/manage/:bookClubId"
                                  component={AdminBookClubManageContainer}
                                  isLoggedIn={isLoggedIn}/>

                    <PrivateRoute exact
                                  path="/admin/essay"
                                  component={AdminEssayMainContainer}
                                  isLoggedIn={isLoggedIn}/>

                    <PrivateRoute exact
                                  path="/admin/essay/:essayId"
                                  component={AdminEssayDetailContainer}
                                  isLoggedIn={isLoggedIn}/>




                    <PrivateRoute exact
                                  path="/admin/user"
                                  component={AdminUser}
                                  isLoggedIn={isLoggedIn}/>
                    <PrivateRoute exact
                                  path="/admin/user/:userId"
                                  component={AdminUserDetailContainer}
                                  isLoggedIn={isLoggedIn}/>

                    <PrivateRoute exact
                                  path="/admin/recommend"
                                  component={AdminRecommendMainContainer}
                                  isLoggedIn={isLoggedIn}/>
                    <PrivateRoute exact
                                  path="/admin/recommend/read/:recommendId"
                                  component={AdminRecommendDetailContainer}
                                  isLoggedIn={isLoggedIn}/>
                    <PrivateRoute exact
                                  path="/admin/recommend/make"
                                  component={AdminRecommendMakeContainer}
                                  isLoggedIn={isLoggedIn}/>





                    {/* 로그인 후 */}

                    <PrivateRoute exact
                                  path="/bookclub/apply/:bookClubId"
                                  component={ApplyBookClubContainer}
                                  isLoggedIn={isLoggedIn}/>

                    <PrivateRoute exact
                                  path="/partner/bookclub/apply/:bookClubId"
                                  component={ApplyBookClubPartnerContainer}
                                  isLoggedIn={isLoggedIn}/>

                    {/* 마이 북클럽 */}

                    <PrivateRoute exact
                                  path="/mybookclub/:id"
                                  component={MyBookClubDetailContainer}
                                  isLoggedIn={isLoggedIn}/>

                    <PrivateRoute exact
                                  path="/mybookclub/essay/write"
                                  component={EssayWriteContainer}
                                  isLoggedIn={isLoggedIn}/>

                    <PrivateRoute exact
                                  path="/mybookclub/essay/read/:essayId"
                                  component={EssayDetailContainer}
                                  isLoggedIn={isLoggedIn}/>

                    <PrivateRoute exact
                                  path="/mybookclub/essay/modify/:essayId"
                                  component={EssayModifyContainer}
                                  isLoggedIn={isLoggedIn}/>


                    <PrivateRoute exact
                                  path="/mypage/bookclub"
                                  component={MyBookClubContainer}
                                  isLoggedIn={isLoggedIn}/>
                    <PrivateRoute exact
                                  path="/mypage/bookshelf"
                                  component={MyBookshelfAll}
                                  isLoggedIn={isLoggedIn}/>

                    <PrivateRoute exact
                                  path="/mypage/essay"
                                  component={MyEssayAll}
                                  isLoggedIn={isLoggedIn}/>
                    <PrivateRoute exact
                                  path="/mypage/myinfo"
                                  component={MyInfo}
                                  isLoggedIn={isLoggedIn}/>
                    <PrivateRoute exact
                                  path="/mypage"
                                  component={MyPageContainer}
                                  isLoggedIn={isLoggedIn}/>

                </Layout>
            </BrowserRouter>
        );
    }
}

const PrivateRoute = ({component: Component, isLoggedIn, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            isLoggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: props.location}
                    }}
                />
            )
        }
    />
);


const mapStateToProps = state => {
    const {isLoggedIn, token} = state.auth;
    return {
        isLoggedIn,
        token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        userInfoUpdate: token => {
            dispatch(authActions.userInfoUpdate(token))
        },
        loadBookClubList: () => {
            dispatch(actionBookClubPublic.loadBookClubList())
        },
        loadPartnerBookClubList: () => {
            dispatch(actionBookClubPartner.loadPartnerBookClubList())
        },
        loadRecommendList: () => {
            dispatch(actionRecommend.loadRecommendList())
        },
        loadApplyList: (token) => {
            dispatch(actionApplyList.loadApplyList(token))
        },
        loadMyBookClub: (token, isRefreshing) => {
            dispatch(actionsMyBookClubs.loadMyBookClub(token, isRefreshing))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

import axios from 'axios';
import _ from "lodash";
import {API_READERS_V1} from "../../constants/API";
import {
    LOAD_RECOMMEND_LIST_FAILURE,
    LOAD_RECOMMEND_LIST_REQUEST,
    LOAD_RECOMMEND_LIST_SUCCESS
} from "./actionTypes";

export const loadRecommendList = () => {

    return dispatch => {

        dispatch(loadRecommendListRequest());

        axios
            .get(API_READERS_V1 + '/recommend/list')
            .then(response => {
                const recommendsAll = response.data;
                /*const bookClubListRecruiting = _.filter(bookClubListAll, {status: 'recruiting'});
                const bookClubListIng = _.filter(bookClubListAll, {status: 'ing'});
                const bookClubListEnded = _.filter(bookClubListAll, {status: 'ended'});*/

                dispatch(loadRecommendListSuccess(recommendsAll))
                // console.log('[COMPLETE: REDUX] LoadRecommendList', recommendsAll);
                console.log('[COMPLETE: REDUX] LoadRecommendList');
            })
            .catch(e => {
                console.log('[FAILURE: REDUX] LoadRecommendList', e);
                dispatch(loadRecommendListFailure())
            })
    }
};



export const loadRecommendListRequest = () => {
    return {
        type: LOAD_RECOMMEND_LIST_REQUEST
    }
}

export const loadRecommendListSuccess = (recommendsAll) => {
    return {
        type: LOAD_RECOMMEND_LIST_SUCCESS,
        recommendsAll
    }
};

export const loadRecommendListFailure = () => {
    return {
        type: LOAD_RECOMMEND_LIST_FAILURE
    }
}


import React, {Component} from 'react'
import {connect} from 'react-redux'
import {API_READERS_V1} from "../../constants/API";
import axios from 'axios'

class MyBookshelfAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bookshelfList: [],
            isLoadedBookshelfList: false,

            bookList: [],
            isLoadedBookList: false,
        }
    }


    componentDidMount() {
        this.loadBookshelfList();
        this.loadBookList();
    }

    loadBookshelfList = () => {
        axios
            .get(API_READERS_V1 + '/bookshelf/my/list',
                {
                    headers: {jwt: this.props.token}
                })
            .then(response => {
                const bookshelfList = response.data;
                this.setState({
                    isLoadedBookshelfList: true,
                    bookshelfList,
                })
            })
            .catch(error => {

            })
    };

    loadBookList = () => {
        axios
            .get(API_READERS_V1 + '/bookshelf/book/my/list',
                {
                    headers: {jwt: this.props.token}
                })
            .then(response => {
                const bookList = response.data;
                this.setState({
                    isLoadedBookList: true,
                    bookList,
                })
            })
            .catch(error => {

            })
    }


    render() {
        return (
            <div>
                {JSON.stringify(this.state.bookshelfList)}
                <div>
                    {JSON.stringify(this.state.bookList)}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    const {token} = state.auth;
    return {
        token
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MyBookshelfAll)

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button, Container, Segment} from 'semantic-ui-react';
import * as authActions from '../../redux/actions/auth';


class MyInfo extends Component {
    logout = () => {
        this.props.logout();
    };

    render() {
        return (
            <div>
                <Segment as={Container} vertical basic>
                    <Button onClick={this.logout}>logout</Button>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(authActions.logout());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyInfo)

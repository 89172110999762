import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {API_READERS_V1} from "../../constants/API";
import {Tab, Container, Segment, Item, Image, Icon, Card} from 'semantic-ui-react'
import {removeHtmlTag, removeHtmlTagSubString} from "../../middleware/TextHandler";
import moment from 'moment'
import {Link} from 'react-router-dom'
import AvatarComponent from '../../components/user/AvatarComponent'
import EssayItemComponent from '../../components/bookclub/EssayItemComponent'

class MyBookClubDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookClub: {},
            isLoadedBookClub: false,
            failed: false,

            participants: [],
            isLoadedParticipants: false,


            essayList: [],
            isLoadedEssayList: false,
        }
    }

    componentDidMount() {
        this.loadBookClubInfo();
        this.loadBooClubParticipants();
        this.loadEssayList();
    }

    loadBookClubInfo = async () => {
        const bookClubId = this.props.match.params.id;

        axios
            .get(API_READERS_V1 + '/bookclub/one', {
                params: {
                    bookClubId
                }
            })
            .then(response => {
                console.log('[BookClub] 북클럽 정보를 가져왔습니다.')
                // console.log(response.data);
                const bookClub = response.data[0];
                this.setState({
                    bookClub,
                    isLoadedBookClub: true,
                    failed: false,
                })
            })
            .catch(e => {
                console.log(e);
            })
    };
    loadBooClubParticipants = () => {
        const bookClubId = this.props.match.params.id;

        axios
            .get(API_READERS_V1 + '/bookclub/one/participants', {
                params: {
                    bookClubId
                }
            })
            .then(response => {
                console.log(response.data);
                console.log('[BookClub] 북클럽 참여자를 가져왔습니다.')
                const participants = response.data;

                const userIndex = participants.findIndex(user => user.participantId === this.props.userId);

                if (userIndex < 0) {
                    window.alert('멤버가 아닙니다.')
                    this.props.history.push({pathname: '/'})
                }

                this.setState({
                    participants,
                    isLoadedParticipants: true,
                })
            })
            .catch(e => {
                console.log(e);
            })
    };

    loadEssayList = () => {
        const bookClubId = this.props.match.params.id;
        axios
            .get(API_READERS_V1 + '/bookclub/one/essays/list', {
                params: {
                    bookClubId
                }
            })
            .then(response => {
                console.log('[BookClub] 에세이 정보를 가져왔습니다.')
                // console.log(response.data);
                const essayList = response.data;
                this.setState({
                    essayList,
                    isLoadedEssayList: true,
                })
            })
            .catch(e => {
                console.log(e);
            })
    };



    render() {
        const panes = [
            {
                menuItem: '에세이', render: () => {
                    return (
                        <Tab.Pane>

                            {
                                this.state.essayList.map((book, i) => {
                                    return (
                                        <EssayItemComponent book={book} key={i}/>
                                    );
                                })
                            }
                        </Tab.Pane>
                    )
                }
            },
            {
                menuItem: '멤버', render: () => {
                    return (
                        <Tab.Pane>
                            {
                                this.state.participants.map((member, i) => {
                                    return (
                                        <div key={i}>
                                            <AvatarComponent
                                                size={50}
                                                bucket={member.userInfo.profileImageFirebase ? member.userInfo.profileImageFirebase.bucket : null}
                                                fileName={member.userInfo.profileImageFirebase ? member.userInfo.profileImageFirebase.fileName : null}
                                                uri={member.userInfo.profileImage}/>
                                            {member.userInfo.local.name}
                                        </div>
                                    )
                                })
                            }
                        </Tab.Pane>
                    )
                }
            },
            {
                menuItem: '정보', render: () => {
                    return (
                        <Tab.Pane>

                        </Tab.Pane>
                    )
                }
            }
        ]
        return (
            <div>
                <Container as={Segment} vertical>
                    <Tab panes={panes}/>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {userId} = state.auth;
    return {userId};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MyBookClubDetailContainer);

import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import {Link} from 'react-router-dom';
import {API_READERS_V1} from "../../constants/API";
import {
    Container,
    Header,
    Button,
    Segment,
    Loader,
    Card,
    Image,
    Item,
    List,
    Label,
    Icon,
    Grid,
    Divider,
    Statistic,
    Message,
    Responsive,
} from 'semantic-ui-react';
import Colors from "../../config/Colors";
import {deleteTag, withLineEnter} from "../../middleware/stringModify";
import _ from 'lodash';
import PartnerApplyModuleComponent from '../../components/bookClubDetail-partner/PartnerApplyModuleComponent'
import PartnerResponsiveApplyModuleComponent from '../../components/bookClubDetail-partner/PartnerResponsiveApplyModuleComponent'
import ParticipantListComponent from '../../components/bookClubDetail-public/ParticipantListComponent'
import PublicEssayModuleComponent from '../../components/bookClubDetail-public/PublicEssayModuleComponent'
import AuthenticationWayComponent from '../../components/bookClubDetail-public/AuthenticationWayComponent'
import HostIntroComponent from '../../components/bookClubDetail-public/HostIntroComponent'
import ReadingListComponent from '../../components/bookClubDetail-public/ReadingListComponent'
import OfflineMeetingModuleComponent from '../../components/bookClubDetail-public/OfflineMeetingModuleComponent'
import GroundRuleComponent from '../../components/bookClubDetail-public/GroundRuleComponent'
import ScheduleComponent from '../../components/bookClubDetail-public/ScheduleComponent'
import Analytics from "../../middleware/analytics";

class BookClubDetailContainer extends Component {

    state = {
        isLoaded: false,

        bookClub: {},
        bookGroup: [],
        isLoadedBookClub: false,
        failed: false,

        participants: [],
        isLoadedParticipants: false,

        publicEssays: [],
        isLoadedPublicEssays: false,


        showAllPublicEssay: false,


        amIApplyThisBookClub: false,
        applyStatus: ''
    }


    componentDidMount() {
        this.loadBookClubInfo()
        this.loadBooClubParticipants();
        this.loadBookClubEssay();

        Analytics.pageViewAll(`/partner/bookclub/${this.props.match.params.bookClubId}`);

        window.scrollTo(0, 0)
    }


    loadBookClubInfo = async () => {
        const {bookClubId} = this.props.match.params

        axios
            .get(API_READERS_V1 + '/bookclub/one', {
                params: {
                    bookClubId
                }
            })
            .then(response => {
                const bookClub = response.data[0];

                if (!bookClub.partner) {
                    window.alert('정상적인 접근이 아닙니다')
                    return this.props.history.goBack();
                }

                this.setState({
                    bookClub,
                    isLoadedBookClub: true,
                    failed: false,
                })
                this.checkAmIApplyThis();
            })
            .catch(e => {
                console.log(e);
            })
    };
    loadBooClubParticipants = () => {
        const {bookClubId} = this.props.match.params

        axios
            .get(API_READERS_V1 + '/bookclub/one/participants', {
                params: {
                    bookClubId
                }
            })
            .then(response => {
                // console.log(response.data);
                const participants = response.data;
                this.setState({
                    participants,
                    isLoadedParticipants: true,
                })
            })
            .catch(e => {
                console.log(e);
            })
    };
    loadBookClubEssay = () => {
        const {bookClubId} = this.props.match.params

        axios
            .get(API_READERS_V1 + '/bookclub/one/essays/public', {
                params: {
                    bookClubId
                }
            })
            .then(response => {
                // console.log(response.data);
                const publicEssays = response.data;
                this.setState({
                    publicEssays,
                    isLoadedPublicEssays: true,
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    checkAmIApplyThis = () => {
        const {applyListAll} = this.props;
        const {bookClub} = this.state;
        // console.log(applyListAll);

        if (!bookClub._id) {
            return;
        }

        const amIApplyThisBookClubIndex = applyListAll.findIndex(apply => apply.bookclubId.toString() === bookClub._id.toString())
        if (amIApplyThisBookClubIndex > -1) {
            this.setState({
                amIApplyThisBookClub: true,
                applyStatus: applyListAll[amIApplyThisBookClubIndex].status
            })
        }
    }


    render() {
        const {isLoadedBookClub} = this.state;
        if (!isLoadedBookClub) {
            return (
                <div style={{minHeight: '80vh'}}>
                    <Loader active/>
                </div>
            )
        }

        const {
            title,
            subTitle,
            mainImage,

            introduction,
            authenticationWay,
            groundRule,

        } = this.state.bookClub;
        const {
            publicEssays,
            participants,
            bookClub,
        } = this.state;



        return (
            <div style={{marginBottom: 80}}>
                {/* 상단 설명 */}
                <Segment as={Container} basic vertical>
                    <Responsive maxWidth={764}>
                        <Grid stackable>
                            <Grid.Row columns={2} stretched>
                                <Grid.Column width={10}>
                                    <Image src={mainImage} style={{border: `1px solid lightgrey`}}/>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <Header size={'large'}>
                                        {title}
                                        <Header.Subheader>
                                            {subTitle}
                                        </Header.Subheader>
                                    </Header>
                                    <div>
                                        시작: {moment(bookClub.startDate).format('YYYY. MM. DD')} <br/>
                                        종료: {moment(bookClub.endDate).format('YYYY. MM. DD')}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Responsive>
                    <Responsive minWidth={765}>
                        <Grid stackable>
                            <Grid.Row columns={2} stretched>
                                <Grid.Column width={10}
                                             style={{
                                                 backgroundColor: 'lightgrey',
                                                 backgroundImage: `url(${mainImage})`,
                                                 backgroundPosition: 'center',
                                                 backgroundSize: 'cover',
                                                 minHeight: '400px',
                                                 border: `1px solid lightgrey`
                                             }}>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <Header size={'large'}>
                                        {title}
                                        <Header.Subheader>
                                            {subTitle}
                                        </Header.Subheader>
                                    </Header>
                                    <div>
                                        시작: {moment(bookClub.startDate).format('YYYY. MM. DD')} <br/>
                                        종료: {moment(bookClub.endDate).format('YYYY. MM. DD')}
                                    </div>
                                    <PartnerApplyModuleComponent bookClub={bookClub}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Responsive>
                </Segment>

                {/* 북클럽 소개 */}
                <div style={styles.module}>
                    <Segment as={Container} vertical text basic textAlign={'center'}>
                        <Header style={{}} size={'large'}>
                            북클럽 소개
                            <Header.Subheader style={styles.subHeader}>
                                북클럽을 소개합니다.
                            </Header.Subheader>
                        </Header>
                    </Segment>
                    {/* 기본 수치 */}
                    <div style={{marginTop: 0}}>
                        <Segment as={Container} vertical text basic textAlign={'center'}>
                            <Statistic.Group widths={'five'} size={'tiny'}>
                                <Statistic>
                                    <Statistic.Label>속도</Statistic.Label>
                                    <Statistic.Value>{(bookClub.numOfBooks / bookClub.numberOfWeeks).toFixed(1)}</Statistic.Value>
                                    <Statistic.Label>권/1주</Statistic.Label>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Label>기간</Statistic.Label>
                                    <Statistic.Value>{bookClub.numberOfWeeks}</Statistic.Value>
                                    <Statistic.Label>주</Statistic.Label>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Label>읽는 책</Statistic.Label>
                                    <Statistic.Value>{bookClub.numOfBooks}</Statistic.Value>
                                    <Statistic.Label>권</Statistic.Label>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Label>에세이</Statistic.Label>
                                    <Statistic.Value>{bookClub.numOfEssays}</Statistic.Value>
                                    <Statistic.Label>편</Statistic.Label>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Label>클럽장</Statistic.Label>
                                    <Statistic.Value>{bookClub.hasHost ? 'O' : 'X'}</Statistic.Value>
                                </Statistic>
                            </Statistic.Group>
                        </Segment>
                    </div>

                    <Segment as={Container} text vertical>
                        <p style={{lineHeight: '2rem'}}>
                            {introduction && withLineEnter(introduction)}
                        </p>
                    </Segment>
                </div>


                {/* 읽는 책 */}
                <ReadingListComponent bookClub={bookClub}/>

                {
                    bookClub.introModule.map((module, i) => {
                        return (
                            <div style={{}} key={i}>
                                <Segment as={Container} vertical text basic textAlign={'center'}>
                                    <Header style={module.header ? styles.module : {}} size={'large'}>
                                        {module.header}
                                        <Header.Subheader style={styles.subHeader}>
                                            {module.subHeader}
                                        </Header.Subheader>
                                    </Header>
                                </Segment>
                                <Segment as={Container} text vertical>
                                    {
                                        module.image && (
                                            <Image src={module.image} style={{marginBottom: 10}}/>
                                        )
                                    }
                                    {
                                        module.movieUrl && (
                                            <div style={{textAlign: 'center', marginBottom: 10}}>
                                                <Responsive maxWidth={764}>
                                                    <iframe width="343" height="192" src={module.movieUrl}
                                                            frameBorder="0"
                                                            allow="autoplay; encrypted-media"/>
                                                </Responsive>
                                                <Responsive minWidth={765}>
                                                    <iframe width="560" height="315" src={module.movieUrl}
                                                            frameBorder="0"
                                                            allow="autoplay; encrypted-media"/>
                                                </Responsive>
                                            </div>
                                        )
                                    }
                                    {
                                        module.content && (
                                            <p style={{lineHeight: '2rem'}}>
                                                {withLineEnter(module.content)}
                                            </p>
                                        )
                                    }

                                </Segment>
                            </div>
                        )
                    })
                }



                {/* 독서 인증 방법 */}
                <AuthenticationWayComponent bookClub={bookClub}/>


                {/* 에세이 스케줄 */}
                <ScheduleComponent bookClub={bookClub}/>

                {/* 공개 에세이 */}
                <PublicEssayModuleComponent publicEssays={publicEssays} bookClub={bookClub}/>

                {/* 오프라인 모임 */}
                <OfflineMeetingModuleComponent bookClub={bookClub}/>

                {/* 호스트 소개 */}
                <HostIntroComponent bookClub={bookClub}/>

                {/* 함께 읽는 사람들 */}
                <ParticipantListComponent participants={participants} bookClub={bookClub}/>


                {/* 그라운드 룰 */}
                <GroundRuleComponent bookClub={bookClub}/>

                {/* 신청하기 */}
                <Responsive minWidth={765}>
                    <div style={styles.module}>
                        <Segment as={Container} text vertical>
                            <PartnerApplyModuleComponent bookClub={bookClub}/>
                        </Segment>
                    </div>
                </Responsive>
                <PartnerResponsiveApplyModuleComponent bookClub={bookClub}/>
            </div>
        );
    }
}

const
    mapStateToProps = (state) => {
        const {isLoggedIn} = state.auth;
        const {
            applyListAll
        } = state.applyList;
        return {
            isLoggedIn,
            applyListAll
        };
    }
const
    mapDispatchToProps = (dispatch) => {
        return {};
    }
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)

(
    BookClubDetailContainer
)
;

const
    styles = {
        subHeader: {
            marginTop: 5
        },
        module: {
            marginTop: 120
        }
    }

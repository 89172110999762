import React, {Component} from 'react';
import {connect} from 'react-redux';
import {removeHtmlTag, removeHtmlTagSubString} from "../../middleware/TextHandler";
import {Link, withRouter} from "react-router-dom";
import moment from "moment/moment";
import {Segment, Button, Item, Card, Icon} from 'semantic-ui-react'

class EssayItemComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            doISubmit: false,
        }
    };


    componentWillMount() {
        this.checkSubmit(this.props)
    };

    checkSubmit = (props) => {
        const {book} = props;
        const {essays} = book;
        const doISubmitIndex = essays.findIndex(essay => essay.owner_id === props.userId);
        let doISubmit = false;
        if (doISubmitIndex > -1) {
            doISubmit = true
        }
        this.setState({
            doISubmit
        })
    };


    render() {
        const {
            book
        } = this.props;


        return (
            <Segment vertical>
                <Item.Group>
                    <Item>
                        <Item.Image src={book.image} size={'tiny'}/>

                        <Item.Content>
                            <Item.Header>
                                {removeHtmlTag(book.title)}
                            </Item.Header>
                            <Item.Meta>
                                {book.author}
                            </Item.Meta>
                            <Item.Extra>
                                {
                                    moment(moment.utc(book.readStartDate).format('YYYY-MM-DD'), 'YYYY-MM-DD').format('YYYY.MM.DD ddd').toUpperCase()
                                }
                            </Item.Extra>
                            <Item.Extra>
                                {
                                    moment(moment.utc(book.readEndDate).format('YYYY-MM-DD'), 'YYYY-MM-DD').format('YYYY.MM.DD ddd').toUpperCase()
                                }
                            </Item.Extra>
                            {
                                !this.state.doISubmit && (
                                    <Item.Extra>
                                        <Button floated={'right'}
                                                as={Link}
                                                to={{
                                                    pathname: `/mybookclub/essay/write`,
                                                    state: {
                                                        book
                                                    }
                                                }}>
                                            에세이 작성하기
                                        </Button>
                                    </Item.Extra>
                                )
                            }
                        </Item.Content>
                    </Item>
                </Item.Group>

                <Card.Group itemsPerRow={3} stackable>
                    {
                        book.essays.map((essay, j) => {

                            if (this.state.doISubmit) {
                                return (
                                    <Card key={j}
                                          style={{borderRadius: 0}}
                                          raised
                                          as={Link}
                                          // target='_blank'
                                          to={{
                                              pathname: `/mybookclub/essay/read/${essay._id}`
                                          }}>
                                        <Card.Content>
                                            <Card.Header>
                                                {essay.title.length > 15 ? essay.title.slice(0, 15) + '..' : essay.title}
                                            </Card.Header>
                                            <Card.Description>
                                                {essay.contentPlain ? removeHtmlTagSubString(essay.contentPlain, 80) : removeHtmlTagSubString(essay.content, 80)} ...
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                            {essay.owner_name}
                                            <p>
                                                {moment(essay.created).format('MM.DD ddd HH:mm')}
                                            </p>
                                            <p>
                                                <Icon
                                                    name={'comments outline'}/>{essay.comments.length}
                                            </p>
                                        </Card.Content>
                                    </Card>
                                )
                            } else {
                                return (
                                    <Card key={j}
                                          style={{borderRadius: 0}}
                                          raised
                                          onClick={() => {
                                              window.alert('에세이를 작성한 후에, 다른 사람의 에세이를 볼 수 있습니다.')
                                          }}>
                                        <Card.Content>
                                            <Card.Header>
                                                {essay.title.length > 15 ? essay.title.slice(0, 15) + '..' : essay.title}
                                            </Card.Header>
                                            <Card.Description>
                                                {essay.contentPlain ? removeHtmlTagSubString(essay.contentPlain, 80) : removeHtmlTagSubString(essay.content, 80)} ...
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                            {essay.owner_name}
                                            <p>
                                                {moment(essay.created).format('MM.DD ddd HH:mm')}
                                            </p>
                                            <p>
                                                <Icon
                                                    name={'comments outline'}/>{essay.comments.length}
                                            </p>
                                        </Card.Content>
                                    </Card>
                                )
                            }
                        })
                    }
                </Card.Group>
            </Segment>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        userId
    } = state.auth
    return {userId};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(EssayItemComponent));

import {combineReducers} from 'redux';
import auth from './auth';
import bookClubPublic from './bookClubPublic'
import bookClubPartner from './bookClubPartner'
import myBookClubs from './myBookClubs'
import recommend from './recommend'
import applyList from './applyList'

const reducers = combineReducers({
    auth,
    bookClubPublic,
    bookClubPartner,
    myBookClubs,
    recommend,
    applyList,
});

export default reducers;

import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {API_READERS_V1} from "../../constants/API";
import {Loader, Container, Icon, Comment, Header, Segment, Form, Button} from 'semantic-ui-react'
import {withLineEnter} from "../../middleware/stringModify";
import AvatarComponent from '../../components/user/AvatarComponent'
import moment from 'moment'
import {Link} from 'react-router-dom'

class AdminEssayDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            essay: {},
            isLoadedEssay: false,
            failedEssay: false,

            comments: [],
            isLoadedComments: false,
            failedComments: false,

            uploadingComment: false,
            thisComment: ''
        }
    }

    componentDidMount() {
        this._loadEssay();
        this._loadComments()
    }

    handleInputChange = (e, {value, name}) => {
        this.setState({[name]: value});
    };

    _loadEssay = async () => {
        console.log(this.props.match.params.essayId);
        const {essayId} = this.props.match.params;
        const token = await localStorage.getItem('token');

        axios
            .get(API_READERS_V1 + `/essay/${essayId}`, {
                headers: {jwt: token}
            })
            .then(response => {
                const essay = response.data;

                console.log(essay);

                if (essay.isDeleted) {
                    window.alert('에세이 정보를 찾을 수 없습니다.');
                    this.props.history.goBack();
                }
                this.setState({
                    essay,
                    isLoadedEssay: true,
                    failedEssay: false,
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    failedEssay: true
                })
                window.alert('에세이 정보를 찾을 수 없습니다.');
                this.props.history.goBack();

            })
    };

    _loadComments = async () => {
        const {essayId} = this.props.match.params;
        const token = await localStorage.getItem('token');
        axios
            .get(API_READERS_V1 + `/essay/comment/essay/${essayId}`, {
                headers: {jwt: token}
            })
            .then(response => {
                console.log('[COMPLETE] COMMENT LOADED');
                const comments = response.data;
                this.setState({
                    comments,
                    isLoadedComments: true,
                    failedComments: false,
                })
            })
            .catch(error => {
                console.log('[ERROR] COMMENT NOT LOADED');
                this.setState({
                    failedComments: true
                })
            })
    };

    addComment = async (e) => {
        e.preventDefault();
        if (this.state.thisComment === '' || this.state.thisComment.length < 1) {
            return window.alert('댓글을 작성해주세요')
        }
        this.setState({
            uploadingComment: true
        })
        const {essayId} = this.props.match.params;
        const token = await localStorage.getItem('token');

        const commentData = {
            essayId,
            writer_name: this.props.userInfo.userName,
            writer_id: this.props.userId,
            content: this.state.thisComment,
            writerImage: this.props.userInfo.profileImage,
        };

        axios
            .post(API_READERS_V1 + '/essay/comment', commentData, {
                headers: {jwt: token}
            })
            .then(response => {
                console.log('[COMPLETE] COMMENT ADDED', response.data);
                this._loadComments();
                this.setState(prev => ({
                    uploadingComment: false,
                    thisComment: '',
                }))
            })
            .catch(error => {
                console.log(error);
                window.alert('댓글을 작성하는데 실패하였습니다. 재시도 해주세요')
            })

    };

    _removeComment = (commentId) => {
        const a = window.confirm('댓글을 삭제하시겠습니까?')
        if (!a) {
            return;
        }

        this._confirmDeleteComment(commentId)

    };

    _confirmDeleteComment = async commentId => {
        const {essayId} = this.props.match.params;
        const token = await localStorage.getItem('token');
        axios
            .delete(API_READERS_V1 + '/essay/comment', {
                headers: {jwt: token},
                params: {
                    commentId,
                    essayId,
                }
            })
            .then(response => {
                console.log(response.data);
                this._loadComments();
                this._loadEssay();
            })
            .catch(error => {
                console.log(error);
                window.alert('댓글을 삭제하는데 실패하였습니다. 재시도해주세요')
            })
    }

    _checkAmISumitEssayIfThiEssayPrivate = () => {


    };

    render() {
        const {
            isLoadedEssay,
            failedEssay,
            essay,
            comments,
        } = this.state;
        if (!isLoadedEssay) {
            return (
                <div>
                    <Loader active/>
                </div>
            )
        }
        return (
            <Fragment>
                <Container text as={Segment} vertical>
                    <Header>
                        {essay.title}
                    </Header>
                    <p>
                        <AvatarComponent
                            bucket={essay.userInfo.profileImageFirebase ? essay.userInfo.profileImageFirebase.bucket : null}
                            fileName={essay.userInfo.profileImageFirebase ? essay.userInfo.profileImageFirebase.fileName : null}
                            uri={essay.userInfo.profileImage}/>

                        {essay.userInfo.local.name}{'\t'}
                        {moment(essay.created).format('YYYY. MM. DD HH:mm')}
                    </p>
                    <p>
                        <Icon name="comment outline"/> {essay.comments.length}
                    </p>
                    {
                        this.props.userId === essay.owner_id ? (
                            <Button basic as={Link} to={{
                                pathname: `/mybookclub/essay/modify/${essay._id}`,
                                state: {
                                    from: this.props.location,
                                }
                            }}>
                                수정하기
                            </Button>
                        ) : (
                            <Fragment>
                                {/*<Button negative basic as={Link} to={{
                                pathname: `/mybookclub/essay/modify/${essay._id}`,
                                state: {
                                    from: this.props.location,
                                }
                            }}>
                                수정하기
                            </Button>*/}
                            </Fragment>
                        )
                    }

                </Container>
                <Container text as={Segment} vertical style={{}}>
                    {
                        this.state.essay.contentPlain ? (
                            <span style={{fontSize: '1.2rem', lineHeight: '2rem'}}>
                                    {withLineEnter(this.state.essay.contentPlain)}
                            </span>
                        ) : (
                            <div className={'essayMainBody'}
                                 dangerouslySetInnerHTML={{__html: this.state.essay.content}}/>
                        )
                    }

                </Container>
                <Container text as={Segment} vertical>
                    <Header>
                        댓글
                    </Header>
                    <Comment.Group>
                        {
                            comments.map((comment, i) => {
                                return (
                                    <Comment key={i}>
                                        <Comment.Avatar src={comment.userInfo.profileImage}/>
                                        <Comment.Content>
                                            <Comment.Author as={'a'}>
                                                {comment.writer_name}
                                            </Comment.Author>
                                            <Comment.Metadata>
                                                {moment(comment.created).format('YYYY.MM.DD HH:mm')}
                                            </Comment.Metadata>
                                            <Comment.Text>
                                                <div dangerouslySetInnerHTML={{__html: comment.content}}/>
                                            </Comment.Text>

                                            {
                                                this.props.userId === comment.writer_id ? (
                                                    <Comment.Actions onClick={() => {
                                                        this._removeComment(comment._id)
                                                    }}>
                                                        <Comment.Action>[삭제하기]</Comment.Action>
                                                    </Comment.Actions>
                                                ) : (
                                                    null
                                                )
                                            }
                                        </Comment.Content>
                                    </Comment>
                                )
                            })
                        }
                        <Form reply>
                            <Form.TextArea autoHeight
                                           placeholder="댓글을 달아보세요"
                                           rows={10}
                                           name="thisComment"
                                           value={this.state.thisComment}
                                           onChange={this.handleInputChange}/>
                            <Button content={'댓글 달기'}
                                    active={this.state.uploadingComment}
                                    disabled={this.state.uploadingComment}
                                    labelPosition={'left'}
                                    icon={'edit'}
                                    onClick={e => this.addComment(e)}
                                    primary/>
                        </Form>
                    </Comment.Group>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {userId, userInfo} = state.auth
    return {userId, userInfo};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminEssayDetailContainer);

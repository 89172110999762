import React, {Component} from 'react';
import {Segment, Container, Form, Message} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {firebase, firebaseApp} from "../../config/firebase";
import axios from "axios/index";
import {API} from "../../constants/API";

class SignUpContainer extends Component {
    state = {
        email: '',
        password: '',
        passwordConfirm: '',
        name: '',
        phoneNumber: '',

        message: []
    };

    handleChange = (e, {value, name}) => {
        this.setState({
            [name]: value
        });
    };

    handleSubmit = e => {
        this.setState({message: ''});

        let message = [];

        e.preventDefault();
        console.log(this.state);
        if (!this.ValidateEmail(this.state.email)) {
            message.push('email 형식이 잘못되었습니다');
        }
        if (this.state.password.length < 6) {
            message.push('패스워드는 6자 이상으로 작성해주세요');
        }
        if (this.state.password !== this.state.passwordConfirm) {
            message.push('패스워드가 일치하지 않습니다');
        }
        if (this.state.name.length < 1) {
            message.push('이름을 올바르게 입력해주세요');
        }
        if (this.state.phoneNumber.length === null) {
            message.push('전화번호를 입력하세요');
        }
        if (message.length > 0) {
            console.log(message);
            this.setState({
                message: message
            });
            return;
        }

        const {email, password, passwordConfirm, name, phoneNumber} = this.state;

        const data = {
            name,
            email,
            password,
            phoneNumber
        };
        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(result => {
                const user = firebase.auth().currentUser;
                user.updateProfile({
                    displayName : name,
                    testParams : 'text'
                })
            })
            .catch(error => {
                console.log(error);
            })
    };

    ValidateEmail = mail => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    };

    handleLogin = () => {

    }


    render() {
        const {email, password, passwordConfirm, name, phoneNumber} = this.state;
        return (
            <div>
                <Segment as={Container} text>
                    <Form>
                        <Form.Input label="e-mail" type="email" name="email" value={email}
                                    onChange={this.handleChange}/>
                        <Form.Input label="password" type="password" name="password" value={password}
                                    onChange={this.handleChange}/>
                        <Form.Input label="password 확인" type="password" name="passwordConfirm" value={passwordConfirm}
                                    onChange={this.handleChange}/>
                        <Form.Input label="이름" name="name" value={name} onChange={this.handleChange}/>
                        <Form.Input label="핸드폰 번호" name="phoneNumber" value={phoneNumber} type="number"
                                    onChange={this.handleChange}/>
                        <Form.Button onClick={this.handleSubmit}>가입하기</Form.Button>
                    </Form>
                    {this.state.message.length > 0 && (
                        <Message warning size="small">
                            <Message.List>
                                {this.state.message.map((message, i) => {
                                    return <Message.Item key={i}>{message}</Message.Item>;
                                })}
                            </Message.List>
                        </Message>
                    )}
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignUpContainer);

import * as types from '../actions/actionTypes';
import update from 'react-addons-update';
import _ from "lodash";

const initialState = {
    recommendsAll: [],
    isLoaded: false,
    failed: false,

    status: 'INIT'
};

const recommend = (state = initialState, action) => {

    switch (action.type) {
        case types.LOAD_RECOMMEND_LIST_REQUEST:
            return update(state, {
                status: {$set: 'REQUEST'},
                failed: {$set: false},
            });
        case types.LOAD_RECOMMEND_LIST_SUCCESS:
            return update(state, {
                status: {$set: 'SUCCESS'},
                isLoaded: {$set: true},
                failed: {$set: false},

                recommendsAll: {$set: action.recommendsAll},
            })
        case types.LOAD_RECOMMEND_LIST_FAILURE:
            return update(state, {
                status: {$set: "FAILURE"},
                failed: {$set: true}
            })
        default:
            return state

    }


}

export default recommend


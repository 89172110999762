import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

//REDUX 관련
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {Provider} from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import reducer from './redux/reducers';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

const store = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(thunkMiddleware) //createLogger
        // other store enhancers if any
    )
);

let rootElement = document.getElementById('root');

unregister()
ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    rootElement
);
// registerServiceWorker();

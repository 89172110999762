import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {API_READERS_V1} from "../../constants/API";
import {Table, Container, Loader, Button, Segment, Image} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import moment from 'moment';

class AdminRecommendMainContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recommends: [],
            isLoaded: false,
            failed: false,
        }
    }


    componentDidMount() {
        this._loadRecommendList()
    }

    _loadRecommendList = async () => {
        const token = localStorage.getItem('token');
        axios
            .get(API_READERS_V1 + '/recommend/admin/list', {
                headers: {jwt: token}
            })
            .then(response => {
                const recommends = response.data;
                this.setState({
                    recommends,
                    isLoaded: true,
                    failed: false,
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    failed: true,
                })
            })
    }


    render() {
        if (!this.state.isLoaded) {
            return (
                <Loader active/>
            )
        }
        return (
            <Fragment>
                <Container as={Segment} vertical>
                    <Button as={Link} to={'/admin/recommend/make'}>
                        리스트 만들기
                    </Button>
                </Container>
                <Container fluid as={Segment} vertical>
                    <Table size={'small'}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    사진
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    번호
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    제목
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    날자
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    추천 책 수
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    공개여부
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    버튼
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.recommends.map((recommend, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>
                                                <Image src={recommend.mainImage} size={'small'}/>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {i + 1}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {recommend.title}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {moment(recommend.selectedDay).format('YYYY-MM-DD')}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {recommend.bookList.length}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {
                                                    recommend.isSecret ? (
                                                        <span style={{color: 'red'}}>
                                                        비공개
                                                        </span>
                                                    ) : (
                                                        <span>
                                                        공개
                                                        </span>
                                                    )
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button as={Link}
                                                        to={{pathname: `/admin/recommend/read/${recommend._id}`}}
                                                        size={'small'}>
                                                    자세히 보기
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminRecommendMainContainer);

import React, {Component} from 'react';
import {Card, Image, Icon, Label, } from 'semantic-ui-react'
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import moment from "moment/moment";

class MyBookClubCardComponent extends Component {

    render() {
        const {bookclub} = this.props;

        if (!bookclub._id) {
            return (
                <Card>
                    <Image src={'/images/mockup/image.png'}/>
                    <Card.Content>
                        <Card.Header>
                            {bookclub.title}
                        </Card.Header>
                        <Card.Meta>
                            {bookclub.sub_title}
                        </Card.Meta>
                    </Card.Content>
                    <Card.Content>
                        <Icon name={'user'}/>
                    </Card.Content>
                </Card>
            )
        }

        return (
            <Card as={Link} to={`/mybookclub/${bookclub._id}`} style={{borderRadius: 0}}>
                <Image src={bookclub.mainImage ? bookclub.mainImage : '/images/mockup/image.png'}
                       style={{borderRadius: 0}}/>
                <Card.Content>
                    <Card.Header>
                        {bookclub.title} <br/>
                        <span style={{fontWeight: '400'}}>
                            {moment(bookclub.day_start).format('YYYY. MM. DD')} - {moment(bookclub.day_end).format('YYYY. MM. DD')}
                        </span>
                    </Card.Header>
                    <Card.Meta>
                        <br/>
                        <Icon name={'user'}/>
                        {bookclub.participants.length} 명 <br/>
                    </Card.Meta>
                </Card.Content>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MyBookClubCardComponent);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withLineEnter} from "../../middleware/stringModify";
import {Container, Segment, Header} from "semantic-ui-react";

class GroundRuleComponent extends Component {
    render() {
        const {bookClub} = this.props;
        if (!bookClub.groundRule) {
            return null;
        }
        return (
            <div style={{paddingTop: 60, paddingBottom: 60}}>
                <Segment as={Container} vertical text basic textAlign={'center'}>
                    <Header style={{}} size={'large'}>
                        그라운드 룰
                        <Header.Subheader style={{marginTop: 5}}>
                            북클럽 운영과 관련된 기타 규칙입니다.
                        </Header.Subheader>
                    </Header>
                </Segment>
                <Segment as={Container} text vertical>
                    <p style={{lineHeight: '2rem'}}>
                        {withLineEnter(bookClub.groundRule)}
                    </p>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GroundRuleComponent);

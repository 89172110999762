import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import numeral from "numeral";
import {Card, Divider, Button} from 'semantic-ui-react'
import Colors from "../../config/Colors";
import {readersBankAccount} from "../../config/informations";

class ApplyModuleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amIApplyThisBookClub: null,
            applyStatus: ''
        }
    }

    componentDidMount() {

    }

    componentWillMount() {
        this._checkAmIApply();
    }


    componentWillUpdate(nextProps, nextState) {

    }

    componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this._checkAmIApply()
        }
    }

    _checkAmIApply = () => {
        const {applyListAll, bookClub} = this.props;
        // console.log(applyListAll);

        if (!bookClub._id) {
            return;
        }

        const amIApplyThisBookClubIndex = applyListAll.findIndex(apply => apply.bookclubId.toString() === bookClub._id.toString())
        if (amIApplyThisBookClubIndex > -1) {
            this.setState({
                amIApplyThisBookClub: true,
                applyStatus: applyListAll[amIApplyThisBookClubIndex].status
            })
        } else {
            this.setState({
                amIApplyThisBookClub: false,
            })
        }
    };

    render() {
        const {bookClub} = this.props;
        const {amIApplyThisBookClub, applyStatus} = this.state;

        switch (amIApplyThisBookClub) {
            case true :
                return (
                    <Fragment>
                        {
                            applyStatus === 'approved' ? (
                                <Card fluid style={{borderRadius: 0}}>
                                    <Card.Content>
                                        <Button fluid color={'black'} to={{pathname: `/mybookclub/${bookClub._id}`}}
                                                as={Link}>
                                            멤버 페이지로 들어가기
                                        </Button>
                                    </Card.Content>
                                </Card>
                            ) : (
                                <Card fluid style={{borderRadius: 0}}>
                                    <Card.Content>
                                        신청이 완료되었습니다. <br/>
                                    </Card.Content>
                                    <Card.Content>
                                        <Button disabled fluid color={'black'}>
                                            승인 대기중
                                        </Button>
                                    </Card.Content>
                                </Card>
                            )
                        }
                    </Fragment>
                );
            case false :
                switch (bookClub.status) {
                    case 'beforeRecruiting' :
                        return (
                            <Card fluid style={{borderRadius: 0}}>
                                <Card.Content>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                        회비 : {numeral(bookClub.totalAmountMembershipFee).format('0,0')} 원
                                    </p>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                        - 디파짓
                                        : {numeral(bookClub.depositPerEssay * bookClub.numOfEssays).format('0,0')} 원
                                    </p>
                                    <Divider style={{marginLeft: '50%'}}/>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                        <span style={{fontSize: '0.8rem', color: Colors.inActiveGrey}}>
                                            에세이를 쓰기만 하면 결국 내는 금액은,
                                        </span> <br/>
                                        :{numeral(bookClub.defaultMembershipFee).format('0,0')} 원
                                    </p>
                                    <p>
                                        * 디파짓 {numeral(bookClub.depositPerEssay).format('0,0')} 원
                                        x {bookClub.numOfEssays}회(에세이
                                        작성 수) <br/>
                                        독서 인증을 할 때마다 디파짓을 환급해 드립니다.
                                    </p>
                                    <Button fluid color={'black'} disabled>
                                        곧 모집이 시작됩니다.
                                    </Button>
                                </Card.Content>
                            </Card>
                        );
                    case 'recruiting' :
                        return (
                            <Card fluid style={{borderRadius: 0}}>
                                <Card.Content>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                        회비 : {numeral(bookClub.totalAmountMembershipFee).format('0,0')} 원
                                    </p>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                       - 디파짓
                                        : {numeral(bookClub.depositPerEssay * bookClub.numOfEssays).format('0,0')} 원
                                    </p>
                                    <Divider style={{marginLeft: '50%'}}/>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                        <span style={{fontSize: '0.8rem', color: Colors.inActiveGrey}}>
                                            에세이를 쓰기만 하면 결국 내는 금액은,
                                        </span> <br/>
                                        :{numeral(bookClub.defaultMembershipFee).format('0,0')} 원
                                    </p>
                                    <p>
                                        * 디파짓 {numeral(bookClub.depositPerEssay).format('0,0')} 원
                                        x {bookClub.numOfEssays}회(에세이
                                        작성 수) <br/>
                                        독서 인증을 할 때마다 디파짓을 환급해 드립니다.
                                    </p>
                                    <Button fluid color={'black'} to={{pathname: `/bookclub/apply/${bookClub._id}`}}
                                            as={Link}>
                                        리더스 북클럽 참여하기
                                    </Button>
                                </Card.Content>
                            </Card>
                        );
                    case 'ing' :
                        return (
                            <Card fluid style={{borderRadius: 0}}>
                                <Card.Content>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                        회비 : {numeral(bookClub.totalAmountMembershipFee).format('0,0')} 원
                                    </p>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                       - 디파짓
                                        : {numeral(bookClub.depositPerEssay * bookClub.numOfEssays).format('0,0')} 원
                                    </p>
                                    <Divider style={{marginLeft: '50%'}}/>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                        <span style={{fontSize: '0.8rem', color: Colors.inActiveGrey}}>
                                            에세이를 쓰기만 하면 결국 내는 금액은,
                                        </span> <br/>
                                        :{numeral(bookClub.defaultMembershipFee).format('0,0')} 원
                                    </p>
                                    <p>
                                        * 디파짓 {numeral(bookClub.depositPerEssay).format('0,0')} 원
                                        x {bookClub.numOfEssays}회(에세이
                                        작성 수) <br/>
                                        독서 인증을 할 때마다 디파짓을 환급해 드립니다.
                                    </p>
                                    <Button fluid color={'grey'}>
                                        진행중입니다.
                                    </Button>
                                </Card.Content>
                            </Card>
                        );
                    case 'ended':
                        return (
                            <Card fluid style={{borderRadius: 0}}>
                                <Card.Content>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                        회비 : {numeral(bookClub.totalAmountMembershipFee).format('0,0')} 원
                                    </p>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                       - 디파짓
                                        : {numeral(bookClub.depositPerEssay * bookClub.numOfEssays).format('0,0')} 원
                                    </p>
                                    <Divider style={{marginLeft: '50%'}}/>
                                    <p style={{textAlign: 'right', fontSize: '1.2rem'}}>
                                        <span style={{fontSize: '0.8rem', color: Colors.inActiveGrey}}>
                                            에세이를 쓰기만 하면 결국 내는 금액은,
                                        </span> <br/>
                                        :{numeral(bookClub.defaultMembershipFee).format('0,0')} 원
                                    </p>
                                    <p>
                                        * 디파짓 {numeral(bookClub.depositPerEssay).format('0,0')} 원
                                        x {bookClub.numOfEssays}회(에세이
                                        작성 수) <br/>
                                        독서 인증을 할 때마다 디파짓을 환급해 드립니다.
                                    </p>
                                    <Button fluid color={'grey'}>
                                        종료된 북클럽입니다.
                                    </Button>
                                </Card.Content>
                            </Card>
                        );
                    default:
                        return null
                }
            default :
                return null
        }
    }
}

const mapStateToProps = (state) => {
    const {
        applyListAll
    } = state.applyList;
    return {
        applyListAll
    };
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ApplyModuleComponent);

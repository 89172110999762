import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Segment,
    Container,
    Button,
    Form,
    Header,
    Item,
    Grid,
    Radio,
    Input,
    Select,
    Table,
    Image,
    Card,
} from 'semantic-ui-react'

import moment from 'moment';
import {bookClubImageUploadToFirebase} from "../../middleware/firebaseImageHandler";
import {API_READERS_V1} from "../../constants/API";
import axios from 'axios';
import {removeHtmlTag} from "../../middleware/TextHandler";
import {moveItemInArrayFromIndexToIndex} from "../../middleware/arrayHandler";

class AdminBookClubMakeContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bookClub: {},

            title: '',
            subTitle: '',

            description: '',

            // Depreciated 될 예정
            // sub_title: moment().format('YYYY-MM-DD'),
            // day_start: moment().format('YYYY-MM-DD'),
            // day_end: moment().format('YYYY-MM-DD'),
            // day_deadline: moment().format('YYYY-MM-DD'),

            // fixed_number_of_people: 99,
            // requirement: '',

            // fee_default: 20000,
            // deposit_perbook: 30000,
            // numberOfBook: 4,
            // total_membership: 140000,

            // host_intro: '',
            // host_name: '',
            // host_email: '',
            // host: '',

            hasHost: false,
            hostUserId: '',
            hostIntroModule: [],
            staff: [],

            mainImage: '',
            mainImageFirebase: {},


            // 추가
            introduction: '',
            authenticationWay: '',
            groundRule: '',
            offlineMeeting: '',
            hasOfflineMeeting: true,
            introModule: [],

            numOfBooks: 4,
            bookList: [],

            depositPerEssay: 30000,
            numOfEssays: 4,
            defaultMembershipFee: 20000,
            totalAmountMembershipFee: 140000,

            numberOfWeeks: 8,
            numberOfMonths: 2,

            startDate: moment().add(1, 'months').startOf('month').format('YYYY-MM-DD'),
            endDate: moment().add(2, 'months').endOf('month').format('YYYY-MM-DD'),
            registerStartDate: moment().format('YYYY-MM-DD'),
            registerEndDate: moment().add(1, 'months').startOf('month').subtract(1, 'days').format('YYYY-MM-DD'),
            maximumNumberOfMember: 99,



            uploadingImage: false,
            imagePreviewUrl: '',

            searchHost: '',
            searchedHosts: [],
            tempHostInfo: {},

            searchStaff: '',
            searchedStaffs: [],


            searchValue: '',
            searchOption: 'd_titl',
            searchedOutcome: [],

        };
    }



    handleChange = (e, {value, name}) => {

        this.setState({
            [name]: value
        })

    }

    fileChange = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.handleImageUpload(file)
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {
            this.setState({imagePreviewUrl: ''});
        }
    };

    handleImageUpload = async (file) => {

        this.setState({
            uploadingImage: true,
        })
        const {
            mainImage,
            mainImageFirebase
        } = await bookClubImageUploadToFirebase(file);

        this.setState({
            mainImage,
            mainImageFirebase,
            uploadingImage: false,
        })
    };

    searchHost = async () => {
        const token = await localStorage.getItem('token');
        const memberName = this.state.searchHost
        axios
            .get(API_READERS_V1 + '/user/search/realtime', {
                headers: {jwt: token},
                params: {
                    memberName
                }
            })
            .then(response => {
                const searchedHosts = response.data;
                this.setState({
                    searchedHosts
                })
            })
            .catch(error => {
                console.log(error);
            })
    }
    searchStaff = async () => {
        const token = await localStorage.getItem('token');
        const memberName = this.state.searchStaff
        axios
            .get(API_READERS_V1 + '/user/search/realtime', {
                headers: {jwt: token},
                params: {
                    memberName
                }
            })
            .then(response => {
                const searchedStaffs = response.data;
                this.setState({
                    searchedStaffs
                })
            })
            .catch(error => {
                console.log(error);
            })
    };

    searchBook = () => {
        const {searchOption, searchValue} = this.state;
        console.log({
            searchOption,
            searchValue,
        })

        axios
            .get(API_READERS_V1 + '/bookshelf/search/book', {
                params: {
                    searchValue,
                    searchOption
                }
            })
            .then(response => {
                const searchedOutcome = response.data
                console.log({searchedOutcome})
                this.setState({
                    searchedOutcome
                })
            })
            .catch(error => {

            })
    }
    _deleteBook = i => {
        const bookList = this.state.bookList;
        bookList.splice(i, 1);
        this.setState({
            bookList
        })
    }

    onHostIntroFileUpload = (e, index) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = async () => {
            const {
                mainImage: image,
                mainImageFirebase: imageFirebase
            } = await bookClubImageUploadToFirebase(file);

            let hostIntroModule = this.state.hostIntroModule;
            hostIntroModule[index].image = image;
            hostIntroModule[index].imageFirebase = imageFirebase;

            this.setState({
                hostIntroModule
            })
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {
            let hostIntroModule = this.state.hostIntroModule;
            hostIntroModule[index].image = '';
            hostIntroModule[index].imageFirebase = {};

            this.setState({
                hostIntroModule
            })
        }
    };
    onIntroFileUpload = (e, index) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = async () => {
            const {
                mainImage: image,
                mainImageFirebase: imageFirebase
            } = await bookClubImageUploadToFirebase(file);

            let introModule = this.state.introModule;
            introModule[index].image = image;
            introModule[index].imageFirebase = imageFirebase;

            this.setState({
                introModule
            })
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {
            let introModule = this.state.introModule;
            introModule[index].image = '';
            introModule[index].imageFirebase = {};

            this.setState({
                introModule
            })
        }
    };


    makeBookClub = async () => {
        const token = await localStorage.getItem('token');
        const {
            title,
            subTitle,

            description,
            hasHost,
            hostUserId,
            hostIntroModule,
            staff,

            mainImage,
            mainImageFirebase,

            introduction,
            authenticationWay,
            groundRule,
            offlineMeeting,
            hasOfflineMeeting,
            introModule,

            numOfBooks,
            bookList,

            depositPerEssay,
            numOfEssays,
            defaultMembershipFee,
            totalAmountMembershipFee,

            numberOfWeeks,
            numberOfMonths,

            startDate,
            endDate,
            registerStartDate,
            registerEndDate,
            maximumNumberOfMember,

            uploadingImage,
            imagePreviewUrl,
        } = this.state;
        const bookClubData = {
            title,
            subTitle,

            description,
            hasHost,
            hostUserId,
            hostIntroModule,
            staff,

            mainImage,
            mainImageFirebase,

            introduction,
            authenticationWay,
            groundRule,
            offlineMeeting,
            hasOfflineMeeting,
            introModule,

            numOfBooks,
            bookList,

            depositPerEssay,
            numOfEssays,
            defaultMembershipFee,
            totalAmountMembershipFee,

            numberOfWeeks,
            numberOfMonths,

            startDate: moment(startDate).toISOString(),
            endDate: moment(endDate).toISOString(),
            registerStartDate: moment(registerStartDate).toISOString(),
            registerEndDate: moment(registerEndDate).toISOString(),
            maximumNumberOfMember,

            uploadingImage,
            imagePreviewUrl,
        }

        axios
            .post(API_READERS_V1 + '/bookclub/one', bookClubData, {
                headers: {jwt: token}
            })
            .then(response => {
                console.log(response.data);
                window.alert('북클럽 생성이 완료되었습니다.')
                this.props.history.push('/admin/bookclub')
            })
            .catch(error => {
                console.log(error);
            })
    }

    render() {

        const $hostIntroModule = (
            <Container text>
                <Card.Group>
                    {
                        this.state.hostIntroModule.map((module, i) => {
                            return (
                                <Card key={i} raised fluid>
                                    {
                                        module.image && (
                                            <Image src={module.image} onClick={() => {
                                                const a = window.confirm('삭제하시겠습니까?')
                                                if (!a) {
                                                    return;
                                                }

                                                let hostIntroModule = this.state.hostIntroModule;
                                                hostIntroModule[i].image = null;
                                                hostIntroModule[i].imageFirebase = {};
                                                this.setState({
                                                    hostIntroModule
                                                })

                                            }}/>
                                        )
                                    }
                                    {
                                        module.movieUrl && (
                                            <iframe width="560" height="315" src={module.movieUrl} frameBorder="0"
                                                    allow="autoplay; encrypted-media"/>
                                        )
                                    }
                                    <Card.Content>
                                        <Form>
                                            <Form.Input
                                                placeholder={'헤더'}
                                                value={this.state.hostIntroModule[i].header}
                                                onChange={(e, {value}) => {
                                                    let hostIntroModule = this.state.hostIntroModule;
                                                    hostIntroModule[i].header = value
                                                    this.setState({
                                                        hostIntroModule
                                                    })
                                                }}/>
                                            <Form.Input
                                                placeholder={'서브헤더'}
                                                value={this.state.hostIntroModule[i].subHeader}
                                                onChange={(e, {value}) => {
                                                    let hostIntroModule = this.state.hostIntroModule;
                                                    hostIntroModule[i].subHeader = value
                                                    this.setState({
                                                        hostIntroModule
                                                    })
                                                }}/>
                                            <Form.Input
                                                placeholder={'YouTube link'}
                                                value={this.state.hostIntroModule[i].movieUrl}
                                                onChange={(e, {value}) => {
                                                    let hostIntroModule = this.state.hostIntroModule;
                                                    hostIntroModule[i].movieUrl = value
                                                    this.setState({
                                                        hostIntroModule
                                                    })
                                                }}/>
                                            <Form.TextArea
                                                rows={8}
                                                placeholder={'서브헤더'}
                                                value={this.state.hostIntroModule[i].content}
                                                onChange={(e, {value}) => {
                                                    let hostIntroModule = this.state.hostIntroModule;
                                                    hostIntroModule[i].content = value
                                                    this.setState({
                                                        hostIntroModule
                                                    })
                                                }}/>
                                        </Form>
                                        <input type="file"
                                               onChange={e => {
                                                   this.onHostIntroFileUpload(e, i)
                                               }}
                                               accept=".jpg, .jpeg, .png, .heic"/>

                                    </Card.Content>
                                    <Card.Content extra>

                                        <Button onClick={() => {
                                            const a = window.confirm('삭제하시겠습니까?');
                                            if (!a) {
                                                return;
                                            }
                                            this.setState(state => {
                                                let hostIntroModule = state.hostIntroModule;
                                                hostIntroModule.splice(i, 1)
                                                return {
                                                    hostIntroModule
                                                }
                                            })
                                        }}>
                                            삭제
                                        </Button>
                                        {
                                            i !== 0 && (
                                                <Button onClick={() => {
                                                    const hostIntroModule = moveItemInArrayFromIndexToIndex(this.state.hostIntroModule, i, i - 1)
                                                    console.log({hostIntroModule})
                                                    this.setState({
                                                        hostIntroModule
                                                    })
                                                }}>
                                                    위로
                                                </Button>
                                            )
                                        }
                                        {
                                            i !== this.state.hostIntroModule.length - 1 && (
                                                <Button onClick={() => {
                                                    const hostIntroModule = moveItemInArrayFromIndexToIndex(this.state.hostIntroModule, i, i + 1)
                                                    console.log({hostIntroModule})
                                                    this.setState({
                                                        hostIntroModule
                                                    })
                                                }}>
                                                    아래로
                                                </Button>
                                            )
                                        }
                                    </Card.Content>
                                </Card>
                            )
                        })
                    }
                </Card.Group>
            </Container>
        )

        const $hostModule = (
            <Segment>
                <Input action
                       name={'searchHost'}
                       value={this.state.searchHost}
                       onChange={this.handleChange}>
                    <input/>
                    <Button onClick={this.searchHost}>Search</Button>
                </Input>
                {this.state.tempHostInfo._id ? (
                    <Fragment>
                        <Table>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        {this.state.tempHostInfo.local.name}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {this.state.tempHostInfo.local.email}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {this.state.tempHostInfo.phoneNumber}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button onClick={() => {
                                            this.setState({
                                                tempHostInfo: {},
                                                hostId: ''
                                            })
                                        }}>
                                            삭제하기
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Fragment>
                ) : null}
                {
                    this.state.searchedHosts.length > 0 && (
                        <Table>
                            <Table.Body>
                                {
                                    this.state.searchedHosts.map((user, i) => {
                                        return (
                                            <Table.Row key={i}>
                                                <Table.Cell>{user.local.name}</Table.Cell>
                                                <Table.Cell>{user._id}</Table.Cell>
                                                <Table.Cell>{user.local.email}</Table.Cell>
                                                <Table.Cell>{user.phoneNumber}</Table.Cell>
                                                <Table.Cell>
                                                    <Button size={'tiny'} onClick={() => {
                                                        this.setState({
                                                            hostUserId: user._id,
                                                            tempHostInfo: user
                                                        })
                                                    }}>
                                                        호스트로
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table>
                    )
                }
                <Segment>
                    <Button onClick={() => {
                        this.setState(state => {
                            let hostIntroModule = state.hostIntroModule;
                            hostIntroModule.push({header: '', subHeader: '', content: '', movieUrl: ''})
                            return {
                                hostIntroModule
                            }
                        })
                    }}>
                        인트로 추가하기
                    </Button>
                </Segment>
                {
                    this.state.hostIntroModule.length > 0 && $hostIntroModule
                }

            </Segment>
        )

        const $staffModule = (
            <Fragment>
                <Table>
                    <Table.Body>
                        {
                            this.state.staff.map((staff, i) => {
                                return (
                                    <Table.Row key={i}>
                                        <Table.Cell>{staff.userName}</Table.Cell>
                                        <Table.Cell>{staff.email}</Table.Cell>
                                        <Table.Cell>{staff.phoneNumber}</Table.Cell>
                                        <Table.Cell>
                                            <Button onClick={() => {
                                                let thisStaff = this.state.staff;
                                                thisStaff.splice(i, 1);
                                                this.setState({
                                                    staff: thisStaff
                                                })
                                            }}>
                                                삭제하기
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })
                        }

                    </Table.Body>
                </Table>
            </Fragment>
        )

        const $staffSearchModule = (
            <Table>
                <Table.Body>
                    {
                        this.state.searchedStaffs.map((user, i) => {
                            return (
                                <Table.Row key={i}>
                                    <Table.Cell>{user.local.name}</Table.Cell>
                                    <Table.Cell>{user._id}</Table.Cell>
                                    <Table.Cell>{user.local.email}</Table.Cell>
                                    <Table.Cell>{user.phoneNumber}</Table.Cell>
                                    <Table.Cell>
                                        <Button size={'tiny'} onClick={() => {
                                            let thisStaff = this.state.staff;
                                            let currentUser = user;
                                            currentUser.userId = user._id;
                                            currentUser.userName = user.local.name;
                                            currentUser.email = user.local.email;
                                            currentUser.phoneNumber = user.phoneNumber;
                                            thisStaff.push(currentUser);
                                            this.setState({
                                                staff: thisStaff
                                            })
                                        }}>
                                            스태프로
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })
                    }
                </Table.Body>
            </Table>
        )

        const $introModule = (
            <Container text>
                <Card.Group>
                    {
                        this.state.introModule.map((module, i) => {
                            return (
                                <Card key={i} raised fluid>
                                    {
                                        module.image && (
                                            <Image src={module.image} onClick={() => {
                                                const a = window.confirm('삭제하시겠습니까?')
                                                if (!a) {
                                                    return;
                                                }

                                                let introModule = this.state.introModule;
                                                introModule[i].image = null;
                                                introModule[i].imageFirebase = {};
                                                this.setState({
                                                    introModule
                                                })

                                            }}/>
                                        )
                                    }
                                    {
                                        module.movieUrl && (
                                            <iframe width="560" height="315" src={module.movieUrl} frameBorder="0"
                                                    allow="autoplay; encrypted-media"/>
                                        )
                                    }
                                    <Card.Content>
                                        <Form>
                                            <Form.Input
                                                placeholder={'헤더'}
                                                value={this.state.introModule[i].header}
                                                onChange={(e, {value}) => {
                                                    let introModule = this.state.introModule;
                                                    introModule[i].header = value
                                                    this.setState({
                                                        introModule
                                                    })
                                                }}/>
                                            <Form.Input
                                                placeholder={'서브헤더'}
                                                value={this.state.introModule[i].subHeader}
                                                onChange={(e, {value}) => {
                                                    let introModule = this.state.introModule;
                                                    introModule[i].subHeader = value
                                                    this.setState({
                                                        introModule
                                                    })
                                                }}/>
                                            <Form.Input
                                                placeholder={'YouTube link'}
                                                value={this.state.introModule[i].movieUrl}
                                                onChange={(e, {value}) => {
                                                    let introModule = this.state.introModule;
                                                    introModule[i].movieUrl = value
                                                    this.setState({
                                                        introModule
                                                    })
                                                }}/>
                                            <Form.TextArea
                                                value={this.state.introModule[i].content}
                                                rows={8}
                                                placeholder={'서브헤더'}
                                                onChange={(e, {value}) => {
                                                    let introModule = this.state.introModule;
                                                    introModule[i].content = value
                                                    this.setState({
                                                        introModule
                                                    })
                                                }}/>
                                        </Form>
                                        <input type="file" onChange={e => {
                                            this.onIntroFileUpload(e, i)
                                        }}
                                               accept=".jpg, .jpeg, .png, .heic"/>
                                    </Card.Content>
                                    <Card.Content extra>

                                        <Button onClick={() => {
                                            const a = window.confirm('삭제하시겠습니까?');
                                            if (!a) {
                                                return;
                                            }
                                            this.setState(state => {
                                                let introModule = state.introModule;
                                                introModule.splice(i, 1)
                                                return {
                                                    introModule
                                                }
                                            })
                                        }}>
                                            삭제
                                        </Button>
                                        {
                                            i !== 0 && (
                                                <Button onClick={() => {
                                                    const introModule = moveItemInArrayFromIndexToIndex(this.state.introModule, i, i - 1)
                                                    console.log({introModule})
                                                    this.setState({
                                                        introModule
                                                    })
                                                }}>
                                                    위로
                                                </Button>
                                            )
                                        }
                                        {
                                            i !== this.state.introModule.length - 1 && (
                                                <Button onClick={() => {
                                                    const introModule = moveItemInArrayFromIndexToIndex(this.state.introModule, i, i + 1)
                                                    console.log({introModule})
                                                    this.setState({
                                                        introModule
                                                    })
                                                }}>
                                                    아래로
                                                </Button>
                                            )
                                        }
                                    </Card.Content>
                                </Card>
                            )
                        })
                    }
                </Card.Group>
            </Container>
        )

        return (

            <div>
                <Container as={Segment} vertical>
                    <Image src={this.state.mainImage} size={'medium'}/>
                    <Button
                        basic
                        color="blue"
                        size="small"
                        onClick={() => {
                            this.fileInput.click();
                        }}
                    >
                        사진 올리기
                    </Button>
                    <input style={{display: 'none'}} type="file" onChange={this.fileChange}
                           ref={fileInput => (this.fileInput = fileInput)} accept=".jpg, .jpeg, .png, .heic"/>

                    <Button
                        basic
                        color="blue"
                        size="small"
                        onClick={() => {
                            const a = window.confirm('북클럽을 만드시겠습니까?')
                            if (!a) {
                                return;
                            }
                            this.makeBookClub()
                        }}
                    >
                        북클럽 만들기
                    </Button>

                </Container>
                <Container as={Segment}>
                    <Form>
                        <Form.Input label={'Title'}
                                    name={'title'}
                                    value={this.state.title}
                                    onChange={this.handleChange}/>

                        <Form.Input label={'subTitle'}
                                    name={'subTitle'}
                                    value={this.state.subTitle}
                                    onChange={this.handleChange}/>

                        <Form.TextArea rows={5}
                                       label={'description'}
                                       name={'description'}
                                       value={this.state.description}
                                       onChange={this.handleChange}/>

                        <Form.TextArea rows={5}
                                       label={'introduction'}
                                       name={'introduction'}
                                       value={this.state.introduction}
                                       onChange={this.handleChange}/>

                        <Form.TextArea rows={5}
                                       label={'authenticationWay'} name={'authenticationWay'}
                                       value={this.state.authenticationWay}
                                       onChange={this.handleChange}/>

                        <Form.TextArea rows={5}
                                       label={'groundRule'} name={'groundRule'}
                                       value={this.state.groundRule}
                                       onChange={this.handleChange}/>

                        <Form.Input label={'depositPerEssay'}
                                    name={'depositPerEssay'}
                                    value={this.state.depositPerEssay}
                                    onChange={this.handleChange}/>

                        <Form.Input label={'numOfEssays'}
                                    name={'numOfEssays'}
                                    value={this.state.numOfEssays}
                                    onChange={this.handleChange}/>

                        <Form.Input label={'numOfBooks'}
                                    name={'numOfBooks'}
                                    value={this.state.numOfBooks}
                                    onChange={this.handleChange}/>

                        <Form.Input label={'defaultMembershipFee'}
                                    name={'defaultMembershipFee'}
                                    value={this.state.defaultMembershipFee}
                                    onChange={this.handleChange}/>

                        <Form.Input label={'totalAmountMembershipFee'}
                                    name={'totalAmountMembershipFee'}
                                    value={this.state.totalAmountMembershipFee}
                                    onChange={this.handleChange}/>


                        <Form.Input label={'numberOfWeeks'}
                                    name={'numberOfWeeks'}
                                    value={this.state.numberOfWeeks}
                                    onChange={this.handleChange}/>

                        <Form.Input label={'numberOfMonths'}
                                    name={'numberOfMonths'}
                                    value={this.state.numberOfMonths}
                                    onChange={this.handleChange}/>

                        <Form.Input label={'maximumNumberOfMember'}
                                    name={'maximumNumberOfMember'}
                                    value={this.state.maximumNumberOfMember}
                                    onChange={this.handleChange}/>

                        <Form.Input label={'startDate'}
                                    type={'date'}
                                    name={'startDate'}
                                    value={this.state.startDate}
                                    onChange={this.handleChange}/>
                        <Form.Input label={'endDate'}
                                    type={'date'}
                                    name={'endDate'}
                                    value={this.state.endDate}
                                    onChange={this.handleChange}/>
                        <Form.Input label={'registerStartDate'}
                                    type={'date'}
                                    name={'registerStartDate'}
                                    value={this.state.registerStartDate}
                                    onChange={this.handleChange}/>
                        <Form.Input label={'registerEndDate'}
                                    type={'date'}
                                    name={'registerEndDate'}
                                    value={this.state.registerEndDate}
                                    onChange={this.handleChange}/>

                    </Form>
                </Container>

                {/* 인트로 모듈 */}
                <Container as={Segment}>
                    <Header>
                        인트로
                    </Header>
                    <Button onClick={() => {
                        this.setState(state => {
                            let introModule = state.introModule;
                            introModule.push({header: '', subHeader: '', content: '', movieUrl: ''})
                            return {
                                introModule
                            }
                        })
                    }}>
                        인트로 추가하기
                    </Button>
                    {
                        this.state.introModule.length > 0 && $introModule
                    }
                </Container>

                {/* 오프라인 모임 */}
                <Container as={Segment}>
                    <Header>
                        오프라인 모임
                        <Radio toggle name={'hasOfflineMeeting'} checked={this.state.hasOfflineMeeting}
                               onChange={(e, {checked}) => {
                                   this.setState({
                                       hasOfflineMeeting: checked
                                   })
                               }}/>
                    </Header>
                    {
                        this.state.hasOfflineMeeting && (
                            <Form>
                                <Form.TextArea rows={5}
                                               label={'offlineMeeting'} name={'offlineMeeting'}
                                               value={this.state.offlineMeeting}
                                               onChange={this.handleChange}/>
                            </Form>
                        )
                    }

                </Container>


                {/* 클럽장 */}
                <Container as={Segment}>
                    <Header>
                        클럽장
                        <Radio toggle name={'hasHost'} checked={this.state.hasHost} onChange={(e, {checked}) => {
                            this.setState({
                                hasHost: checked
                            })
                        }}/>
                    </Header>
                    {
                        this.state.hasHost && $hostModule
                    }
                </Container>

                {/* 스탭 */}
                <Container as={Segment}>
                    <Header>
                        스텝
                    </Header>
                    <Input action
                           name={'searchStaff'}
                           value={this.state.searchStaff}
                           onChange={this.handleChange}>
                        <input/>
                        <Button onClick={this.searchStaff}>Search</Button>
                    </Input>
                    {
                        this.state.staff.length > 0 && $staffModule
                    }
                    {
                        this.state.searchedStaffs.length > 0 && $staffSearchModule
                    }
                </Container>

                {/* 읽는 책 */}
                <Container fluid as={Segment}>
                    <Container>
                        <Header>
                            책
                        </Header>
                    </Container>
                    {
                        this.state.bookList.length > 0 && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            사진
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            제목
                                        </Table.HeaderCell>

                                        <Table.HeaderCell>
                                            저자
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            출판사
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            출판년월
                                        </Table.HeaderCell>

                                        <Table.HeaderCell>
                                            설명
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            이유
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            에세이 주제
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            버튼
                                        </Table.HeaderCell>

                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        this.state.bookList.map((book, i) => {
                                            return (
                                                <Table.Row key={i}>
                                                    <Table.Cell>
                                                        <Item.Image src={book.image} size={'tiny'}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {book.title}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {book.author}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {book.publisher}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {book.pubdate}
                                                    </Table.Cell>
                                                    <Table.Cell width={4}>
                                                        {book.description}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Form>
                                                            <Form.TextArea
                                                                value={this.state.bookList[i].recommendReason}
                                                                onChange={(e, {name, value}) => {
                                                                    const bookList = this.state.bookList;
                                                                    bookList[i].recommendReason = value
                                                                }}/>
                                                        </Form>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Form>
                                                            <Form.TextArea
                                                                value={this.state.bookList[i].essaySubject}
                                                                onChange={(e, {name, value}) => {
                                                                    const bookList = this.state.bookList;
                                                                    bookList[i].essaySubject = value
                                                                }}/>
                                                        </Form>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Button onClick={() => {
                                                            this._deleteBook(i)
                                                        }}>
                                                            삭제하기
                                                        </Button>
                                                        {
                                                            i === 0 ? (
                                                                null
                                                            ) : (
                                                                <Button onClick={() => {
                                                                    const bookList = moveItemInArrayFromIndexToIndex(this.state.bookList, i, i - 1)
                                                                    this.setState({
                                                                        bookList
                                                                    })
                                                                }}>
                                                                    올리기
                                                                </Button>
                                                            )
                                                        }
                                                        {
                                                            i === this.state.bookList.length - 1 ? (
                                                                null
                                                            ) : (
                                                                <Button onClick={() => {
                                                                    const bookList = moveItemInArrayFromIndexToIndex(this.state.bookList, i, i + 1)
                                                                    this.setState({
                                                                        bookList
                                                                    })
                                                                }}>
                                                                    내리기
                                                                </Button>
                                                            )
                                                        }

                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                    }
                                </Table.Body>
                            </Table>
                        )
                    }
                    <Container>
                        <Input type='text' placeholder='Search...'
                               action
                               name={'searchValue'}
                               onChange={this.handleChange}
                               value={this.state.searchValue}>
                            <input/>
                            <Select compact
                                    name={'searchOption'}
                                    onChange={this.handleSelect}
                                    options={[
                                        {
                                            key: 'd_titl',
                                            text: '제목',
                                            value: 'd_titl'
                                        }, {
                                            key: 'd_auth',
                                            text: '저자',
                                            value: 'd_auth'
                                        }]}
                                    defaultValue='d_titl'/>
                            <Button onClick={this.searchBook}>Search</Button>
                        </Input>
                    </Container>
                    {
                        this.state.searchedOutcome.length > 0 ? (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            이미지
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            제목
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            저자
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            출판사
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            출판일
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            기타
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>

                                    {
                                        this.state.searchedOutcome.map((book, i) => {
                                            return (
                                                <Table.Row key={i}>
                                                    <Table.Cell>
                                                        <Item.Image src={book.image} size={'tiny'}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {removeHtmlTag(book.title)}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {book.author}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {book.publisher}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {book.pubdate}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Button onClick={() => {
                                                            const bookList = this.state.bookList;
                                                            let newBook = book;
                                                            newBook.title = removeHtmlTag(newBook.title);
                                                            newBook.author = removeHtmlTag(newBook.author);
                                                            newBook.description = removeHtmlTag(newBook.description);
                                                            bookList.push(newBook)
                                                            this.setState({
                                                                bookList
                                                            })
                                                        }}>
                                                            추가
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                    }
                                </Table.Body>
                            </Table>
                        ) : null
                    }


                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminBookClubMakeContainer);

import * as types from '../actions/actionTypes';
import update from 'react-addons-update';

const initialState = {
    token: '',
    email: '',
    userId: '',
    userName: '',
    nickName: '',
    phoneNumber: '',
    profileImage: '',
    profileImageFirebase: {},
    closed: false,

    isLoggedIn: false,
    status: 'INIT',

    userInfo: {},
};

const authentication = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTH_LOGIN_REQUEST:
            return update(state, {
                status: {$set: 'REQUEST'}
            });
        case types.AUTH_LOGIN_FAILURE:
            return update(state, {
                status: {$set: 'FAILURE'}
            });
        case types.AUTH_LOGIN_SUCCESS:
            return update(state, {
                status: {$set: 'SUCCESS'},
                isLoggedIn: {$set: true},

                token: {$set: action.token},
                email: {$set: action.email},
                userId: {$set: action.userId},
                userName: {$set: action.userName},
                nickname: {$set: action.userInfo.nickname},
                phoneNumber: {$set: action.phoneNumber},
                profileImage: {$set: action.profileImage},

                profileImageFirebase: {$set: action.userInfo.profileImageFirebase},
                userInfo: {$set: action.userInfo},
            });
        case types.AUTH_LOGOUT_REQUEST:
            return initialState;
        default:
            return state;
    }
};

export default authentication;

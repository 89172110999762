import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Responsive} from 'semantic-ui-react'
import {Link} from 'react-router-dom';

class PartnerResponsiveApplyModuleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amIApplyThisBookClub: null,
            applyStatus: ''
        }
    }

    componentDidMount() {

    }

    componentWillMount() {
        this._checkAmIApply();
    }


    componentWillUpdate(nextProps, nextState) {

    }

    componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this._checkAmIApply()
        }
    }

    _checkAmIApply = () => {
        const {applyListAll, bookClub} = this.props;
        // console.log(applyListAll);

        if (!bookClub._id) {
            return;
        }

        const amIApplyThisBookClubIndex = applyListAll.findIndex(apply => apply.bookclubId.toString() === bookClub._id.toString())
        if (amIApplyThisBookClubIndex > -1) {
            this.setState({
                amIApplyThisBookClub: true,
                applyStatus: applyListAll[amIApplyThisBookClubIndex].status
            })
        } else {
            this.setState({
                amIApplyThisBookClub: false,
            })
        }
    };

    render() {
        const {bookClub} = this.props;
        const {amIApplyThisBookClub, applyStatus} = this.state;
        if (amIApplyThisBookClub) {
            return (
                <Fragment>
                    {
                        applyStatus === 'approved' ? (
                            <Responsive maxWidth={764}>
                                <Link to={{pathname: `/mybookclub/${bookClub._id}`}}>
                                    <div
                                        style={{
                                            position: 'fixed',
                                            padding: 15,
                                            backgroundColor: 'black',
                                            bottom: 0,
                                            width: '100%',
                                            textAlign: 'center',
                                            justifyContent: 'center',
                                            color: 'white',
                                            fontSize: 16
                                        }}>
                                        멤버 페이지로 들어가기
                                    </div>
                                </Link>
                            </Responsive>
                        ) : (
                            <Responsive maxWidth={764}>
                                <div
                                    style={{
                                        position: 'fixed',
                                        padding: 15,
                                        backgroundColor: 'black',
                                        bottom: 0,
                                        width: '100%',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        color: 'white',
                                        fontSize: 16
                                    }}>
                                    신청이 완료되었습니다. <br/>
                                    승인 대기중
                                </div>
                            </Responsive>
                        )
                    }
                </Fragment>
            );
        }

        switch (bookClub.status) {
            case 'beforeRecruiting':
                return (
                    <Responsive maxWidth={764}>
                        <div
                            style={{
                                position: 'fixed',
                                padding: 15,
                                backgroundColor: 'black',
                                bottom: 0,
                                width: '100%',
                                textAlign: 'center',
                                justifyContent: 'center',
                                color: 'white',
                                fontSize: 16
                            }}>
                            곧 모집이 시작됩니다.
                        </div>
                    </Responsive>
                )
            case 'recruiting' :
                return (
                    <Responsive maxWidth={764}>
                        <Link to={{pathname: `/partner/bookclub/apply/${bookClub._id}`}}>
                            <div
                                style={{
                                    position: 'fixed',
                                    padding: 15,
                                    backgroundColor: 'black',
                                    bottom: 0,
                                    width: '100%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    fontSize: 16
                                }}>
                                신청하기
                            </div>
                        </Link>
                    </Responsive>
                );
            case 'ing':
            case 'ended':
            default:
                return null

        }

    }
}

const mapStateToProps = (state) => {
    const {
        applyListAll
    } = state.applyList;
    return {
        applyListAll
    };
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PartnerResponsiveApplyModuleComponent);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {deleteTag} from "../../middleware/stringModify";
import moment from "moment/moment";
import {Segment, Container, Header, Message, Button, Divider, Item, Table,} from 'semantic-ui-react'

class ScheduleComponent extends Component {


    render() {
        const {bookClub} = this.props;
        if (!bookClub.books) {
            return null;
        }
        return (
            <div style={{paddingTop: 60, paddingBottom: 60}}>
                <Segment as={Container} vertical text basic textAlign={'center'}>
                    <Header style={{}} size={'large'}>
                        에세이 스케줄
                        <Header.Subheader style={{marginTop: 5}}>
                            {bookClub.bookList.length}권의 책을 {bookClub.numberOfWeeks}주간 읽는
                            동안, {bookClub.books && bookClub.books.length}번 에세이를 작성합니다.
                        </Header.Subheader>
                    </Header>
                </Segment>
                <Segment as={Container} vertical text basic>
                    <Message>
                        <Message.Header style={{fontSize: '1rem'}}>
                            에세이는 얼만큼 써야하나요? 왜쓰나요?
                        </Message.Header>
                        <Message.Content style={{fontSize: '1rem   '}}>
                            에세이 작성에 형식이나 분량이 정해져 있지는 않습니다. 자유롭게 작성하면 됩니다. <br/>
                            책을 읽는 것만으로는 충분하지 않습니다. 단 1줄이라도, 스스로의 생각을 정리해 보는 것이 중요합니다. <br/>
                            생각을 정리하고, 다른 사람들과 생각을 나누는 과정을 거치면, 같은 1권을 읽어도 더 많은 배움을 얻을 수 있습니다.

                        </Message.Content>
                    </Message>
                </Segment>
                <Segment as={Container} vertical text basic>
                    <Table unstackable compact size={'small'}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>책</Table.HeaderCell>
                                <Table.HeaderCell>시작일</Table.HeaderCell>
                                <Table.HeaderCell>종료일</Table.HeaderCell>
                                <Table.HeaderCell>기간</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                bookClub.books.map((book, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>
                                                <div
                                                    style={{
                                                        backgroundColor: 'lightgrey',
                                                        backgroundImage: `url(${book.image.replace('m1', 'm5')})`,
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'cover',
                                                        width: '30px',
                                                        height: '45px',
                                                        // margin: 5,
                                                        // float: 'left',
                                                    }}>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {deleteTag(book.title)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {moment(book.readStartDate).format('MM.DD')}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {moment(book.readEndDate).format('MM.DD')}
                                            </Table.Cell>
                                            <Table.Cell>
                                                ({((moment(book.readEndDate).diff(moment(book.readStartDate), 'days') + 1) / 7).toFixed(0)}주)
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ScheduleComponent);

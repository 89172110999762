import * as types from '../actions/actionTypes';
import update from 'react-addons-update';
import _ from "lodash";

const initialState = {
    applyListAll: [],
    applyListApproved: [],
    applyListBeforeApproval: [],
    isLoaded: false,
    failed: false,

    status: 'INIT'
};

const applyList = (state = initialState, action) => {

    switch (action.type) {
        case types.LOAD_APPLY_LIST_REQUEST:
            return update(state, {
                status: {$set: 'REQUEST'},
                failed: {$set: false},
            });
        case types.LOAD_APPLY_LIST_SUCCESS:
            return update(state, {
                status: {$set: 'SUCCESS'},
                isLoaded: {$set: true},
                failed: {$set: false},

                applyListAll: {$set: action.applyListAll},
                applyListApproved: {$set: action.applyListApproved},
                applyListBeforeApproval: {$set: action.applyListBeforeApproval}
            })
        case types.LOAD_APPLY_LIST_FAILURE:
            return update(state, {
                status: {$set: "FAILURE"},
                failed: {$set: true}
            })
        case types.INITIALIZE_APPLY_LIST:
            return initialState;
        default:
            return state

    }


}

export default applyList


import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as authActions from '../../redux/actions/auth';
import {Input, Form, Button, Segment, Message, Grid, Header} from 'semantic-ui-react';
import {API} from '../../constants/API';
import {Redirect, Link} from 'react-router-dom';
import ReactGA from 'react-ga';

import axios from 'axios';
import Colors from "../../config/Colors";
import Analytics from "../../middleware/analytics";

class Login extends Component {
    state = {
        email: '',
        password: '',
        isFetching: false,
        message: ''
    };


    componentDidMount() {
        window.scrollTo(0, 0)
        Analytics.pageViewAll('/login');

    }


    handleChange = (e, {value, name}) => {
        this.setState({[name]: value});
    };

    handleSubmit = e => {
        e.preventDefault();
        console.log(this.state);

        const {email, password} = this.state;
        this.setState({
            isFetching: true
        });
        this.handleLogin();
    };

    handleLogin = () => {
        const {email, password} = this.state;
        const data = {email, password};
        axios
            .post(API + '/auth/login', data)
            .then(response => {
                const userInfo = response.data;
                const {email, phoneNumber, profileImage, token, userId, userName} = userInfo;
                this.props.loginSuccess(token, userId, email, userName, phoneNumber, profileImage, userInfo);
                localStorage.setItem('token', userInfo.token);

            })
            .catch(error => {
                console.log(error);
                if (error.response && error.response.data.error) {
                    console.log(error.response.data.error);
                    this.setState({message: error.response.data.error, isFetching: false});
                }
            });
    };

    render() {
        const {isLoggedIn} = this.props;
        if (isLoggedIn) {
            const {from} = this.props.location.state || {from: {pathname: '/mypage/bookclub'}};
            return <Redirect to={from}/>;
        }

        const {isFetching} = this.state;
        return (
            <Grid textAlign="center" style={{height: '90vh'}} verticalAlign="middle">
                <Grid.Column style={{maxWidth: 450}}>
                    <Form size="large">
                        <Segment style={{borderRadius: '0'}}>
                            <Header>
                                리더스
                            </Header>
                            <Form.Input fluid icon="user" iconPosition="left" placeholder="E-mail address"
                                        value={this.state.email} name={'email'} onChange={this.handleChange}/>
                            <Form.Input fluid icon="lock" iconPosition="left" placeholder="Password" type="password"
                                        value={this.state.password} name="password" onChange={this.handleChange}/>

                            <Button color="black" basic fluid size="large" onClick={this.handleSubmit}
                                    loading={isFetching}>
                                로그인
                            </Button>
                        </Segment>
                    </Form>
                    <Message style={{borderRadius: '0'}}>
                        아직 회원이 아니신가요?<br/>
                        <Link to={{pathname: '/signup'}} style={{color: Colors.mainColor}}>
                            [가입하기]
                        </Link>
                    </Message>
                    {
                        this.state.message.length > 0 && (
                            <Message>{this.state.message}</Message>
                        )
                    }

                </Grid.Column>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    const {isLoggedIn} = state.auth;
    return {
        isLoggedIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loginSuccess: (token, userId, email, userName, phoneNumber, profileImage, userInfo) => {
            dispatch(authActions.loginSuccess(token, userId, email, userName, phoneNumber, profileImage, userInfo));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

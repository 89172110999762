import React, {Component} from 'react';
import {Card, Image, Icon, Label, Button} from 'semantic-ui-react'
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import moment from "moment/moment";
import {readersBankAccount, readersBankAccountHolder, readersBankName} from "../../config/informations";
import numeral from 'numeral'

class MyBookClubCardBeforeApprovalComponent extends Component {

    render() {
        const {bookclub} = this.props;

        if (!bookclub._id) {
            return (
                <Card>
                    <Image src={'/images/mockup/image.png'}/>
                    <Card.Content>
                        <Card.Header>
                            {bookclub.title}
                        </Card.Header>
                        <Card.Meta>
                            {bookclub.sub_title}
                        </Card.Meta>
                    </Card.Content>
                    <Card.Content>
                        <Icon name={'user'}/>
                    </Card.Content>
                </Card>
            )
        }

        return (
            <Card style={{borderRadius: 0}}
                  onClick={() => {
                      window.alert('승인이 되면, 상세페이지로 들어갈 수 있습니다.')
                  }}>
                <Image src={bookclub.mainImage ? bookclub.mainImage : '/images/mockup/image.png'}
                       style={{borderRadius: 0}}/>
                <Card.Content>
                    <Card.Header>
                        {bookclub.title} <br/>
                        <span style={{fontWeight: '400'}}>
                            {moment(bookclub.day_start).format('YYYY. MM. DD')} - {moment(bookclub.day_end).format('YYYY. MM. DD')}
                        </span>
                    </Card.Header>
                    <Card.Meta>
                        승인 대기중 입니다.
                    </Card.Meta>
                    <Card.Meta>
                        {readersBankName} {readersBankAccount} ({readersBankAccountHolder})<br/>
                        {numeral(bookclub.totalAmountMembershipFee).format(0, 0)}원을 입금해주세요.
                    </Card.Meta>
                </Card.Content>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MyBookClubCardBeforeApprovalComponent);

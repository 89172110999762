import React, {Component} from 'react';
import {connect} from 'react-redux';
import {API_READERS_V1} from "../../constants/API";
import axios from "axios";
import moment from 'moment';
import {Segment, Container, Header, Table, Card} from 'semantic-ui-react'
import {removeHtmlTagSubString} from "../../middleware/TextHandler";
import {Link} from 'react-router-dom'

class AdminBookClubManageContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            essayList: [],
            isLoadedEssayList: false,

            participants: [],
            isLoadedParticipants: false,
        }
    }


    componentDidMount() {
        this.loadData();
    }

    checkEssaySubmit = () => {

    }

    loadData = async () => {
        await this.loadEssayList();
        await this.loadBookClubParticipants();

    }

    loadBookClubParticipants = async () => {
        const bookClubId = this.props.match.params.bookClubId;

        axios
            .get(API_READERS_V1 + '/bookclub/one/participants', {
                params: {
                    bookClubId
                }
            })
            .then(response => {
                console.log(response.data);
                console.log('[BookClub] 북클럽 참여자를 가져왔습니다.')
                const participants = response.data;
                this.setState({
                    participants,
                    isLoadedParticipants: true,
                })
            })
            .catch(e => {
                console.log(e);
            })
    };

    loadEssayList = async () => {
        const bookClubId = this.props.match.params.bookClubId;
        axios
            .get(API_READERS_V1 + '/bookclub/one/essays/list', {
                params: {
                    bookClubId
                }
            })
            .then(response => {
                console.log('[BookClub] 에세이 정보를 가져왔습니다.')
                // console.log(response.data);
                const essayList = response.data;
                this.setState({
                    essayList,
                    isLoadedEssayList: true,
                })
            })
            .catch(e => {
                console.log(e);
            })
    };

    render() {
        if (!this.state.isLoadedEssayList || !this.state.isLoadedParticipants) {
            return (
                <div>

                </div>
            )
        }
        return (
            <div>
                <Segment as={Container}>
                    <Table size={'small'} unstackable>

                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    이름
                                </Table.HeaderCell>
                                {
                                    this.state.essayList.map((book, i) => {
                                        return (
                                            <Table.HeaderCell key={i}>
                                                <div style={{
                                                    backgroundColor: 'lightgrey',
                                                    backgroundImage: `url(${book.image.replace('m1', 'm5')})`,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                    width: '30px',
                                                    height: '45px',
                                                }}>

                                                </div>
                                            </Table.HeaderCell>
                                        )
                                    })
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.participants.map((user, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>
                                                {user.userInfo.local.name}
                                            </Table.Cell>
                                            {
                                                this.state.essayList.map((book, i) => {
                                                    let index = book.essays.findIndex(essay => essay.owner_id === user.userInfo._id);
                                                    return (
                                                        <Table.Cell key={i}>
                                                            {index > -1 ? (
                                                                moment(book.essays[index].created).isSameOrBefore(book.readEndDate, 'day') ? (
                                                                    <Link
                                                                        to={{pathname: `/admin/essay/${book.essays[index]._id}`}}>
                                                                    <span style={{color: 'green'}}>
                                                                        제출
                                                                    </span>
                                                                    </Link>
                                                                ) : (
                                                                    moment(book.essays[index].created).isSameOrBefore(moment(book.readEndDate).add(1, 'day'), 'day') ? (
                                                                        <Link
                                                                            to={{pathname: `/admin/essay/${book.essays[index]._id}`}}>
                                                                        <span style={{color: 'orange'}}>
                                                                            D+1
                                                                        </span>
                                                                        </Link>
                                                                    ) : (
                                                                        <Link
                                                                            to={{pathname: `/admin/essay/${book.essays[index]._id}`}}>
                                                                        <span style={{color: 'red'}}>
                                                                            지각
                                                                        </span>
                                                                        </Link>
                                                                    )
                                                                )
                                                            ) : (
                                                                // 아직 제출 시점 아니니? 맞니?
                                                                moment().isSameOrBefore(book.readEndDate, 'day') ? (
                                                                    null
                                                                ) : (
                                                                    <span style={{color: 'red'}}>
                                                                        미제출
                                                                    </span>
                                                                )
                                                            )}
                                                        </Table.Cell>
                                                    )
                                                })
                                            }
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>

                </Segment>

                {
                    this.state.essayList.map((book, i) => {
                        return (
                            <Segment as={Container} key={i}>
                                <Header>
                                    {book.title}
                                    <Header.Subheader>
                                        {moment(book.readStartDate).format('YYYY-MM-DD HH:mm:ss')} <br/>
                                        {moment(book.readEndDate).format('YYYY-MM-DD HH:mm:ss')} <br/>
                                    </Header.Subheader>
                                </Header>
                                <Card.Group itemsPerRow={4}>
                                    {
                                        book.essays.map((essay, j) => {
                                            return (
                                                <Card key={j}>
                                                    <Card.Content>
                                                        {essay.title}
                                                    </Card.Content>
                                                    <Card.Content>
                                                        {essay.owner_name}
                                                    </Card.Content>
                                                    <Card.Content>
                                                        {moment(essay.created).format('YYYY-MM-DD HH:mm:ss')} <br/>
                                                        {moment(essay.created).isSameOrBefore(moment(book.readEndDate).add(1, 'day')) ? "O" : "지각"}
                                                    </Card.Content>
                                                    <Card.Content>
                                                        {essay.contentPlain ? essay.contentPlain.substring(0, 100) : removeHtmlTagSubString(essay.content, 100)}
                                                    </Card.Content>
                                                </Card>
                                            )
                                        })
                                    }
                                </Card.Group>
                            </Segment>
                        )
                    })
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminBookClubManageContainer);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Card, Grid, Header, Button, Divider, Segment, Image} from 'semantic-ui-react'
import Colors from "../../config/Colors";
import HomeBookClubCardComponent from '../../components/bookclub/PublicBookClubCardComponent'
import * as actionBookClubPublic from '../../redux/actions/bookClubPublic'
import * as actionRecommend from '../../redux/actions/recommend'
import _ from 'lodash';
import {Link} from 'react-router-dom'
import ReadersWayComponent from '../../components/bookclub/ReadersWayComponent'
import FooterComponent from '../../components/shared/FooterComponent'
import Analytics from "../../middleware/analytics";

class HomeContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidMount() {
        this._loadBookClubList()
        window.scrollTo(0, 0);
        Analytics.pageViewAll('/');
    }

    _loadBookClubList = () => {
        this.props.loadBookClubList()
        this.props.loadRecommendList();
    };



    render() {

        const {
            bookClubListRecruiting,
            bookClubListIng,
            bookClubListEnded,

            recommendsAll,
        } = this.props;


        const recruitListSegment = () => {
            if (bookClubListRecruiting.length === 0) {
                return '현재 모집중인 북클럽이 없습니다.';
            }
            return bookClubListRecruiting.map((bookClub, i) => {
                return <HomeBookClubCardComponent bookclub={bookClub} key={i}/>;
            });
        };
        const ingListSegment = () => {
            if (bookClubListIng.length === 0) {
                return '현재 진행중인 북클럽이 없습니다.';
            }
            return bookClubListIng.slice(0, 6).map((bookClub, i) => {
                return <HomeBookClubCardComponent bookclub={bookClub} key={i}/>;
            });
        };

        const endedListSegment = () => {
            return bookClubListEnded.slice(0, 6).map((bookclub, i) => {
                return <HomeBookClubCardComponent bookclub={bookclub} key={i}/>;
            });
        };


        return (
            <div>
                <Container className={'noMargin'} as={Segment} vertical fluid
                           style={{
                               backgroundColor: Colors.mainColor,
                               marginLeft: 0,
                               marginRight: 0,
                               minHeight: '50vh'
                           }}>
                    <Grid as={Container} stackable>
                        <Grid.Row columns={2} verticalAlign={'middle'} style={{minHeight: '50vh'}}>
                            <Grid.Column
                                style={{}}
                                verticalAlign={'middle'}
                                className={'headerGrid'}>
                                <Header style={{color: 'white', fontWeight: '400'}} size={'huge'}>
                                    <Header.Subheader style={{color: 'white', lineHeight: '2rem'}}
                                                      className={'animated fadeIn slower'}>
                                        책을 꾸준히 읽고 싶나요? <br/>
                                        어떤 책을 읽어야할 지 고민 되나요?<br/>
                                        <br/>
                                    </Header.Subheader>
                                    <span className={'animated fadeIn slower delay-1s'}>리더스</span>
                                </Header>
                                <Button inverted size={'large'} as={Link} to={{pathname: '/about'}}>
                                    리더스가 무엇인가요?
                                </Button>
                            </Grid.Column>
                            <Grid.Column>
                                {/*<Image src={'/images/white-mockup.png'} size={'large'} centered/>*/}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>


                <Container as={Segment} vertical style={{marginTop: 100}} basic>
                    <Header size={'large'}>
                        모집중인 북클럽
                    </Header>
                    <Card.Group itemsPerRow={2} stackable>
                        {recruitListSegment()}
                    </Card.Group>
                </Container>

                {
                    this.props.bookClubListRecruitingPartner.length > 0 ? (
                        <Container as={Segment} vertical style={{marginTop: 100}} basic>
                            <Divider hidden/>
                            <Header size={'large'} >
                                제휴 북클럽을 찾으시나요 ?
                            </Header>
                            <div style={{textAlign: 'center'}}>
                                <Button basic color={'black'} size={'large'} as={Link}
                                        to={{pathname: '/partner'}}>
                                    모집중인 제휴 북클럽 ({this.props.bookClubListRecruitingPartner.length}) 전체보기
                                </Button>
                            </div>

                        </Container>
                    ) : (
                        null
                    )
                }

                {/* 리더스 진행 방식 */}
                <div style={{marginTop: 100}}>
                    <ReadersWayComponent/>
                </div>

                {/* 진행중인 북클럽 */}
                <Container as={Segment} vertical style={{marginTop: 100}} basic>
                    <Header size={'large'}>
                        진행중인 북클럽
                    </Header>
                    <Card.Group itemsPerRow={3} doubling>
                        {ingListSegment()}
                    </Card.Group>
                    {
                        bookClubListIng.length > 6 && (
                            <div style={{textAlign: 'center'}}>
                                <Divider hidden/>
                                <Button basic color={'black'} size={'large'} as={Link}
                                        to={{pathname: '/bookclub/list/ing'}}>
                                    진행중인 북클럽 ({bookClubListIng.length}) 전체보기
                                </Button>
                            </div>
                        )
                    }
                </Container>

                {/* 종료된 북클럽 */}
                <Container as={Segment} vertical style={{marginTop: 100}} basic>
                    <Header size={'large'}>
                        종료된 북클럽
                    </Header>
                    <Card.Group itemsPerRow={3} doubling>
                        {endedListSegment()}
                    </Card.Group>
                    {
                        bookClubListEnded.length > 6 && (
                            <div style={{textAlign: 'center'}}>
                                <Divider hidden/>
                                <Button basic color={'black'} size={'large'} as={Link}
                                        to={{pathname: '/bookclub/list/ended'}}>
                                    종료된 북클럽 ({bookClubListEnded.length}) 전체보기
                                </Button>
                            </div>
                        )
                    }

                </Container>

                {/* 서재 */}
                {/*<div style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    backgroundColor: '#E8E8E8',
                    marginTop: 100,
                }}>
                    <Container as={Segment} vertical style={{}} basic>
                        <Header size={'large'} style={{fontWeight: '900'}}>
                            서재
                            <Header.Subheader style={{lineHeight: '1.8rem'}}>
                                책장을 본다는 것은 생각의 흐름을 보는 것. <br/>
                                다른 사람의 책장에서 인생책을 발견할 수도 있습니다.<br/>
                                나만의 온라인 서재를 만들어보세요
                            </Header.Subheader>
                        </Header>
                    </Container>
                </div>
                <Container as={Segment} vertical style={{marginTop: 100}} basic>
                    <Header size={'large'} style={{fontWeight: '900'}}>
                        추천 책장
                        <Header.Subheader>
                            추천 책 리스트
                        </Header.Subheader>
                    </Header>
                    <Card.Group itemsPerRow={4} stackable>
                        {
                            recommendsAll.slice(0, 4).map((recommend, i) => {
                                return (
                                    <Card key={i}
                                          style={{borderRadius: 0}}
                                          as={Link}
                                          to={{pathname: `/recommend/${recommend._id}`}}>
                                        <Image src={recommend.mainImage}/>
                                        <Card.Content>
                                            <Card.Header>
                                                {recommend.title}
                                            </Card.Header>
                                            <Card.Description>
                                                {recommend.subTitle}
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>
                                )
                            })
                        }
                    </Card.Group>
                    {
                        recommendsAll.length > 4 && (
                            <div style={{textAlign: 'center'}}>
                                <Divider hidden/>
                                <Button basic color={'black'} size={'large'} as={Link}
                                        to={{pathname: '/bookshelf'}}>
                                    서재에서 전체보기
                                </Button>
                            </div>
                        )
                    }
                </Container>

                <Container as={Segment} vertical style={{marginTop: 100}} basic>
                    <Header size={'large'} style={{fontWeight: '900'}}>
                        멤버들의 책장
                        <Header.Subheader>
                            리더스 멤버들의 서재에 꽂혀있는 책들입니다.
                        </Header.Subheader>
                    </Header>
                    <p>
                        준비중입니다.
                    </p>
                </Container>*/}


            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        bookClubListAll,
        bookClubListRecruiting,
        bookClubListIng,
        bookClubListEnded,
        isLoaded,
        failed
    } = state.bookClubPublic;

    const {
        bookClubListRecruiting: bookClubListRecruitingPartner
    } = state.bookClubPartner

    const {
        recommendsAll
    } = state.recommend;

    return {
        bookClubListAll,
        bookClubListRecruiting,
        bookClubListIng,
        bookClubListEnded,
        isLoaded,
        failed,

        bookClubListRecruitingPartner,


        recommendsAll
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        loadBookClubList: () => {
            dispatch(actionBookClubPublic.loadBookClubList())
        },
        loadRecommendList: () => {
            dispatch(actionRecommend.loadRecommendList())
        }
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomeContainer);



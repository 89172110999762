import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as authActions from '../../redux/actions/auth';
import {Container, Segment, Header, Image, Card, Label} from 'semantic-ui-react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import Analytics from "../../middleware/analytics";

class MyPageContainer extends Component {


    componentDidMount = () => {
        window.scrollTo(0, 0);
        Analytics.pageViewAll('/mypage');
    };

    render() {
        const {
            userName,
            userId,
        } = this.props;

        return (
            <div>
                <div style={{backgroundColor: 'black', paddingTop: 50, paddingBottom: 50}}>
                    <Container>
                        <Header inverted size="large">
                            {userName} 님 반갑습니다.
                        </Header>

                    </Container>
                </div>
                <Container/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {isLoggedIn, userName, email, userId, phoneNumber, profileImage} = state.auth;
    return {

        isLoggedIn,
        userName,
        email,
        userId,
        phoneNumber,
        profileImage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(authActions.logout());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyPageContainer);

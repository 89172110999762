import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Container, Segment, Header, Divider, Card} from 'semantic-ui-react'
import PartnerBookClubCardComponent from '../../components/bookclub/PartnerBookClubCardComponent';
import * as actionBookClubPartner from "../../redux/actions/bookClubPartner";
import Analytics from "../../middleware/analytics";
import {Link} from "react-router-dom";

class BookClubPartnerMainContainer extends Component {

    componentDidMount = () => {
        this.props.loadPartnerBookClubList();
        Analytics.pageViewAll('/partner');

        window.scrollTo(0, 0);
    };

    render() {
        const {
            bookClubListRecruiting,
            bookClubListIng,
            bookClubListEnded,
        } = this.props;

        const recruitListSegment = () => {
            if (bookClubListRecruiting.length === 0) {
                return '현재 모집중인 북클럽이 없습니다.';
            }
            return bookClubListRecruiting.map((bookclub, i) => {
                return <PartnerBookClubCardComponent bookclub={bookclub} key={i}/>;
            });
        };

        return (
            <Fragment>
                <div style={{minHeight: '30vh', backgroundColor: 'black', paddingTop: 30, marginBottom: 50}}>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn slow'}>
                        <p style={{color: 'white'}}>
                            화
                        </p>
                        <div style={{fontSize: '3rem', color: 'white', fontWeight: '700'}}
                             className={'hanSerifJapanese'}>
                            夥
                        </div>
                        <Header inverted>
                            <Header.Subheader>
                                리더스 이외의 책 모임들이 사용할 수 있는 공간입니다.
                            </Header.Subheader>
                        </Header>
                    </Container>
                </div>

                <Segment as={Container} vertical basic style={styles.module}>
                    <Header>
                        모집중 북클럽
                    </Header>
                    <Divider/>
                    <Card.Group itemsPerRow={3}
                                stackable>
                        {recruitListSegment()}
                    </Card.Group>
                </Segment>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        bookClubListAll,
        bookClubListRecruiting,
        bookClubListIng,
        bookClubListEnded,
        isLoaded,
        failed
    } = state.bookClubPartner
    return {
        bookClubListAll,
        bookClubListRecruiting,
        bookClubListIng,
        bookClubListEnded,
        isLoaded,
        failed
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        loadPartnerBookClubList: () => {
            dispatch(actionBookClubPartner.loadPartnerBookClubList())
        }
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BookClubPartnerMainContainer);


const styles = {
    module: {
        marginBottom: 100
    }
}
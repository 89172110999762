import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Segment, Container, Header} from "semantic-ui-react";
import ReactGA from 'react-ga';

class SpaceMainContainer extends Component {

    componentDidMount() {
        ReactGA.pageview('/space');
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <Fragment>
                <div style={{minHeight: '50vh', backgroundColor: 'black', paddingTop: 30}}>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn slow'}>
                        <p style={{color: 'white'}}>
                            정
                        </p>
                        <div style={{fontSize: '3rem', color: 'white', fontWeight: '700'}}
                             className={'hanSerifJapanese'}>
                            亭
                        </div>
                        <Header inverted>
                            <Header.Subheader>
                                책을 읽는 사람들이 모이고 잠시 놀다가는 곳
                            </Header.Subheader>
                        </Header>
                    </Container>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn delay-1s slow'}>
                        <div style={{
                            backgroundImage: `url(/images/anjin.jpg)`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: '100%',
                            minHeight: 300,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end'
                        }}>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <span style={{color: 'white'}} className={'hanSerifJapanese'}>
                                代官山 Anjin
                            </span>
                        </div>
                    </Container>
                </div>
                <Container as={Segment}
                           text
                           vertical
                           basic style={{paddingTop: 100}}
                           className={'animated fadeIn slow'}>
                    <Header>
                        리더스 亭 (정)
                    </Header>
                    <p>
                        책을 읽는 사람들이, 만날 수 있는 장소를 만듭니다.
                    </p>
                    <p>
                        <b>서재</b>: 책들이 모여 있습니다. <br/>
                        옷장에서 오늘 입을 옷을 고르듯, 책장과 서재에서 생각을 입습니다. 지금 내게 필요한 생각과 질문을 찾을 수 있습니다. <br/>
                        오늘을 살아가는 나에게 힌트를 주는 책을 만납니다.
                    </p>
                    <p>
                        <b>만남</b>: 사람들이 모입니다. <br/>
                        책을 중심으로 수 없이 많은 주제를 다룰 수 있습니다. 책으로 둘러 쌓인 공간이 주는 힘이 있습니다. 같은 책을 보고,
                        다른 경험을 한 사람들이 모여서 이야기를 나누면, 혼자 책을 읽을 때와 다른 입체적인 생각을 할 수 있습니다.
                    </p>
                    <p>
                        공간 <span className={'hanSerifJapanese'}>亭</span>
                        (정)은 현재 기획/준비 중에 있습니다. 빠른 시일 내에 만날 수 있도록 준비 중입니다.
                    </p>
                </Container>

            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SpaceMainContainer);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Header, Segment,} from "semantic-ui-react";


class ParticipantListComponent extends Component {
    render() {
        const {participants, bookClub} = this.props;
        const {status} = bookClub;

        switch (status) {
            case 'beforeRecruiting' :
            case 'recruiting' :
                return (
                    null
                )
            case 'ing' :
                return (
                    <div style={styles.module}>
                        <Segment as={Container} vertical text basic textAlign={'center'}>
                            <Header style={{}} size={'large'}>
                                함께 읽는 사람들
                                <Header.Subheader style={styles.subHeader}>
                                    {participants.length}명이 북클럽에 신청하였습니다.
                                </Header.Subheader>
                            </Header>
                            <div
                                style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center'
                                }}>
                                {
                                    participants.map((participant, i) => {
                                        return (
                                            <div key={i}>
                                                <div
                                                    style={{
                                                        backgroundColor: 'lightgrey',
                                                        backgroundImage: `url(${participant.userInfo.profileImage})`,
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'cover',
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '25px',
                                                        marginLeft: 5,
                                                        marginRight: 5,
                                                    }}>
                                                </div>
                                                <div style={{fontSize: '0.8rem'}}>
                                                    {participant.userInfo.local.name}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Segment>
                    </div>
                )
            case 'ended':
                return (
                    <div style={styles.module}>
                        <Segment as={Container} vertical text basic textAlign={'center'}>
                            <Header style={{}} size={'large'}>
                                참여한 사람들
                                <Header.Subheader style={styles.subHeader}>
                                    {participants.length}명이 북클럽을 진행하였습니다.
                                </Header.Subheader>
                            </Header>
                            <div
                                style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center'
                                }}>
                                {
                                    participants.map((participant, i) => {
                                        return (
                                            <div key={i}>
                                                <div
                                                    style={{
                                                        backgroundColor: 'lightgrey',
                                                        backgroundImage: `url(${participant.userInfo.profileImage})`,
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'cover',
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '25px',
                                                        marginLeft: 5,
                                                        marginRight: 5,
                                                    }}>
                                                </div>
                                                <div style={{fontSize: '0.8rem'}}>
                                                    {participant.userInfo.local.name}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Segment>
                    </div>
                )
            default:
                return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParticipantListComponent);


const styles = {
    module: {
        paddingTop: 60,
        paddingBottom: 60,
        backgroundColor: 'lightgrey'
    }
}